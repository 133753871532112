<template>
  <span>
    <dropdown>
      <template v-slot:toggle="toggleProps">
        <div class="btn-group">
          <button class="btn btn-primary" type="primary" @click="newNewsletter()">
            {{ $t("newsletter.definition.new_form_title") }}
          </button>
          <button
            class="btn btn-primary"
            type="primary"
            @click="toggleProps.toggleDropdown()"
            v-if="enableTemplates && newsletterTemplates && newsletterTemplates.length > 0"
          >
            <span class="caret"></span>
          </button>
        </div>
      </template>
      <template v-slot:menu>
        <li>
          <dropdown-item @click="newNewsletter()">{{ $t("newsletter.definition.create_empty") }}</dropdown-item>
          <dropdown-item @click="newNewsletterFromTemplate()">{{
            $t("newsletter.definition.create_from_template")
          }}</dropdown-item>
        </li>
      </template>
    </dropdown>
    <newsletter-definition-from-template-modal
      ref="newsletterDefinitionFromTemplateModal"
      :newsletter-templates="newsletterTemplates"
      @new-from-template="onNewFromTemplate"
    ></newsletter-definition-from-template-modal
  ></span>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import dropdown from "../components/dropdown.vue";
import NewsletterDefinitionFromTemplateModal from "./newsletter-definition-from-template-modal.vue";
import newsletterTemplatesApi from "../api/newsletter-templates-api";
import _ from "lodash";
import DropdownItem from "../components/dropdown-item.vue";

export default defineComponent({
  components: {
    dropdown,
    NewsletterDefinitionFromTemplateModal,
    DropdownItem,
  },
  data() {
    return {
      newsletterTemplates: null,
    };
  },
  created() {
    this.fetchNewsletterTemplates();
  },
  methods: {
    newNewsletter() {
      if (this.$listeners["new-newsletter"]) {
        this.$emit("new-newsletter");
      } else {
        location.hash = "#!/definitions/";
      }
    },
    onNewFromTemplate(id) {
      if (this.$listeners["new-newsletter-from-template"]) {
        this.$emit("new-newsletter-from-template", id);
      } else {
        location.hash = `#!/definitions/?templateId=${id}`;
      }
    },
    newNewsletterFromTemplate() {
      this.newsletterDefinitionFromTemplateModal.show();
    },
    fetchNewsletterTemplates() {
      newsletterTemplatesApi.getAll().then(
        (templates) =>
          (this.newsletterTemplates = templates.map((template) => {
            const localizedTitle = _.get(template, `merged_translations_hash.title.${window.zoinedContext.locale}`);
            if (localizedTitle) {
              template = { ...template, title: localizedTitle };
            }
            return template;
          }))
      );
    },
  },
  props: {
    enableTemplates: { default: false, type: Boolean },
  },
  computed: {
    newsletterDefinitionFromTemplateModal: {
      cache: false,
      get() {
        return this.$refs["newsletterDefinitionFromTemplateModal"] as NewsletterDefinitionFromTemplateModal;
      },
    },
  },
});
</script>
