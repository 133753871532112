<template>
  <report-action-button
    v-uiv-tooltip.hover="duplicateTooltip"
    class="intercom-tag-report-actions-duplicate"
    :disabled="isLimited"
    @click="duplicateReport"
    icon="fluent:copy-24-filled"
    :text="t('actions.duplicate')"
  />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import { useI18n } from "@/composables/i18n";
import reportActionButton from "@/components/report-action-button.vue";

const { t } = useI18n();
const isLimited = window.zoinedContext.limited;

const duplicateTooltip = computed(() => {
  return isLimited ? `${t("actions.duplicate")} (${t("limited_version.available_with_pro")})` : t("actions.duplicate");
});

const duplicateReport = () => {
  EventBus.emit(Events.duplicateReport);
};
</script>
