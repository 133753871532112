<template>
  <div class="newsletter-section-alert-conditions" v-if="newCondition">
    <div class="conditions">
      <span class="condition" v-for="(condition, index) in conditions"
        ><b v-if="index != 0">{{ $t("common.or") }}</b>
        <pill-button :label="conditionText(condition)" @remove="onRemove(condition)"></pill-button
      ></span>
      <plus-button @click="modalOpen = true"></plus-button>
    </div>
    <modal v-model="modalOpen" :title="$t('newsletter.definition.alert.alert_condition')" @show="onOpenModal">
      <div slot="default">
        <div class="add-condition-form" v-if="newCondition">
          <h4>{{ $t("filter.config.metrics") }}</h4>
          <filter-dropdown
            :value="newCondition.metric"
            :default="defaultMetric"
            :select-label="'Default metric'"
            :available-items="availableMetrics"
            @update="newCondition.metric = $event"
          ></filter-dropdown>
          <h4>{{ $t("newsletter.definition.alert.comparison_left") }}</h4>
          <filter-dropdown
            :value="comparisonToMenuKey(newCondition.comparison_left)"
            :available-items="availableComparisons"
            @update="newCondition.comparison_left = menuKeyToComparison($event)"
          ></filter-dropdown>
          <h4>{{ $t("newsletter.definition.alert.comparison_right") }}</h4>
          <filter-dropdown
            :value="comparisonToMenuKey(newCondition.comparison_right)"
            :available-items="availableComparisons"
            @update="newCondition.comparison_right = menuKeyToComparison($event)"
          ></filter-dropdown>
          <h4>{{ $t("newsletter.definition.alert.condition") }}</h4>
          <div class="input-group" v-if="newCondition.comparison_right.type != 'no_comparison'">
            <b>{{ comparisonLabel(newCondition.comparison_left) }}</b
            ><b>{{ $t("common.is") }}</b>
            <input class="value" type="number" v-model="newCondition.value" />
            <filter-dropdown
              :value="newCondition.condition_type"
              :available-items="conditionTypes"
              @update="newCondition.condition_type = $event"
            ></filter-dropdown>
            <filter-dropdown
              :value="newCondition.compare"
              :available-items="compareTypes"
              @update="newCondition.compare = $event"
            ></filter-dropdown
            ><b>{{ comparisonLabel(newCondition.comparison_right) }}</b>
          </div>
          <div class="input-group" v-if="newCondition.comparison_right.type == 'no_comparison'">
            <b>{{ comparisonLabel(newCondition.comparison_left) }}</b
            ><b>{{ $t("common.is") }}</b>
            <filter-dropdown
              :value="newCondition.compare"
              :available-items="compareTypes"
              @update="newCondition.compare = $event"
            ></filter-dropdown>
            <input class="value" type="number" v-model="newCondition.value" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" :disabled="!isValid" @click="onAdd">{{ $t("actions.add") }}</button>
        <button class="btn btn-default" @click="modalOpen = false">{{ $t("actions.cancel") }}</button>
      </div>
    </modal>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import pillButton from "../components/pill-button";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper.ts";
import { Modal } from "uiv";
import FilterDropdown from "../components/filter-dropdown";
import i18n from "@/i18n";
import PlusButton from "@/components/buttons/plus-button.vue";

const CONDITION_TYPE_PERCENT = "percent";
const CONDITION_TYPE_UNIT = "unit";

export default defineComponent({
  components: {
    pillButton,
    FilterDropdown,
    Modal,
    PlusButton,
  },
  props: {
    config: Object,
    defaultMetric: String,
  },
  data() {
    return {
      modalOpen: false,
      newCondition: null,
    };
  },
  computed: {
    loaded() {
      return this.availableMetrics && this.availableComparisons;
    },
    availableMetrics() {
      return this.$store.getters.getParameters("metrics");
    },
    conditionTypes() {
      return [CONDITION_TYPE_PERCENT, CONDITION_TYPE_UNIT].map((key) => ({
        key,
        name: i18n.t(`newsletter.definition.alert.condition_types.${key}`),
      }));
    },
    compareTypes() {
      return ["gt", "lt"].map((key) => ({
        key,
        name: i18n.t(`newsletter.definition.alert.compare_options.${key}`),
      }));
    },
    availableComparisons() {
      const comparisons = this.$store.getters.getParameters("comparisons");
      return (
        comparisons && [
          {
            key: "no_comparison",
            name: i18n.t("filters.benchmark.no_comparison"),
          },

          { key: "actual", name: i18n.t("filters.date_selector.actual") },
          ...comparisons.map(({ type, label, time_period, name }) => ({
            key: comparisonToMenuKey({ type, label, time_period }),
            name,
          })),
        ]
      );
    },
    conditions() {
      return this.config ? this.config.conditions : [];
    },
    isValid() {
      const { value } = this.newCondition;
      return Number(value) >= 0;
    },
  },
  methods: {
    comparisonToMenuKey(comparison) {
      return comparisonToMenuKey(comparison);
    },
    menuKeyToComparison(menuKey) {
      return menuKeyToComparison(menuKey);
    },
    conditionText(condition) {
      if (!this.loaded) {
        return;
      }
      const { metric, comparison_left, value, condition_type, compare, comparison_right } = condition;

      const metricLabel = metric ? this.availableMetrics.find(({ key }) => key == metric).name : "";
      const comparisonLeftLabel = this.comparisonLabel(comparison_left);
      const compareLabel = i18n.t(`newsletter.definition.alert.compare_options.${compare}`);

      if (comparison_right && comparison_right.type != "no_comparison") {
        const conditionTypeLabel = i18n.t(`newsletter.definition.alert.condition_types.${condition_type}`);

        const comparisonRightLabel = this.comparisonLabel(comparison_right);

        return i18n.t("newsletter.definition.alert.alert_description", {
          metric: metricLabel,
          comparison_left: comparisonLeftLabel,
          value,
          condition_type: conditionTypeLabel,
          compare: compareLabel,
          comparison_right: comparisonRightLabel,
        });
      } else {
        return i18n.t("newsletter.definition.alert.alert_description_single_comparison", {
          metric: metricLabel,
          comparison_left: comparisonLeftLabel,
          value,
          compare: compareLabel,
        });
      }
    },
    comparisonLabel(comparison) {
      return this.availableComparisons.find(({ key }) => key == comparisonToMenuKey(comparison)).name;
    },
    onAdd() {
      const conditions = [...this.conditions, this.newCondition];
      this.$emit("update", { conditions });
      this.modalOpen = false;
    },
    onRemove(condition) {
      const conditions = this.conditions.filter((c) => c !== condition);
      this.$emit("update", { conditions });
    },
    onOpenModal() {
      if (this.loaded) {
        this.clearCondition();
      }
    },
    clearCondition() {
      this.newCondition = {
        metric: null,
        comparison_left: menuKeyToComparison(this.availableComparisons[1].key),
        condition_type: CONDITION_TYPE_UNIT,
        compare: "gt",
        comparison_right: menuKeyToComparison(this.availableComparisons[0].key),
      };
    },
    onUpdateComparisonRight(comparisonRight) {
      if (comparisonRight.type == "no_comparison") {
        this.newCondition.condition_type = CONDITION_TYPE_UNIT;
      } else {
        this.newCondition.condition_type = CONDITION_TYPE_PERCENT;
      }
    },
    onLoaded(loaded) {
      if (loaded) {
        this.clearCondition();
      }
    },
  },
  watch: {
    "newCondition.comparison_right": [
      {
        handler: "onUpdateComparisonRight",
      },
    ],
    loaded: [{ immediate: true, handler: "onLoaded" }],
  },
});
</script>
<style lang="scss" scoped>
.add-button {
  padding: 4px 7px !important;
}
.condition {
  > *:not(:last-child) {
    margin-right: 5px;
  }
}
.add-condition-form {
  input.value {
    width: 80px;
    display: inline-block;
  }
  .input-group > * {
    margin-right: 10px !important;
  }

  .actions {
    > * {
      margin-right: 5px;
    }
  }
}
</style>
