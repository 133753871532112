<template>
  <div class="input-group">
    <span class="input-group-addon"> <div class="fa fa-search"></div></span>
    <input
      class="form-control"
      id="search"
      type="text"
      :value="value"
      :placeholder="$t('actions.search')"
      @input="onInput"
    />
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import Vue, { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      onInput: _.debounce((event) => {
        this.$emit("input", event.target.value);
      }, 400),
    };
  },
  props: {
    value: {
      type: String,
    },
  },
});
</script>
