<template>
  <div id="sidenav" :class="{ open, expanded: showNavExpanded }">
    <div class="overlay" :class="{ open, expanded: showNavExpanded }" @click="open = false"></div>
    <div class="sidenav-container" :class="{ open, expanded: showNavExpanded }">
      <div class="sidenav-iconbar">
        <div class="logo-container">
          <router-link v-if="hasRouterEnabled" to="/" v-uiv-tooltip="defaultDashboardTooltip">
            <svg-icon class="zoined-logo" name="zoined-logo"></svg-icon> </router-link
          ><a v-else href="/"> <svg-icon class="zoined-logo" name="zoined-logo"></svg-icon></a>
        </div>
        <icon-btn
          class="intercom-tag-sidenav-analytics-button"
          :active="tab == 'SidenavAnalytics'"
          :title="$t('layouts.navigation.analytics')"
          @click="toggleTab('SidenavAnalytics')"
        >
          <icon icon="fluent:data-trending-20-filled"></icon>
        </icon-btn>
        <div class="divider" v-if="showNewAnalytics"></div>
        <icon-btn
          class="intercom-tag-sidenav-new-analytics-button"
          v-if="showNewAnalytics"
          :active="tab == 'SidenavNewAnalytics'"
          :title="$t('analytics.index.new_analytics')"
          @click="toggleTab('SidenavNewAnalytics')"
        >
          <icon icon="fluent:add-20-filled"></icon>
        </icon-btn>
        <div class="divider" v-if="hasUserCompanyRole"></div>
        <icon-btn
          v-if="hasUserCompanyRole"
          :active="tab == null && routePath == subscriptionsPath"
          :title="$t('newsletter.subscription.email_reports')"
          @click="openSubscriptions()"
        >
          <icon icon="fluent:mail-20-filled"></icon>
        </icon-btn>
        <div class="divider" v-if="showSettings"></div>
        <icon-btn
          v-if="showSettings"
          :active="tab == 'SidenavSettings'"
          :title="$t('dashboard_custom.report_editor.settings')"
          @click="toggleTab('SidenavSettings')"
        >
          <icon icon="fluent:settings-20-filled"></icon>
        </icon-btn>
        <div class="trailing-container">
          <product-fruits-launcher-button />
          <a v-if="tab" class="expand-btn" @click="onExpandBtnClicked">
            <icon
              :icon="expanded ? 'fluent:arrow-circle-left-20-regular' : 'fluent:arrow-circle-right-20-regular'"
              v-uiv-tooltip.right="expandButtonTooltip"
            ></icon
          ></a>
        </div>
      </div>
      <div class="results-container" :class="{ open, expanded: showNavExpanded }">
        <div class="results">
          <div class="header flex-row justify-content-space-between align-items-flex-start">
            <component class="results-logo-container" :is="hasRouterEnabled ? 'router-link' : 'a'" to="/" href="/">
              <div class="zoined-logo">
                <div class="zoined">zoined</div>
                <div class="font-weight-md font-size-md uppercase">Analytics</div>
              </div>
            </component>
          </div>
          <keep-alive>
            <component :is="tab"></component>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import Vue, { defineComponent } from "vue";
import IconBtn from "./icon-btn.vue";
import SvgIcon from "../components/svg-icon.vue";
import i18n from "../i18n";
import SidenavAnalytics from "./sidenav-analytics.vue";
import SidenavNewAnalytics from "./sidenav-new-analytics.vue";
import SidenavSettings from "./sidenav-settings.vue";
import { isReportActive } from "./utils";
import { Icon } from "@iconify/vue2";
import ProductFruitsLauncherButton from "@/components/product-fruits-launcher-button.vue";

const EXPANDED_COOKIE_NAME = "_zoined_sidenav_expanded";

export default defineComponent({
  components: {
    IconBtn,
    SvgIcon,
    SidenavAnalytics,
    SidenavNewAnalytics,
    SidenavSettings,
    Icon,
    ProductFruitsLauncherButton,
  },
  data() {
    const tab: string = null;

    return {
      tab,
      openCategory: null,
      open: false,
      expanded: true,
    };
  },
  computed: {
    hasRouterEnabled() {
      return !!this.$route;
    },
    routePath() {
      return this.$route?.fullPath || location.pathname;
    },
    subscriptionsPath() {
      return "/newsletter_subscriptions";
    },
    expandButtonTooltip() {
      return this.expanded ? i18n.t("sidenav.collapse_menu") : i18n.t("sidenav.expand_menu");
    },
    defaultDashboardTooltip() {
      return i18n.t("dashboard_custom.default_dashboard");
    },
    showNavExpanded() {
      return this.expanded && this.tab;
    },
    showSettings() {
      return this.$store.state.navigation.data?.settings;
    },
    showNewAnalytics() {
      return !!this.$store.state.navigation.data?.new_analytics;
    },
    hasUserCompanyRole() {
      return !!window.zoinedContext.current_role;
    },
    activeReport() {
      const analytics = this.$store.state.navigation.data?.analytics || [];

      const allReports = _.flatten(
        analytics.map((category) => _.flatten(category.folders.map(({ reports }) => reports)))
      );

      return allReports.find((report: any) => isReportActive(report));
    },
    activeNewReport() {
      const newAnalytics = this.$store.state.navigation.data?.new_analytics || [];

      const allReports = _.flatten(
        newAnalytics.map((category) => _.flatten(category.folders.map(({ reports }) => reports)))
      );

      return allReports.find((report: any) => isReportActive(report));
    },
    activeSettings() {
      const settings = this.$store.state.navigation.data?.settings || [];

      const allReports = _.flatten(
        settings.map((category) => _.flatten(category.folders.map(({ reports }) => reports)))
      );

      return allReports.find((report: any) => isReportActive(report));
    },
  },
  created() {
    this.expanded = window.getCookie(EXPANDED_COOKIE_NAME) !== "no";

    this.setCurrentTab();
  },
  methods: {
    openSubscriptions() {
      this.tab = null;
      this.open = false;
      if (!this.$router) {
        // Angular
        location.href = this.subscriptionsPath;
      } else if (this.$route.path !== this.subscriptionsPath) {
        this.$router.push(this.subscriptionsPath);
      }
    },
    toggleTab(tab) {
      if (tab === this.tab) {
        this.open = !this.open;
      } else {
        this.tab = null;
        this.open = true;
        this.$nextTick(() => {
          this.tab = tab;
        });
      }
    },
    onExpandBtnClicked() {
      this.expanded = this.open = !this.expanded;
      window.setCookie(EXPANDED_COOKIE_NAME, this.expanded ? "yes" : "no");
    },
    setCurrentTab() {
      if (this.activeReport) {
        this.tab = "SidenavAnalytics";
      } else if (this.activeNewReport) {
        this.tab = "SidenavNewAnalytics";
      } else if (this.activeSettings) {
        this.tab = "SidenavSettings";
      }
    },
    onRouteChange() {
      this.open = false;
    },
  },
  watch: {
    routePath: [
      {
        handler: "onRouteChange",
      },
    ],
  },
});
</script>
<style lang="scss" scoped>
$grid-float-breakpoint: 992px;

.overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-sidenav-overlay);

  &.open {
    display: block;

    @media (min-width: 1200px) {
      &.expanded {
        display: none;
      }
    }
  }
}

.sidenav-container {
  background-color: var(--color-sidenav-background);
  color: var(--color-sidenav-text);
  z-index: var(--z-index-sidenav);
  overflow: hidden;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;

  height: var(--sidenav-iconbar-width);
  transition: height 0.2s ease 0.1s;

  &.open {
    height: 100%;
    transition: height 0.2s ease 0s;
  }

  .sidenav-iconbar {
    display: flex;
    height: var(--sidenav-iconbar-width);
    width: 100%;
    justify-content: flex-start;
    column-gap: 20px;
    padding: 0 10px;

    .logo-container {
      display: none;
    }

    .trailing-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      @media screen and (min-width: $grid-float-breakpoint) {
        flex-direction: column;
      }
    }

    .expand-btn {
      display: none;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      color: white;
      font-size: 30px;
      font-weight: normal;

      svg {
        outline: none;
      }

      &:hover {
        color: var(--color-sidenav-icon-btn-hover-background);
      }

      @media (min-width: 1200px) {
        display: flex;
      }
    }

    .divider {
      display: none;
    }
  }

  .results-container {
    flex: 1;
    overflow: auto;

    .header {
      margin-top: 25px;
      margin-bottom: 20px;
      padding: 0 15px;

      .results-logo-container {
        .zoined-logo {
          color: white;

          .zoined {
            font-size: 28px;
            font-weight: bold;
            line-height: 1;
          }
        }
      }
    }

    .results {
      opacity: 0;
      transition: opacity 0.2s ease 0s;
    }

    &.open .results,
    &.expanded .results {
      opacity: 1;
      transition: opacity 0.2s ease 0.1s;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    top: 0;
    left: 0;
    bottom: 0;
    height: auto;
    width: auto;
    flex-direction: row;
    justify-content: flex-start;

    .sidenav-iconbar {
      flex-direction: column;
      width: var(--sidenav-iconbar-width);
      height: 100%;
      justify-content: flex-start;
      padding: 0;

      .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      .divider {
        display: block;
        margin: 10px 15px;
        border-bottom: 2px solid var(--color-sidenav-icon-btn-background);
      }
    }

    .results-container {
      width: 0px;
      height: 100%;
      flex: none;
      transition: width 0.2s ease 0.1s;

      &.open {
        width: var(--sidenav-navbar-width);
        transition: width 0.2s ease 0s;
      }
    }
  }

  @media (min-width: 1200px) {
    .results-container {
      &.expanded {
        width: var(--sidenav-navbar-width);
      }
    }
  }

  @media print {
    display: none;
  }
}
</style>

<style lang="scss">
$grid-float-breakpoint: 992px;
$screen-lg-min: 1200px;

// Sidenav placeholder to take space for sidebar
.sidenav-placeholder {
  transition: flex 0.2s ease 0.1s;
  @media screen and (min-width: $grid-float-breakpoint) {
    // Show only on medium screens (sidebar on small screens is shown horizontally in bottom)
    flex: 0 0 var(--sidenav-iconbar-width);
  }
}

// Wider spaceholder when sidebar is expanded
#sidenav.expanded ~ .sidenav-placeholder,
#sidenav.expanded ~ .main-column .sidenav-placeholder {
  @media screen and (min-width: $screen-lg-min) {
    flex: 0 0 var(--sidenav-expanded-width);
    transition: flex 0.2s ease;
  }
}
</style>
