<template>
  <div class="dimension">
    <label class="title">{{ $t("filter.config." + dimension) }}</label>
    <div class="allow-options">
      <uiv-btn-group class="btn-multiple">
        <uiv-btn
          type="primary"
          input-type="radio"
          :input-value="allowOption"
          v-model="allow"
          v-for="allowOption in allowOptions"
          :key="allowOption"
          @click="updateAllow(allowOption)"
          >{{ $t("permissions." + allowOption) }}</uiv-btn
        >
      </uiv-btn-group>
    </div>
    <div class="item-selector" v-if="allow == 'only' || allow == 'except'">
      <pill-list
        :items="items"
        :available-items="availableItems"
        :togglable="false"
        :multi-select="true"
        @update="updateItems"
      ></pill-list>
    </div>
    <div class="effective-config" v-if="effectiveText">{{ effectiveText }}</div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import i18n from "@/i18n";
import Vue, { defineComponent, PropType } from "vue";
import parametersApi from "@/api/parameters-api";
import MenuItem, { menuItemKey } from "@/interfaces/menu-item";
import PillItem from "@/interfaces/pill-item";
import PillList from "@/components/pill-list.vue";
import Permission from "@/model/permission";

export default defineComponent({
  components: { PillList },
  data() {
    const availableItems: MenuItem[] = null;
    const items: PillItem[] = [];
    const allow: string = null;

    return {
      allow,
      items,
      availableItems,
    };
  },
  computed: {
    allowOptions() {
      const allowOptions = ["none", "all", "only"];
      if (this.dimension === "metrics" || this.dimension === "groupings") {
        allowOptions.push("except");
      }
      return allowOptions;
    },
    effectiveText() {
      // Show effectiver permissions notice if effectiveConfig is given
      if (this.config && this.effectiveConfig) {
        return _.isEqual(this.config, this.effectiveConfig)
          ? null
          : i18n.t("permissions.effective_permissions_title") +
              ": " +
              i18n.t("permissions." + this.effectiveConfig.allow) +
              " " +
              (this.effectiveConfig.ids || []).map((id) => this.itemLabel(id)).join(", ");
      }
      return null;
    },
  },
  created() {
    this.fetchAvailableItems();
  },
  methods: {
    updateAllow(allow) {
      const config: Permission = { allow };
      if (config.allow === "only" || config.allow === "except") {
        config.ids = [];
      }
      this.$emit("update", config);
    },
    updateItems({ items }) {
      const config = {
        allow: this.allow,
        ids: items.map(({ value }) => value),
      };
      this.$emit("update", config);
    },
    itemLabel(value) {
      return (
        _.get(
          _.find(this.availableItems, (menuItem) => menuItemKey(menuItem) == value),
          "name"
        ) || value
      );
    },
    async fetchAvailableItems() {
      this.availableItems = await parametersApi.get(this.dimension).then((result) => result.data);
    },
    onConfigChange() {
      this.allow = this.config.allow;
      this.items = (this.config.ids || []).map((id) => ({ value: id, name: id, enabled: true }));
    },
  },
  props: {
    dimension: {
      type: String,
    },
    config: {
      type: Object as PropType<Permission>,
    },
    effectiveConfig: {
      type: Object as PropType<Permission>,
    },
  },
  watch: {
    config: [{ deep: true, immediate: true, handler: "onConfigChange" }],
  },
});
</script>

<style lang="scss" scoped>
label {
  font-weight: normal;
}

.dimension {
  .title {
    display: block;
    float: left;
    margin-top: 7px;
    margin-right: 10px;
  }

  .allow-options {
    display: block;
    margin: 0 0 5px 60px;
  }

  .item-selector {
    display: block;
    margin: 0 0 0 60px;
  }

  .effective-config {
    margin: 0 0 0 60px;
    font-size: 0.8em;
  }
}
</style>
