import { render, staticRenderFns } from "./chart-card.vue?vue&type=template&id=3cc3ed04&scoped=true"
import script from "./chart-card.vue?vue&type=script&setup=true&lang=ts"
export * from "./chart-card.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./chart-card.vue?vue&type=style&index=0&id=3cc3ed04&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc3ed04",
  null
  
)

export default component.exports