<template>
  <div class="sidenav-folder">
    <div class="sidenav-folder-title" v-if="name" @click="open = !open">{{ name }}</div>
    <div class="reports-container" v-if="open">
      <slot name="reports">
        <div class="reports">
          <sidenav-report :report="report" v-for="report in reports" :key="report.path"></sidenav-report>
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import SidenavReport from "./sidenav-report.vue";

export default defineComponent({
  components: { SidenavReport },
  data() {
    return {
      open: true,
    };
  },
  props: {
    name: {
      type: String,
    },
    reports: {
      type: Array as PropType<any[]>,
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-folder {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidenav-folder-title {
  margin-bottom: 5px;
  padding-left: 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-sidenav-category-title);
}
</style>
