<template>
  <spinner v-if="!teams"></spinner>
  <table class="table table-hover" v-else>
    <thead>
      <tr>
        <th>{{ $t("activerecord.attributes.team.name") }}</th>
        <th>{{ $t("company_admin.teams.members_count") }}</th>
        <th>{{ $t("company_admin.teams.newsletter_subscriptions_count") }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="teams.length === 0">
        <td colspan="4">{{ $t("table.no_results") }}</td>
      </tr>
      <tr v-for="team in teams" :key="team.id">
        <td>{{ team.name }}</td>
        <td>{{ team.members_count }}</td>
        <td>{{ team.newsletter_subscriptions_count }}</td>
        <td class="text-right">
          <a class="mr-sm" @click="$emit('edit', team)">{{ $t("actions.edit") }}</a
          ><a @click="$emit('goto-members', team)">{{ $t("company_admin.teams.members") }}</a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import Team from "../model/team";
import Spinner from "../components/spinner.vue";

export default defineComponent({
  components: {
    Spinner,
  },
  props: {
    teams: {
      type: Array as PropType<Team[]>,
    },
  },
});
</script>
