<template>
  <div class="chart-card flex-row gap-lg align-items-flex-start cursor-pointer" @click="$emit('click')">
    <div class="chart-icon">
      <svg-icon :name="icon" />
    </div>
    <div class="flex-column text-xs">
      <div class="fg-text-primary">{{ mainTitle }}</div>
      <div class="fg-text-variant">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChartState } from "@/ai-assistant/ai-assistant-lottery-state";
import { useI18n } from "@/composables/i18n";
import { useStore } from "@/composables/store";
import MenuItem, { menuItemKey } from "@/interfaces/menu-item";
import { computed } from "vue";
import SvgIcon from "@/components/svg-icon.vue";
import { getComponentIconName } from "@/ai-assistant/ai-utils";

const props = defineProps<{
  chart: ChartState;
}>();

const store = useStore();

const { t } = useI18n();

const metrics = computed<MenuItem[]>(() => store.getters.getParameters("metrics"));
const groupings = computed<MenuItem[]>(() => store.getters.getParameters("grouping"));

const metricName = computed(() => {
  return metrics.value.find((m) => menuItemKey(m) === props.chart.metric)?.name;
});

const groupingName = computed(() => {
  return groupings.value.find((g) => menuItemKey(g) === props.chart.grouping)?.name;
});

const timeSelectionName = computed(() => {
  return t("filters.date_selector." + props.chart.selection);
});

const comparisonName = computed(() => {
  return props.chart.comparison && t("filters.benchmark." + props.chart.comparison);
});

const mainTitle = computed(() => {
  if (comparisonName.value) {
    return t("ai_assistant.chart_card_title_with_comparison", {
      time_selection: timeSelectionName.value,
      comparison: comparisonName.value,
      metric: metricName.value,
    });
  } else {
    return t("ai_assistant.chart_card_title", {
      time_selection: timeSelectionName.value,
      metric: metricName.value,
    });
  }
});

const subTitle = computed(() => {
  return t("ai_assistant.chart_card_subtitle", {
    grouping: groupingName.value,
  });
});

const icon = computed(() => {
  return getComponentIconName(props.chart.chart);
});
</script>

<style scoped lang="scss">
.chart-card {
  background-color: var(--color-interactive-fill-neutral-low-contrast);
  border-radius: var(--border-radius-sm);
  border: 1.5px solid var(--color-border-secondary);
  padding: 10px;

  &:hover {
    background-color: var(--color-surface-neutral-1);
    border: 1.5px solid var(--color-border-emphasis);
  }
}

.chart-icon {
  width: 40px;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
