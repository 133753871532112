import { productFruits } from "product-fruits";
import i18 from "@/i18n";

const supportedLocales = ["en", "fi"];

const currentLocale = i18.locale.split("-")[0];

const locale = supportedLocales.includes(currentLocale) ? currentLocale : "en";

if (window.zoinedContext.productFruits) {
  productFruits.init(
    window.zoinedContext.productFruits.workspaceCode,
    locale,
    window.zoinedContext.productFruits.userInfo,
    { hideInAppCenterLauncher: true }
  );
}
