<template>
  <div class="team-listing">
    <div>
      <div class="pull-right">
        <a class="btn btn-primary" @click="openTeamModal()">{{ $t("setup.teams.add_team") }}</a>
      </div>
      <div class="table-controls">
        <search-bar v-model="search"></search-bar>
      </div>
      <div>
        <team-table :teams="teams" @edit="openTeamModal" @goto-members="gotoMembers"></team-table>
        <div class="centered" v-if="total > perPage">
          <uiv-pagination v-model="page" :total-page="Math.ceil(total / perPage)"></uiv-pagination>
        </div>
      </div>
    </div>
    <team-modal ref="teamModal" @save="fetchTeams" @delete="fetchTeams"></team-modal>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import Team from "../model/team";
import adminTeamsApi from "../api/admin-teams-api";
import SearchBar from "../components/search-bar.vue";
import TeamTable from "./team-table.vue";
import TeamModal from "./team-modal.vue";

export default defineComponent({
  components: {
    SearchBar,
    TeamTable,
    TeamModal,
  },
  data() {
    const teams: Team[] = null;

    return {
      page: 1,
      perPage: 15,
      search: "",
      total: 0,
      teams,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    params() {
      const params = {
        page: this.page,
        per_page: this.perPage,
        company_id: this.companyId,
      };

      if (this.search.length > 0) {
        params["search"] = this.search;
      }

      return params;
    },
    teamModal: {
      cache: false,
      get() {
        return this.$refs["teamModal"] as TeamModal;
      },
    },
  },
  methods: {
    async openTeamModal(team: Team = null) {
      if (team) {
        team = await adminTeamsApi.get({ id: team.id, company_id: this.companyId });
      }
      this.teamModal.show(team);
    },
    gotoMembers(team: Team) {
      this.$router.push(`teams/${team.id}/members`);
    },
    async fetchTeams() {
      this.teams = await adminTeamsApi.get(this.params);
      this.total = (this.teams as any).total_count;
    },
    onSearchChange() {
      this.page = 1;
    },
  },
  watch: {
    params: [{ immediate: true, handler: "fetchTeams" }],
    search: [
      {
        handler: "onSearchChange",
      },
    ],
  },
});
</script>
