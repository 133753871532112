<template>
  <div class="drilldown-provider">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
export default defineComponent({
  provide() {
    return {
      drilldownData: this.drilldownData,
    };
  },
  data() {
    return {
      drilldownData: {
        selected: null,
        metric: null,
        currentGrouping: null,
        userChangedDrilldown: false,
      },
    };
  },
});
</script>
