<template>
  <div class="flex-inline-row align-items-center flex-wrap gap-lg">
    <div class="title" v-if="title">{{ title }}</div>
    <dropdown-select button-style="primary" v-bind="$attrs" v-on="$listeners"></dropdown-select>
  </div>
</template>

<script>
import DropdownSelect from "./dropdown-select.vue";

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  components: {
    DropdownSelect,
  },
};
</script>
