<template>
  <span>
    <dropdown :open="open">
      <template slot="toggle">
        <div class="btn-group">
          <button class="btn btn-primary" type="button" @click="newDashboard()">
            {{ $t("dashboard_custom.new_dashboard") }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="open = !open"
            @close="open = false"
            v-if="enableTemplates"
          >
            <span class="caret"></span>
          </button>
        </div>
      </template>
      <template slot="menu">
        <li>
          <a @click="newDashboard()">{{ $t("dashboard_custom.create_empty_dashboard") }}</a
          ><a @click="newDashboardFromTemplate()">{{ $t("dashboard_custom.create_dashboard_from_template") }}</a>
        </li>
      </template>
    </dropdown>
    <dashboard-from-template-modal ref="dashboardFromTemplateModal"></dashboard-from-template-modal
  ></span>
</template>

<script lang="ts">
import Vue from "vue";
import dropdown from "./dropdown.vue";
import DashboardFromTemplateModal from "../custom-dashboard-editor/dashboard-from-template-modal.vue";

export default Vue.extend({
  props: {
    enableTemplates: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    dropdown,
    DashboardFromTemplateModal,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    newDashboard() {
      this.open = false;
      location.href = "/dashboards/new";
    },
    newDashboardFromTemplate() {
      this.open = false;
      this.toggleDashboardsDropdown();
      this.$refs.dashboardFromTemplateModal.show();
    },
    toggleDashboardsDropdown() {
      const dropdown = document.querySelector("#dashboards-dropdown");
      dropdown.classList.remove("open");
    },
  },
});
</script>
