<template>
  <div class="metric-extremes-section">
    <div class="description">{{ $t("newsletter.definition.sections.metric_extremes.description") }}</div>
    <div class="options">
      <div class="option">
        <label>{{ $t("filter.config.grouping") }}</label>
        <select :value="section.grouping" @input="updateGrouping">
          <option v-for="option in groupingOptions" :value="option.id">{{ option.name }}</option>
        </select>
      </div>
      <div class="option">
        <label>{{ $t("filter.config.metrics") }}</label>
        <select :value="section.metric" @input="updateMetric">
          <option v-for="option in metricsOptions" :value="option.id">{{ option.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MenuItem from "../../interfaces/menu-item";
import Actions from "../../store/actions";
import Vue, { defineComponent, PropType } from "vue";
export default defineComponent({
  computed: {
    metricsOptions(): MenuItem[] {
      return this.$store.state.parameters.metrics.all;
    },
    groupingOptions(): MenuItem[] {
      return this.$store.state.parameters.grouping.all;
    },
  },
  methods: {
    updateGrouping(event) {
      const grouping = event.target.value;
      this.$emit("update-section", { ...this.section, grouping });
    },
    updateMetric(event) {
      const metric = event.target.value;
      this.$emit("update-section", { ...this.section, metric, insights: null });
    },
  },
  props: {
    section: {
      type: Object as PropType<any>,
    },
  },
});
</script>

<style lang="scss" scoped>
.description {
  width: 200px;
  float: left;
  text-align: right;
  padding: 5px 15px 0 0;
}
</style>
