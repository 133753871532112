<template>
  <form class="prompt-field" @submit.prevent="onSubmit">
    <input
      type="text"
      :placeholder="t('ai_assistant.ask_from_zed')"
      :disabled="disabled"
      :value="value"
      @focus="onFocus"
      @input="onInput"
    />
    <div class="zed-image"></div>
    <IconButton
      class="submit-button"
      icon="fluent:arrow-right-16-filled"
      size="small"
      type="submit"
      :disabled="submitDisabled"
    />
  </form>
</template>

<script setup lang="ts">
import IconButton from "@/components/icon-button.vue";
import { useI18n } from "@/composables/i18n";
import { computed } from "vue";

const props = defineProps<{
  value: string;
  disabled: boolean;
}>();

const emit = defineEmits(["input", "focus", "submit"]);

const { t } = useI18n();

const submitDisabled = computed(() => props.value.trim() === "");

const onFocus = () => {
  emit("focus");
};

const onInput = (event: InputEvent) => {
  emit("input", (event.target as HTMLInputElement).value);
};

const onSubmit = () => {
  if (submitDisabled.value) {
    return;
  }
  emit("submit");
};
</script>

<style scoped lang="scss">
$grid-float-breakpoint: 992px;

.prompt-field {
  position: relative;

  input[type="text"] {
    border: none;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
    max-width: unset;
    background: var(--color-surface-neutral-2);
    color: var(--color-text-variant);
    font-size: 14px;
    border-radius: 9999px;
    padding-left: 40px;
    padding-right: 30px;

    @media (min-width: $grid-float-breakpoint) {
      padding-left: 12px;
      border-radius: var(--border-radius-sm);
    }

    &:focus,
    &:not(:placeholder-shown) {
      background-color: transparent;
      border: 1px solid var(--color-border-emphasis);
      // border-radius: var(--border-radius-sm);

      ~ .zed-image {
        // display: none;
      }
    }
  }

  .zed-image {
    background-image: url("~@assets/images/zed-front.png");
    background-size: cover;
    background-color: var(--color-text-emphasis-primary);
    width: 32px;
    height: 32px;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 9999px;

    @media (min-width: $grid-float-breakpoint) {
      display: none;
    }
  }

  .submit-button {
    position: absolute;
    top: 5px;
    right: 8px;
  }
}
</style>
