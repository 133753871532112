<template>
  <div class="update-times-container container" v-if="updateTimes">
    <div class="row">
      <div class="col-xs-12">
        <flyover-handle class="handle" :title="title">
          <table class="updates">
            <tr>
              <th>{{ $t("update_times.data_set") }}</th>
              <th>{{ $t("update_times.last_updated") }}</th>
            </tr>
            <tr v-for="update in updates">
              <td>{{ update.name }}</td>
              <td>{{ update.last_updated }}</td>
            </tr>
          </table>
        </flyover-handle>
      </div>
    </div>
  </div>
</template>
<script>
import UpdateTimesApi from "../api/update-times-api-service";
import flyoverHandle from "./flyover-handle";
import moment from "moment";

export default {
  components: {
    flyoverHandle,
  },
  data() {
    return {
      updateTimes: null,
    };
  },
  computed: {
    title() {
      return I18n.t("update_times.last_updated_at_date", {
        date: this.formatDate(this.updateTimes.last_updated),
      });
    },
    updates() {
      return this.updateTimes.entities.map(({ entity_name, last_updated }) => ({
        name: entity_name,
        last_updated: this.formatDate(last_updated),
      }));
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("L, LT");
    },
  },
  mounted() {
    new UpdateTimesApi()
      .getUpdateTimes()
      .then((times) => (this.updateTimes = times))
      .catch(() => (this.updateTimes = null));
  },
};
</script>
<style lang="scss" scoped>
.update-times-container {
  height: 0px;
  .col-xs-12 {
    min-height: 0;
  }
  .handle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
  }
  table.updates {
    margin-top: 5px;

    th,
    td {
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}
</style>
