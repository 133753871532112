import * as i18n from "./vue/filters/i18n";
import numeral from "numeral";
import "numeral/locales";
import { formatNumber } from "./formatters";

const locales = [
  "bg",
  "chs",
  "cs",
  "da-dk",
  "de-ch",
  "de",
  "en-au",
  "en-gb",
  "en-za",
  "es-es",
  "es",
  "et",
  "fi",
  "fr-ca",
  "fr-ch",
  "fr",
  "hu",
  "it",
  "ja",
  "lv",
  "nl-be",
  "nl-nl",
  "no",
  "pl",
  "pt-br",
  "pt-pt",
  "ru-ua",
  "ru",
  "sk",
  "sl",
  "th",
  "tr",
  "uk-ua",
  "vi",
];

export const formatDashboxCurrency = (value, precision, currency = "€", locale = null) => {
  return formatNumber(value, { precision, locale });
};

export const formatDashboxPercentageChange = (value, precision = 0, locale = null, plusSign = true) => {
  locale = locale || i18n.locale;
  locales.includes(locale) ? numeral.locale(locale) : numeral.locale("en");
  let positive;
  value > 0 ? (positive = true) : null;

  if (Math.abs(value) > 10000) {
    return (positive && plusSign ? "+" : "") + numeral(value / 100).format("0.0a %");
  } else {
    return (positive && plusSign ? "+" : "") + numeral(value / 100).format("0.0 %");
  }
};

export const formatDashboxCurrencyChange = (value, precision, currency = "€", locale = null) => {
  const positive = value > 0;
  return (positive ? "+" : "") + formatDashboxCurrency(value, precision, currency, locale);
};
