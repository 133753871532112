<template>
  <div class="btn-group btn-multiple">
    <div class="btn btn-primary intercom-tag-restrict-access-yes" :class="{ active: value }" @click="update(true)">
      Yes
    </div>
    <div class="btn btn-primary" :class="{ active: !value }" @click="update(false)">No</div>
  </div>
</template>

<script setup lang="ts">
defineProps<{ value: boolean }>();

const emit = defineEmits(["input"]);

const update = (value) => emit("input", value);
</script>
