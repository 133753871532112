<template>
  <div>
    <div class="custom-report-editor" id="layout-container">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">{{ $t("dashboard_custom.report_editor.title") }}</label>
            <input id="name" type="text" v-model="report.metadata.title" />
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label for="description">{{ $t("dashboard_custom.report_editor.description") }}</label>
            <textarea id="description" v-model="report.metadata.description"></textarea>
          </div>
        </div>
      </div>
      <div class="row" v-if="isAdmin || isTeamAdmin">
        <div class="col-md-12">
          <report-owner-selector :my-report="myReport" @update-my-report="updateMyReport"></report-owner-selector>
        </div>
      </div>
      <div class="row" v-if="companyReport">
        <div class="col-md-12">
          <div class="form-group">
            <div>
              <label for="restricted">{{ $t("activerecord.attributes.my_report.restrict_access") }}</label>
            </div>
            <restrict-access-selector v-model="myReport.restrict_access" />
          </div>
        </div>
      </div>
      <div class="row" v-if="companyReport && myReport.restrict_access">
        <div class="col-md-12 flex-row align-items-center flex-wrap gap-lg column-gap-xxl">
          <user-role-pill-list v-model="myReport.my_report_accesses"></user-role-pill-list>
          <team-pill-list v-model="myReport.my_report_accesses"></team-pill-list>
        </div>
      </div>
      <div class="row" v-if="partnerReport">
        <div class="col-md-12">
          <visible-to-customers-selector v-model="myReport.visible_to_customers"></visible-to-customers-selector>
        </div>
      </div>
      <div class="row" v-if="partnerReport && myReport.visible_to_customers == 'selected'">
        <div class="col-md-12">
          <company-my-report-access-editor v-model="myReport.my_report_accesses"></company-my-report-access-editor>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("custom_report.report") }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="m-sm type-item component"
            v-for="reportType in reportTypes"
            :class="{ selected: reportType.config.report_type === selectedReportType.config.report_type }"
            @click="selectReportType(reportType.config.report_type)"
          >
            <div class="chartImg" v-bind:class="reportType.config.report_type.replace(/[^a-zA-Z]/g, '')"></div>
            <p class="type-text">
              {{ $t("dashboard_custom.report_editor.component_types." + reportType.config.report_type) }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("dashboard_custom.report_editor.filters") }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
            <time-period-dropdown
              v-if="isFilterEnabled('date')"
              :available-items="options.time_ranges"
              :available-series-types="options.seriesTypes"
              :value="filterConfiguration.time.selection"
              @update="updateSelection($event)"
            ></time-period-dropdown>
            <comparison-pill-list
              v-if="isFilterEnabled('comparisons')"
              :radio="isRadio('comparisons')"
              :comparisons="filterConfiguration.time.comparisons"
              @update="updateComparisons"
            ></comparison-pill-list>
            <business-hours-selector
              v-if="isFilterEnabled('limit_to_business_hours')"
              :value="filterConfiguration.time.limit_to_business_hours"
              @update="updateBusinessHours($event)"
            ></business-hours-selector>
          </div>
          <widget-filters-section
            :filter-configuration="filterConfiguration"
            :report-config="selectedReportType.config"
            @update="updateFilterConfiguration"
          ></widget-filters-section>
          <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
            <filterset-selector
              :config="filterConfiguration.filters"
              @update="updateFiltersConfig"
            ></filterset-selector>
            <show-filterset-name-toggle
              v-model="myReport.control_state.show_filterset_name"
              v-if="hasEnabledFilterSets"
            ></show-filterset-name-toggle>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg intercom-tag-create-report-filters-section"
          >
            <filter-selector
              :config="filters[filter.id]"
              :filter="filter"
              @update="updateFilter(filter.id, $event)"
              v-for="filter in options.filters"
              :key="filter.id"
            ></filter-selector>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("actions.preview") }}</h4>
        </div>
      </div>
      <div v-if="valid">
        <div class="row mb-xl" v-for="component in report.config.components">
          <div :class="'col-md-' + (component.span || 12)">
            <zoined-report-component
              :component="component"
              :filter-configuration="flatFilterConfiguration"
              :chart-options="chartOptions"
              :table-config="tableConfig"
              :highchart-options="highchartOptions"
              :custom="true"
              :dashboard="false"
              @filter-configuration-updated="updateFilterConfiguration"
              @chart-options-updated="updateChartOptions"
              @table-config-updated="updateTableConfig"
              @highchart-options-updated="updateHighchartOptions"
            ></zoined-report-component>
          </div>
        </div>
      </div>
      <div class="help-block" v-else-if="report.config.help_key">
        <i class="fa fa-info-circle mr-sm"></i><span>{{ $t("report.help." + report.config.help_key) }}</span>
      </div>
      <div class="row buttons">
        <div class="col-md-12">
          <div class="pull-right">
            <button
              class="pull-right btn btn-primary intercom-tag-save-report-button"
              @click="save"
              :disabled="submitDisabled"
            >
              {{ $t("actions.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import _ from "lodash";
import {
  refreshFilterTimes,
  convertToNewFilterConfiguration,
  flatFilterConfiguration,
  filterToFlyover,
  mergeFilterConfigurations,
} from "../lib/filter-util";
import userRolePillList from "../components/user-role-pill-list.vue";
import teamPillList from "../components/team-pill-list.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import filterSelector from "../components/filter-selector.vue";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import { reportTypes } from "./report-types";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import WidgetFiltersSection from "../flyover-filters/widget-filters-section.vue";
import FilterConfiguration from "../model/filter-configuration";
import i18n from "../i18n";
import ReportOwnerSelector from "../components/report-owner-selector.vue";
import MyReport from "../model/my-report";
import Actions from "../store/actions";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";
import { makeApiInstance } from "../api/instance";
import { hideOverlay, showOverlay } from "@/ui/overlay";
import visibleToCustomersSelector from "@/components/visible-to-customers-selector.vue";
import CompanyMyReportAccessEditor from "@/components/my-report-access/company-my-report-access-editor.vue";
import RestrictAccessSelector from "@/components/restrict-access-selector.vue";

export default defineComponent({
  components: {
    userRolePillList,
    teamPillList,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    zoinedReportComponent,
    WidgetFiltersSection,
    ReportOwnerSelector,
    visibleToCustomersSelector,
    CompanyMyReportAccessEditor,
    RestrictAccessSelector,
  },
  data() {
    const isAdmin: boolean = null;
    const myReport: any = null;
    const report: any = null;

    return {
      report,
      myReport,
      isAdmin,
      selectedReportType: null,
      initialData: null,
    };
  },
  computed: {
    companyReport() {
      return !this.myReport.user_id && !this.myReport.team_id && !this.myReport.partner_id;
    },
    partnerReport() {
      return !!this.myReport.partner_id;
    },
    teamsAsAdmin() {
      return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
    },
    isTeamAdmin() {
      return this.teamsAsAdmin.length > 0;
    },
    submitDisabled() {
      return this.report.metadata.title.length == 0 || !this.valid;
    },
    reportTypes() {
      return reportTypes.filter((reportType) => {
        return _.every(reportType.config.required_features, (feature) => window.zoinedContext.features[feature]);
      });
    },
    flatFilterConfiguration() {
      return flatFilterConfiguration(this.filterConfiguration);
    },
    filterConfiguration() {
      return _.cloneDeep(this.myReport.filters);
    },
    chartOptions() {
      return _.cloneDeep(this.myReport.control_state);
    },
    tableConfig() {
      return _.cloneDeep(this.myReport.table_config);
    },
    highchartOptions() {
      return _.cloneDeep(this.myReport.highchart_options);
    },
    options() {
      const sort = this.$store.getters.getParameters("sort") || [];
      const seriesTypes = window.zoinedContext?.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
      const groupings = _.clone(this.$store.getters.getParameters("grouping"));
      const metrics = this.$store.state.parameters.metrics.all;
      const snippets = this.$store.state.parameters.metrics.all?.map((item) => ({ ...item, snippet: true }));
      const filters = this.$store.state.parameters.filters.all;
      const time_ranges = this.$store.state.parameters.timePeriods.all;

      return {
        metrics: metrics || [],
        snippets: snippets || [],
        time_ranges: time_ranges || [],
        filters: filters || [],
        sort,
        seriesTypes,
        groupings,
      };
    },
    filters() {
      return _.reduce(
        this.filterConfiguration.filters.filters,
        (result, config, filter) => {
          return {
            ...result,
            [filter]: _.fromPairs(
              _.map(config, ({ enabled, exclude, value, name, wildcard }, key) => [
                key,
                {
                  value: key,
                  enabled,
                  exclude: !!exclude,
                  name: name || value || key,
                  wildcard: !!wildcard,
                },
              ])
            ),
          };
        },
        {}
      );
    },
    hasEnabledFilterSets() {
      let { sets } = this.filterConfiguration.filters;
      sets = sets || [];
      return sets.filter(({ enabled }) => enabled).length > 0;
    },
    valid(): boolean {
      const validate = this.selectedReportType.validate || (() => true);
      return validate(this.flatFilterConfiguration);
    },
    saveData() {
      if (!this.report) {
        return null;
      }
      return _.cloneDeep({
        report: {
          ...this.report,
          config: JSON.stringify(this.report.config),
        },
        my_report: {
          filters: this.myReport.filters,
          restrict_access: this.myReport.restrict_access,
          control_state: this.myReport.control_state,
          table_config: this.myReport.table_config,
          highchart_options: this.myReport.highchart_options,
          my_report_accesses: this.myReport.my_report_accesses,
          visible_to_customers: this.myReport.visible_to_customers,
          user_id: this.myReport.user_id,
          team_id: this.myReport.team_id,
          partner_id: this.myReport.partner_id,
          company_id: this.myReport.company_id,
        },
      });
    },
    hasChanges() {
      return this.initialData && !_.isEqual(this.initialData, this.saveData);
    },
    isNew() {
      return !this.report.id;
    },
  },
  created() {
    const report = _.cloneDeep(this.defaultReport);
    const myReport = _.cloneDeep(this.defaultMyReport);

    myReport.restrict_access = !!myReport.restrict_access;
    myReport.control_state = myReport.control_state || {};

    const defaultConfig = {
      selection: { type: "4_weeks" },
      comparisons: [{ type: "prev_year_corresponding", enabled: true }],
    };
    const filterConfigurations = _.compact([
      defaultConfig,
      report.config.filterConfiguration,
      myReport.filters,
    ]).map((config) => convertToNewFilterConfiguration(config));
    myReport.filters = mergeFilterConfigurations(...filterConfigurations);
    myReport.filters.time = refreshFilterTimes(myReport.filters.time);
    delete report.config.filterConfiguration;

    this.report = report;
    this.myReport = myReport;
    this.isAdmin = !!window.zoinedContext?.isAdmin;

    this.selectReportType(this.report.config.report_type);
    this.initialData = this.saveData;
  },
  methods: {
    selectReportType(type) {
      this.selectedReportType =
        reportTypes.find((reportType) => reportType.config.report_type === type) || reportTypes[0];
      this.report.config = this.selectedReportType.config;

      // some sensible global defaults
      const defaults: any = filterToFlyover({
        selection: { type: "4_weeks" },
        comparisons: [{ type: "prev_year_corresponding", enabled: true }],
        metrics: { sales: { enabled: true } },
        grouping: { store: { enabled: true } },
      });

      if (this.isNew) {
        defaults.column_grouping = { weekday: { enabled: true } };
      }

      //  defaults for selected type
      const reportTypeDefaults = filterToFlyover(
        Object.keys(this.selectedReportType.filters || {}).reduce((defaults, key) => {
          const defaultValue = _.get(this.selectedReportType.filters[key], "default");
          return defaultValue
            ? {
                ...defaults,
                [key]: _.isFunction(defaultValue) ? defaultValue(this.flatFilterConfiguration) : defaultValue,
              }
            : defaults;
        }, this.selectedReportType.defaults || {})
      );

      // Get names of active filters. Some filters might have some default value set.
      const activeFilters = _.union(this.report.config.filters, Object.keys(this.selectedReportType.defaults || {}));

      if (activeFilters.includes("date")) {
        // Filter key for date is selection (not date)
        activeFilters.push("selection");
      }

      // remove filters that are not used for this report type and set correct defaults
      Vue.set(
        this.myReport,
        "filters",
        Object.assign({}, this.myReport.filters, {
          time: _.pick({ ...defaults.time, ...this.myReport.filters.time, ...reportTypeDefaults.time }, activeFilters),
          widgets: _.pick(
            { ...defaults.widgets, ...reportTypeDefaults.widgets, ...this.myReport.filters.widgets },
            activeFilters
          ),
          raw_filters: _.pick(
            { ...defaults.raw_filters, ...reportTypeDefaults.raw_filters, ...this.myReport.filters.raw_filters },
            activeFilters
          ),
        })
      );

      Vue.set(this.myReport, "control_state", {
        ...this.myReport.control_state,
        ...this.selectedReportType.config.default_control_state,
      });
    },
    setRestricted(restricted) {
      Vue.set(this.myReport, "restrict_access", restricted);
    },
    isFilterEnabled(filter) {
      return this.report.config.filters?.includes(filter);
    },
    isRadio(filter) {
      return this.report.config.radio_selectors?.includes(filter);
    },
    updateSelection(selection) {
      this.myReport.filters.time.selection = selection;
      this.myReport.filters.time = refreshFilterTimes(this.myReport.filters.time);
    },
    updateComparisons(comparisons) {
      Vue.set(this.myReport.filters.time, "comparisons", comparisons);
    },
    updateBusinessHours(limitToBusinessHours) {
      Vue.set(this.myReport.filters.time, "limit_to_business_hours", limitToBusinessHours);
    },
    updateFiltersConfig(config) {
      Vue.set(this.myReport.filters, "filters", config);
    },
    updateMyReport(myReport: MyReport) {
      Vue.set(this, "myReport", myReport);
    },
    updateFilter(filter, filterConfig: FilterConfigurationItemMap) {
      if (!_.isEmpty(filterConfig)) {
        const config = _.reduce(
          _.values(filterConfig),
          (result, { enabled, exclude, value, name, wildcard }) => ({
            ...result,
            [value]: { enabled, exclude: !!exclude, value, wildcard, name: name || value },
          }),
          {}
        );
        console.log(config);
        Vue.set(this.myReport.filters.filters.filters, filter, config);
      } else {
        Vue.delete(this.myReport.filters.filters.filters, filter);
      }
    },
    updateFilterConfiguration(filterConfiguration: FilterConfiguration) {
      filterConfiguration = convertToNewFilterConfiguration(filterConfiguration);
      Vue.set(this.myReport, "filters", filterConfiguration);
    },
    updateChartOptions(chartOptions: ChartOptions) {
      Vue.set(this.myReport, "control_state", chartOptions);
    },
    updateTableConfig(tableConfig: TableConfig) {
      Vue.set(this.myReport, "table_config", tableConfig);
    },
    updateHighchartOptions(highchartOptions) {
      Vue.set(this.myReport, "highchart_options", highchartOptions);
    },
    save() {
      if (!this.valid) {
        return;
      }
      showOverlay(i18n.t("please_wait"));
      const url = this.report.id ? `/api/v1/reports/${this.report.id}` : `/api/v1/reports`;
      const method = this.report.id ? "PUT" : "POST";
      const data = (this.initialData = this.saveData);
      console.log(data);
      return makeApiInstance()
        .request({
          url,
          method,
          data,
        })
        .then((response) => response.data)
        .then(({ id }) => {
          this.$router.push({ name: "custom_report", params: { id } });
          this.$store.dispatch(Actions.fetchNavigation);
        })
        .finally(() => {
          hideOverlay();
        });
    },
  },
  props: {
    defaultReport: {
      type: Object as PropType<any>,
    },
    defaultMyReport: {
      type: Object as PropType<any>,
    },
  },
});
</script>

<style scoped lang="scss">
#layout-container {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.buttons {
  margin-top: 30px;
}

.show {
  text-align: center;

  a:hover {
    cursor: pointer;
  }
}

.row h4 {
  margin: 20px 0;
}
</style>
