<template>
  <div class="flex-row align-items-center gap-lg intercom-tag-filters-metrics-width">
    <div class="title">{{ $t("filter.config.span") }}</div>
    <btn-group class="btn-multiple">
      <btn class="btn-sm" type="primary" input-type="radio" :input-value="6" v-model="model" @click="update(6)"
        >1/2</btn
      >
      <btn class="btn-sm" type="primary" input-type="radio" :input-value="4" v-model="model" @click="update(4)"
        >1/3</btn
      >
      <btn class="btn-sm" type="primary" input-type="radio" :input-value="3" v-model="model" @click="update(3)"
        >1/4</btn
      >
    </btn-group>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import { Btn, BtnGroup } from "uiv";
export default defineComponent({
  components: {
    Btn,
    BtnGroup,
  },
  data() {
    return {
      model: 6,
    };
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
    setModel() {
      this.model = this.value || 6;
    },
  },
  props: {
    value: {
      type: Number,
    },
  },
  watch: {
    value: [{ immediate: true, handler: "setModel" }],
  },
});
</script>
