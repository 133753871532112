<template>
  <modal v-model="open" :title="title" size="sm" :append-to-body="true">
    <form class="form" v-if="model" @submit.prevent="save">
      <div class="form-group">
        <label class="control-label">{{ $t("attributes.name") }}</label>
        <input class="form-control" type="text" v-model="model.name" />
      </div>
    </form>
    <div slot="footer">
      <button class="btn btn-default" @click="hide">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" @click="save">{{ $t("actions.ok") }}</button>
    </div>
  </modal>
</template>

<script lang="ts">
import Category from "../model/category";
import Vue, { defineComponent } from "vue";
import { Modal } from "uiv";

export default defineComponent({
  components: {
    Modal,
  },
  data() {
    const model: Category = null;

    return {
      open: false,
      model,
    };
  },
  computed: {
    title() {
      return this.model?.id ? "Edit folder" : "New folder";
    },
  },
  methods: {
    show(category: Category) {
      this.model = { ...category };
      this.open = true;
    },
    hide() {
      this.open = false;
    },
    save() {
      this.$emit("save", this.model);
      this.open = false;
    },
  },
});
</script>
