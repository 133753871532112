<template>
  <modal
    class="report-template-from-report-modal"
    v-model="open"
    :append-to-body="true"
    :title="$t('report_templates.select_report')"
    :footer="false"
  >
    <div class="loading-indicator" v-if="!reports">
      <spinner></spinner>
    </div>
    <div class="content" v-else>
      <div class="item" v-for="report in reports" @click="createFromReport(report)">{{ report.name }}</div>
    </div>
  </modal>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import { Modal } from "uiv";
import spinner from "../../components/spinner.vue";
import { makeApiInstance } from "../../api/instance";

export default defineComponent({
  components: {
    Modal,
    spinner,
  },
  data() {
    return {
      open: false,
      reports: null,
    };
  },
  computed: {
    reportsApi() {
      return makeApiInstance({
        baseURL: "/api/v1/reports",
      });
    },
  },
  methods: {
    show() {
      this.open = true;
      if (!this.reports) {
        this.fetchReports();
      }
    },
    createFromReport(report) {
      this.open = false;
      this.$emit("select", report.id);
    },
    fetchReports() {
      this.reportsApi.get("company_reports").then((result) => (this.reports = result.data));
    },
  },
});
</script>

<style lang="scss" scoped>
.report-template-from-report-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
