<template>
  <dropdown
    class="inline-block"
    ref="dropdown"
    tag="span"
    v-model="open"
    :not-close-elements="notCloseElements"
    :append-to-body="appendDropdownToBody"
  >
    <slot name="default"></slot>
    <div class="item-menu-dropdown" slot="dropdown">
      <item-menu
        :search-query="searchQuery"
        v-bind="$attrs"
        @select="onSelect($event)"
        @deselect="onDeselect($event)"
        @search="onSearch($event)"
        @delete="$emit('delete', $event)"
      >
        <template slot="footer" v-if="$slots['footer']">
          <slot name="footer"></slot>
        </template>
      </item-menu>
    </div>
  </dropdown>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import { Dropdown } from "uiv";
import itemMenu from "./item-menu.vue";

export default defineComponent({
  components: {
    Dropdown,
    itemMenu,
  },
  data() {
    return {
      open: false,
      notCloseElements: [],
      searchQuery: "",
    };
  },
  mounted() {
    this.notCloseElements = [this.dropdown.$el];
  },
  methods: {
    onSelect(item) {
      if (!this.multiSelect) {
        this.open = false;
      }
      this.$emit("select", item);
    },
    onDeselect(item) {
      if (!this.multiSelect) {
        this.open = false;
      }
      this.$emit("deselect", item);
    },
    onSearch(q) {
      this.searchQuery = q;
      this.$emit("search", this.searchQuery);
    },
    onOpenChange() {
      if (this.open) {
        this.onSearch("");
      }
      this.$emit("update:menu-open", this.open);
    },
    onMenuOpenChange() {
      if (this.menuOpen === true || this.menuOpen === false) {
        this.open = this.menuOpen;
      }
    },
  },
  props: {
    multiSelect: { default: false, type: Boolean },
    menuOpen: {
      type: Boolean,
    },
    appendDropdownToBody: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dropdown: {
      cache: false,
      get() {
        return this.$refs["dropdown"] as Dropdown;
      },
    },
  },
  watch: {
    open: [
      {
        handler: "onOpenChange",
      },
    ],
    menuOpen: [
      {
        handler: "onMenuOpenChange",
      },
    ],
  },
});
</script>

<style lang="scss" scoped>
.item-menu-dropdown {
  width: 300px;
}
</style>
