<template>
  <uiv-popover
    viewport="body"
    :title="$t('actions.are_you_sure')"
    :disabled="disabled"
    :enable="!disabled"
    :tag="tag"
    v-model="show"
  >
    <template slot="default">
      <slot>
        <uiv-btn :type="variant">{{ buttonTitle }}</uiv-btn>
      </slot>
    </template>
    <template slot="popover">
      <p v-if="popoverMessage">{{ popoverMessage }}</p>
      <div class="flex-row justify-content-space-between gap-md">
        <div class="flex-1">
          <uiv-btn type="success" block @click.stop="confirm()">{{ $t("actions.confirm") }}</uiv-btn>
        </div>
        <div class="flex-1">
          <uiv-btn block @click.stop="cancel()">{{ $t("actions.cancel") }}</uiv-btn>
        </div>
      </div>
    </template>
  </uiv-popover>
</template>

<script>
export default {
  props: {
    id: String,
    buttonTitle: String,
    popoverMessage: String,
    variant: {
      type: String,
      default: "danger",
    },
    tag: {
      type: String,
      default: "span",
    },
    disabled: Boolean,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.show = false;
    },
    cancel() {
      this.$emit("cancel");
      this.show = false;
    },
  },
};
</script>
