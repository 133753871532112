<template>
  <modal v-model="open" :title="title">
    <h2>{{ $t("chart_settings.color_scheme") }}</h2>
    <label class="row">
      <div class="col-md-6">
        <input type="radio" name="color_scheme" value="default" v-model="colorScheme" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.default") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--default"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input type="radio" name="color_scheme" value="traffic_lights" v-model="colorScheme" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.traffic_lights") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--traffic-lights"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input type="radio" name="color_scheme" value="reverse_traffic_lights" v-model="colorScheme" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.reverse_traffic_lights") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--reverse-traffic-lights"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input type="radio" name="color_scheme" value="violet" v-model="colorScheme" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.violet") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--violet"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input type="radio" name="color_scheme" value="neutral" v-model="colorScheme" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.neutral") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--neutral"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input type="radio" name="color_scheme" value="none" v-model="colorScheme" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.none") }}</div>
      </div>
    </label>
    <div slot="footer">
      <button class="btn btn-default" @click="open = false">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" @click="update">{{ $t("actions.save") }}</button>
    </div>
  </modal>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import { Modal } from "uiv";
import i18n from "../i18n";
export default defineComponent({
  props: {
    component: Object,
    filterConfiguration: Object,
  },
  components: { Modal },
    data() {
        return {
            open: false,
            colorScheme: "default"
        };
    },
    computed: {
        title() {
            return i18n.t("chart_settings.title");
        }
    },
    created() {
        this.onComponentUpdated();
    },
    methods: {
        show() {
            this.open = true;
        },
        update() {
            this.open = false;
            this.$emit("update", {
              ...this.component.chartOptions,
              color_scheme: this.colorScheme,
            });
        },
        onComponentUpdated() {
            const { chartOptions = {} } = this.component;

                this.colorScheme = chartOptions.color_scheme || "default";
        }
    },
    watch: {
        "component": [{
            handler: "onComponentUpdated"
        }]
    }
})

</script>

<style lang="scss" scoped>
label {
  display: block;
}
.scheme-label {
  display: inline;
  margin-left: 5px;
  font-weight: normal;
}
.gradient {
  height: 20px;
  background-color: grey;

  &--default {
    background: linear-gradient(to right, #ffffff, #4bcdf0, #ffbe0f, #fa644a);
  }
  &--traffic-lights {
    background: linear-gradient(to right, green, yellow, red);
  }

  &--reverse-traffic-lights {
    background: linear-gradient(to right, red, yellow, green);
  }

  &--violet {
    background: linear-gradient(to right, #ffffff, #5555ff);
  }

  &--neutral {
    background: linear-gradient(to right, #f2f2f2, #67c9e9);
  }
}
</style>
