<template>
  <div class="item-menu-container">
    <div class="title">{{ title }}</div>
    <div class="search-control flex-row align-items-center" v-if="showSearch">
      <div class="search-icon fg-text-variant">
        <icon class="text-5" icon="fluent:search-20-regular"></icon>
      </div>
      <div class="flex-1">
        <input
          class="form-control"
          type="text"
          :placeholder="$t('actions.search_for')"
          :value="search"
          @input="onSearch($event.target.value)"
        />
      </div>
    </div>
    <div class="item-menu" v-if="showItemMenu" :class="{ 'with-search': showSearch }">
      <div
        class="item"
        v-if="isWildcard"
        :class="{ active: isItemSelected(wildcardItem), exclude }"
        @click.stop="onClickItem(wildcardItem)"
      >
        {{ wildcardLabel }}
      </div>
      <template v-if="!isWildcard" v-for="(groupItems, category) in groupedItems">
        <div class="category-header" v-if="category && category !== 'undefined' && groupItems.length > 0">
          {{ category }}
        </div>
        <div class="items">
          <div
            class="item"
            :class="{ active: isItemSelected(item), exclude }"
            @click.stop="onClickItem(item)"
            v-for="item in enabledItems(groupItems)"
          >
            {{ item.name }}
            <div class="pull-right">
              <confirm-button
                class="delete-button"
                v-if="deleteable && item.deletable !== false"
                @confirm="$emit('delete', item)"
                ><i class="delete fa fa-trash-o" v-on:click.stop="1 == 1"></i
              ></confirm-button>
            </div>
          </div>
        </div>
        <div class="disabled-items">
          <div class="item flex-row" v-for="item in disabledItems(groupItems)">
            <div class="disabled">{{ item.name }}</div>
            <pro-text class="ml-sm"></pro-text>
          </div>
        </div>
      </template>
    </div>
    <div class="simple-spinner" v-if="isLoading && !showItemMenu">
      <div class="spinner"></div>
    </div>
    <div class="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import Vue, { defineComponent, PropType } from "vue";
import confirmButton from "./confirm-button.vue";
import i18n from "../i18n";
import PillItem from "@/interfaces/pill-item";
import ProText from "@/components/pro-text.vue";
import { Icon } from "@iconify/vue2";

export default defineComponent({
  components: {
    confirmButton,
    ProText,
    Icon,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredItems() {
      if (this.typeahead) {
        return this.items;
      }

      // Filter by search
      const search = this.search.trim().toLowerCase();

      return search.length > 0
        ? _.filter(this.items, (item) => item.name.toLowerCase().indexOf(search) >= 0)
        : this.items;
    },
    groupedItems() {
      return _.groupBy(this.filteredItems, "category");
    },
    showSearch() {
      return this.typeahead || (this.searchable && this.items);
    },
    showItemMenu() {
      return this.isWildcard || (this.filteredItems && this.filteredItems.length > 0);
    },
    isWildcard() {
      return (this.wildcard && this.search && this.search.includes("%")) || this.search.includes("_");
    },
    wildcardItem(): MenuItem {
      return { key: this.search, name: this.search, wildcard: true };
    },
    wildcardLabel(): string {
      return i18n.t("filters.all_matching", { query: this.search });
    },
  },
  methods: {
    isItemSelected(item: MenuItem) {
      return (
        this.selectedItems &&
        this.selectedItems.some(
          (pillItem) => pillItem.value === menuItemKey(item) && !!pillItem.wildcard === !!item.wildcard
        )
      );
    },
    enabledItems(items: MenuItem[]) {
      return _.filter(items, (item) => !this.isDisabled(item));
    },
    disabledItems(items: MenuItem[]) {
      return _.filter(items, (item) => this.isDisabled(item));
    },
    isDisabled(item: MenuItem) {
      return item.enabled_for_limited === false;
    },
    onClickItem(item: MenuItem) {
      if (this.isItemSelected(item)) {
        this.$emit("deselect", item);
      } else {
        this.$emit("select", item);
      }
    },
    onSearch(q = "") {
      this.search = q;
      if (!this.isWildcard) {
        this.$emit("search", q);
      }
    },
    onSearchQuery(q) {
      if (this.search !== q) {
        this.onSearch(q);
      }
    },
  },
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array as PropType<MenuItem[]>,
    },
    selectedItems: {
      type: Array as PropType<PillItem[]>,
    },
    typeahead: { default: false, type: Boolean },
    searchable: { default: true, type: Boolean },
    deleteable: { default: false, type: Boolean },
    menuOpen: {
      type: Boolean,
    },
    searchQuery: { default: "", type: String },
    wildcard: { default: false, type: Boolean },
    exclude: { default: false, type: Boolean },
    isLoading: { default: false, type: Boolean },
  },
  watch: {
    searchQuery: [
      {
        handler: "onSearchQuery",
      },
    ],
  },
});
</script>

<style lang="scss" scoped>
.item-menu-container {
  color: var(--color-text-primary);
}

.title {
  padding: 5px 15px;
}

.search-control {
  input,
  input:focus,
  input:active {
    border: none;
    color: var(--color-text-primary);
    background-color: var(--color-surface-neutral-1);
    box-shadow: none;
    font-size: 12px;
    &::placeholder {
      color: var(--color-text-variant);
      font-size: 12px;
    }
  }
  .search-icon {
    line-height: 1;
    margin-top: 3px;
    margin-left: 15px;
  }
}
.item-menu {
  margin-top: 4px;
  border-top: 1px solid var(--color-border-secondary);
  min-width: 300px;
  max-height: 376px;
  overflow-y: auto;
  padding-top: 4px;

  .category-header {
    text-transform: uppercase;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: #999999;
  }
}

.item {
  padding: 7px 15px;
  border-radius: 5px;

  &.active,
  &:hover {
    color: var(--color-text-emphasis);

    &.exclude {
      color: var(--color-semantic-negative-text);
    }
  }

  &:hover {
    cursor: pointer;

    .delete-button {
      display: block;
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .delete-button {
    display: none;

    &:hover * {
      font-weight: bold;
    }
  }
}

.wildcard-item {
  display: inline-block;
  padding: 7px 10px;
  margin: 4px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: #4bcdf0;
  }
}

.footer {
  margin-top: 7px;
}
</style>
