<template>
  <div
    class="filterset-selector flex-row align-items-center gap-sm intercom-tag-filters-saved-filters"
    v-if="availableItems"
  >
    <pill-list
      :title="$t('filters.filterset.saved_filter')"
      :items="items"
      :available-items="availableItems"
      :searchable="availableItems.length > 10"
      :radio="true"
      :deleteable="true"
      @update="onUpdateItems($event.items)"
      @delete="onDelete($event)"
    ></pill-list>
    <button class="btn btn-sm btn-primary" :disabled="isSetActive || !hasEnabledFilters" @click="openSaveModal">
      {{ $t("actions.save") }}
    </button>
    <modal
      v-model="saveModalOpen"
      :append-to-body="true"
      :title="$t('filters.actions.save_as')"
      :cancel-text="$t(requireConfirmation ? 'actions.edit' : 'actions.cancel')"
      :cancel-type="requireConfirmation ? 'primary' : 'default'"
      :ok-text="$t(requireConfirmation ? 'actions.confirm' : 'actions.save')"
      :ok-type="requireConfirmation ? 'danger' : 'primary'"
      :before-close="beforeClose"
      @hide="onHide"
    >
      <form>
        <spinner v-if="saving"></spinner>
        <div class="form" :class="{ disabled: saving }">
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              v-model="filterset.name"
              required
              :placeholder="$t('filters.filterset.name_placeholder')"
              autofocus
              style="max-width:100%;"
            />
          </div>
          <div class="form-group" v-if="isAdmin">
            <div class="checkbox">
              <label for="shared">
                <input type="checkbox" name="shared" v-model="filterset.shared" />{{ $t("filters.filterset.shared") }}
              </label>
            </div>
          </div>
        </div>
        <div v-if="requireConfirmation">{{ $t("filters.filterset.override_filter_name") }}</div>
        <div v-else-if="saveAsOwn">{{ $t("filters.filterset.cannot_save_shared") }}</div>
      </form>
    </modal>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import pillList from "./pill-list.vue";
import { Modal } from "uiv";
import spinner from "../components/spinner.vue";
import _ from "lodash";
import FiltersConfiguration from "../model/filters-configuration";
import FiltersetItem from "../model/filterset-item";
import Actions from "../store/actions";

export default defineComponent({
  components: {
    pillList,
    Modal,
    spinner,
  },
  data() {
    const lastSetId: number = null;
    const filterset: { name: string; shared: boolean } = {
      name: "",
      shared: false,
    };

    return {
      saveModalOpen: false,
      saving: false,
      requireConfirmation: false,
      confirmed: false,
      filterset,
      lastSetId,
      isAdmin: !!window.zoinedContext?.isAdmin,
      saveAsOwn: false,
    };
  },
  computed: {
    effectiveConfig(): FiltersConfiguration {
      if (this.config) {
        const sets = (this.config.sets || []).filter(({ id }) => _.find(this.filtersets, (set) => set.id == id));
        return {
          ...this.config,
          sets,
        };
      } else {
        return null;
      }
    },
    items() {
      return (
        this.effectiveConfig.sets &&
        this.effectiveConfig.sets.map(({ id, enabled }) => {
          const set = _.find(this.filtersets, (set) => set.id == id);
          return {
            value: id,
            enabled,
            name: set.name,
          };
        })
      );
    },
    availableItems() {
      return (
        this.filtersets &&
        this.filtersets.map(({ id, name, shared }) => ({
          key: id,
          name,
          deletable: !shared || this.isAdmin,
          category: shared ? "shared" : "own",
        }))
      );
    },
    enabledSetId() {
      return _.find(this.effectiveConfig.sets, ({ enabled }) => enabled)?.id;
    },
    enabledSet() {
      return this.enabledSetId && _.find(this.filtersets, (set) => set.id == this.enabledSetId);
    },
    lastSet() {
      return this.lastSetId && _.find(this.filtersets, (set) => set.id == this.lastSetId);
    },
    isSetActive() {
      return !!this.enabledSetId;
    },
    hasEnabledFilters() {
      return !_.isEmpty(this.effectiveConfig.filters);
    },
    filtersets() {
      return this.$store.state.filtersets.all;
    },
  },
  created() {
    if (!this.filtersets) {
      this.$store.dispatch(Actions.fetchFiltersets);
    }
  },
  methods: {
    openSaveModal() {
      this.saveModalOpen = true;
      this.filterset = {
        name: this.lastSet?.name || "",
        shared: !!this.lastSet?.shared,
      };
      if (!this.isAdmin) {
        this.filterset.shared = false;
      }
    },
    onUpdateItems(items) {
      const sets: FiltersetItem[] = items.map(({ value, enabled }) => ({ id: value, enabled }));
      this.onFiltersetsUpdated(sets);
    },
    onFiltersetsUpdated(sets: FiltersetItem[]) {
      const enabledSetId = sets.find(({ enabled }) => enabled)?.id;
      const enabledSet = enabledSetId && _.find(this.filtersets, (set) => set.id == enabledSetId);
      const filters = enabledSet ? _.cloneDeep(enabledSet.config.filters) : this.config.filters;
      const config: FiltersConfiguration = {
        v: 2,
        sets,
        filters,
      };
      this.$emit("update", config);
    },
    onHide() {
      this.saving = this.requireConfirmation = this.saveAsOwn = this.confirmed = false;
    },
    onDelete(id) {
      this.$store.dispatch("filtersets/delete", id);
    },
    beforeClose(msg) {
      if (msg == "ok") {
        if (this.requireConfirmation) {
          this.confirmed = true;
        }
        this.save();
        return false;
      } else if (msg == "cancel" && this.requireConfirmation) {
        this.requireConfirmation = false;
        return false;
      } else {
        return true;
      }
    },
    save() {
      if (this.saving || _.isEmpty(this.filterset.name)) {
        return;
      }

      const config = {
        v: 2,
        filters: this.config.filters,
      };

      if (this.lastSet?.name === this.filterset.name) {
        if (this.lastSet.shared && !this.isAdmin) {
          this.saveAsOwn = true;
          this.lastSetId = null;
        } else {
          return this.updateFilterset(this.lastSet, config);
        }
      } else {
        const existingSet = _.find(this.filtersets, (set) => {
          return set.name == this.filterset.name && (this.isAdmin || !set.shared);
        });

        if (existingSet) {
          if (this.confirmed) {
            return this.updateFilterset(existingSet, config);
          } else {
            this.requireConfirmation = true;
          }
        } else {
          return this.createFilterset(config);
        }
      }
    },
    createFilterset(config) {
      this.saving = true;

      const filterset = {
        ...this.filterset,
        config,
      };

      this.$store.dispatch("filtersets/add", filterset).then((filterset) => {
        this.saveModalOpen = false;

        // Add set to config
        const sets = [...this.config.sets, { id: filterset.id, enabled: true }];
        const config = {
          ...this.config,
          sets,
        };

        this.$emit("update", config);
      });
    },
    updateFilterset(existingSet, config) {
      this.saving = true;

      const filterset = {
        ...existingSet,
        ...this.filterset,
        config,
      };

      this.$store.dispatch("filtersets/update", [filterset.id, filterset]).then((filterset) => {
        this.saveModalOpen = false;

        const sets = this.config.sets.map(({ id }) => ({
          id,
          enabled: id === filterset.id,
        }));

        if (!sets.find(({ id }) => id == filterset.id)) {
          sets.push({ id: filterset.id, enabled: true });
        }

        const config = {
          ...this.config,
          sets,
        };
        this.$emit("update", config);
      });
    },
    watchFilters(filters, old) {
      if (!_.isEqual(filters, old)) {
        // Set up enabled flag for filtersets based on current filters state
        const sets = _.map(this.effectiveConfig.sets, ({ id, enabled }) => {
          if (enabled) {
            const set = _.find(this.filtersets, (set) => set.id == id);
            if (!_.isEqual(filters, set.config.filters)) {
              enabled = false;
            }
          }
          return { id, enabled };
        });

        if (!_.isEqual(this.config.sets, sets)) {
          const config = {
            ...this.config,
            sets,
          };
          this.$emit("update", config);
        }
      }
    },
    watchSets(sets) {
      const enabledSetId = sets?.find(({ enabled }) => enabled)?.id;
      if (enabledSetId) {
        this.lastSetId = enabledSetId;
      }
    },
    watchFiltersets() {
      this.onFiltersetsUpdated(this.config.sets || []);
    },
  },
  props: {
    config: {
      default: (): FiltersConfiguration => ({
        v: 2,
        sets: [],
        filters: {},
      }),
      type: Object as PropType<FiltersConfiguration>,
    },
  },
  watch: {
    "config.filters": [
      {
        handler: "watchFilters",
      },
    ],
    "config.sets": [{ immediate: true, handler: "watchSets" }],
    filtersets: [
      {
        handler: "watchFiltersets",
      },
    ],
  },
});
</script>
