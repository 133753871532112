"use strict";

// Import all modules here so they will become available for the global Angular.
// When the application is converted to ES2015, we can just import needed
// modules there.

import "./product-fruits";
import "bootstrap-sass";
import "./mixpanel";

import "./polyfills";

import "./lib/url-util";
import "./i18n";
import "./moment-setup";

import "./rollbar";
import "./vue-setup";

import lodash from "lodash";

window._ = lodash;

import "./vue-app";

import "toastr/toastr.scss";

import Rails from "@rails/ujs";

Rails.start();
