import i18n from "@/i18n";

export const formatNumber = function(
  value,
  {
    style,
    precision,
    locale,
    currency,
  }: { style?: "currency" | "decimal"; precision?: number; locale?: string; currency?: string } = {}
) {
  style = style || "decimal";
  precision = precision || 0;
  locale = locale || i18n.locale;
  currency = currency || undefined;
  if (Math.abs(value) >= 1000000) {
    return new Intl.NumberFormat(locale, { style, currency, notation: "compact", maximumFractionDigits: 2 }).format(
      value
    );
  } else if (Math.abs(value) >= 10000) {
    return new Intl.NumberFormat(locale, { style, currency, maximumFractionDigits: 0 }).format(value);
  } else {
    return new Intl.NumberFormat(locale, { style, currency, maximumFractionDigits: precision }).format(value);
  }
};
