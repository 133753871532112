<template>
  <uiv-modal v-model="open" :title="$t('newsletter.definition.messages.select_section')">
    <div v-for="(section, index) in sections">
      <div class="section-thumbnail" @click="ok(section)">
        <div class="image">
          <div class="section-type-image" :class="section"></div>
        </div>
        <div class="caption">
          <h3>{{ $t("newsletter.definition.sections." + section + ".title") }}</h3>
          <p>{{ $t("newsletter.definition.sections." + section + ".select_desc") }}</p>
        </div>
      </div>
      <hr v-if="index < sections.length - 1" />
    </div>
    <div slot="footer">
      <button class="btn btn-default" @click="cancel">{{ $t("actions.cancel") }}</button>
    </div>
  </uiv-modal>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import SectionTypes from "./section-types";

export default defineComponent({
  data() {
    return {
      open: false,
    };
  },
  computed: {
    sections() {
      let sectionTypes = this.newsletterType == "alert" ? SectionTypes.alert() : SectionTypes.all();
      if (this.allowedSectionTypes) {
        sectionTypes = sectionTypes.filter((type) => this.allowedSectionTypes.includes(type));
      }
      return sectionTypes;
    },
  },
  methods: {
    show() {
      this.open = true;
    },
    ok(sectionId) {
      const section = SectionTypes.get(sectionId);
      this.$emit("add", section);
      this.open = false;
    },
    cancel() {
      this.open = false;
    },
  },
  props: {
    newsletterType: {
      type: String,
    },
    allowedSectionTypes: {
      type: Array as PropType<string[]>,
    },
  },
});
</script>
