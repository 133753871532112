<template>
  <a class="navbar-dropdown-button flex-row align-items-center">
    <div class="content">
      <slot name="content">
        <div class="flex-column">
          <div class="title">{{ title }}</div>
          <div class="subtitle">{{ subtitle }}</div>
        </div>
      </slot>
    </div>
    <div class="trailing">
      <slot name="icon">
        <svg-icon :name="icon"></svg-icon>
      </slot></div
  ></a>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import SvgIcon from "./svg-icon.vue";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    icon: { default: "chevron-down", type: String },
  },
});
</script>

<style lang="scss" scoped>
a.navbar-dropdown-button {
  margin-left: 20px;

  .title {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-black-90);
    white-space: nowrap;
  }
  .subtitle {
    font-size: 10px;
    color: var(--color-muted);
    white-space: nowrap;
  }
  .trailing {
    margin-left: 10px;
    background-color: var(--color-black-90);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    height: 35px;
    width: 35px;

    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }

  &:hover {
    filter: opacity(0.8);
  }
}
</style>
