import AiMessage from "@/ai-assistant/ai-message";
import AiSession from "@/ai-assistant/ai-session";
import { makeApiInstance } from "@/api/instance";
import { useInfiniteQuery, useMutation, UseMutationOptions, useQueryClient } from "@tanstack/vue-query";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import _ from "lodash";

type CreateAiMessageParams = {
  session_id: string;
  data: {
    message: string;
    context?: Record<string, any>;
    use?: Record<string, any>;
  };
};

type CreateAiMessageOptions = {
  onData?: (data: AiMessage) => void;
  onClose?: () => void;
};

type UseCreateAiMessageScoreArgs = {
  session_id: string;
  message_id: string;
  score: number;
  comment?: string;
};

const api = makeApiInstance({ baseURL: "/api/v1" });

const useAiSessions = () => {
  const perPage = 20;

  return useInfiniteQuery<AiSession[]>({
    queryKey: ["aiSessions"],
    queryFn: ({ pageParam = 1 }) =>
      api.get("/ai_sessions", { params: { page: pageParam, per_page: perPage } }).then((res) => res.data),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage) {
        return 1;
      }
      if (lastPage.length < perPage) {
        return undefined;
      }
      return pages.length + 1;
    },
  });
};

const getAiMessages = async (session_id: string) => {
  return api.get("/ai_messages", { params: { session_id } }).then((res) => res.data);
};

const createAiSession = () => {
  return api.post("/ai_sessions").then((res) => res.data);
};

const createAiMessage = async (args: CreateAiMessageParams, opts: CreateAiMessageOptions = {}) => {
  const { onData = () => {}, onClose = () => {} } = opts;

  return fetchEventSource("/api/v1/ai_messages", {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${window.chart_token}` },
    body: JSON.stringify(args),
    openWhenHidden: true,
    onmessage: (event) => {
      const data = JSON.parse(event.data);
      onData(data);
    },
    onclose: () => onClose(),
  });
};

const useCreateAiMessageScore = (options: UseMutationOptions<void, Error, UseCreateAiMessageScoreArgs> = {}) => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, UseCreateAiMessageScoreArgs>({
    ...options,
    mutationFn: (args: UseCreateAiMessageScoreArgs) =>
      api
        .post(`/ai_messages/${args.message_id}/scores`, { score: args.score, comment: args.comment })
        .then((res) => res.data),
    onMutate: async (args) => {
      let messages = queryClient.getQueryData<AiMessage[]>(["aiMessages", args.session_id]);

      if (messages) {
        messages = messages.map((message) => {
          if (message.message_id === args.message_id) {
            return { ...message, score: args.score };
          }
          return message;
        });

        queryClient.setQueryData(["aiMessages", args.session_id], messages);
      }
    },
  });
};

export {
  useAiSessions,
  createAiSession,
  getAiMessages,
  createAiMessage,
  CreateAiMessageParams,
  UseCreateAiMessageScoreArgs,
  useCreateAiMessageScore,
};
