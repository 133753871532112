<template>
  <dropdown class="dropdown-select" ref="dropdown">
    <template v-slot:toggle="toggleProps">
      <slot name="toggle" v-bind:menuOpen="toggleProps.menuOpen" v-bind:toggleMenu="toggleProps.toggleDropdown">
        <dropdown-select-button
          :class="btnClasses"
          :button-style="buttonStyle"
          :menu-open="toggleProps.menuOpen"
          @click="toggleProps.toggleDropdown"
          >{{ label }}</dropdown-select-button
        >
      </slot>
    </template>
    <template slot="menu">
      <div class="simple-spinner" v-if="!availableItems">
        <div class="spinner"></div>
      </div>
      <div class="dropdown-items">
        <div class="dropdown-item flex-row" @click="addItem(item)" v-for="item in enabledItems">
          <div class="flex-row"><i class="fa mr-sm" v-if="item.icon" :class="item.icon"></i>{{ item.name }}</div>
        </div>
        <div class="dropdown-item flex-row" v-for="item in disabledItems">
          <div class="flex-row disabled">
            <i class="fa mr-sm" v-if="item.icon" :class="item.icon"></i>{{ item.name }}
          </div>
          <pro-text class="ml-sm"></pro-text>
        </div>
      </div>
    </template>
  </dropdown>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import Dropdown from "./dropdown.vue";
import DropdownSelectButton from "./dropdown-select-button.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";
import proText from "./pro-text.vue";

export default defineComponent({
  components: {
    Dropdown,
    DropdownSelectButton,
    proText,
  },
  data() {
    return {
      isLimited: window.zoinedContext.limited,
    };
  },
  computed: {
    btnClasses() {
      return {
        default: this.default && !this.value,
      };
    },
    label() {
      if (this.value !== null && this.value !== undefined) {
        return this.getLabel(this.value);
      } else if (this.default) {
        return this.getLabel(this.default);
      } else if (this.selectLabel) {
        return this.selectLabel;
      } else {
        return "Select";
      }
    },
    enabledItems() {
      return _.filter(this.availableItems, (item) => !this.isDisabled(item));
    },
    disabledItems() {
      return _.filter(this.availableItems, (item) => this.isDisabled(item));
    },
    dropdown: {
      cache: false,
      get() {
        return this.$refs["dropdown"] as Dropdown;
      },
    },
  },
  methods: {
    addItem(item: MenuItem) {
      this.$emit("update", menuItemKey(item));
      this.dropdown.toggle();
    },
    getLabel(key) {
      const item = _.find(this.availableItems, (item) => menuItemKey(item) === key);
      if (item) {
        return item.name;
      } else {
        return key;
      }
    },
    isDisabled(item: MenuItem) {
      return this.isLimited && item.enabled_for_limited === false;
    },
  },
  props: {
    availableItems: {
      type: Array as PropType<MenuItem[]>,
    },
    default: {
      type: Object as PropType<any>,
    },
    value: {
      type: String,
    },
    selectLabel: {
      type: String,
    },
    buttonStyle: { default: "primary-inverted", type: String },
  },
});
</script>

<style lang="scss" scoped>
.dropdown-select {
  .btn.default {
    opacity: 0.5;
  }
  .dropdown-items {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    .dropdown-item {
      padding: 7px 10px;
      border-radius: 5px;
      color: #333;
      white-space: nowrap;
      margin: 4px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-top: 0;
      }

      &:hover {
        background-color: #cceeff;
        cursor: pointer;
      }

      .disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
