<template>
  <div class="btn-group btn-multiple">
    <button
      class="btn btn-primary btn-xs intercom-tag-table-change-percent-button"
      id="change-percent-button"
      :class="{ active: config.changePercent }"
      v-uiv-tooltip.hover="changePercentTooltip"
      @click="toggleValue('changePercent')"
    >
      <span>%±</span>
    </button>
    <button
      class="btn btn-primary btn-xs intercom-tag-table-change-value-button"
      id="absolute-change-button"
      :class="{ active: config.absoluteChange }"
      v-uiv-tooltip.hover="changeAbsoluteTooltip"
      @click="toggleValue('absoluteChange')"
    >
      <span>±</span>
    </button>
    <button
      class="btn btn-primary btn-xs intercom-tag-table-change-index-button"
      id="change-index-button"
      :class="{ active: config.changeIndex }"
      v-uiv-tooltip.hover="changeIndexTooltip"
      @click="toggleValue('changeIndex')"
    >
      Ind
    </button>
    <button
      class="btn btn-primary btn-xs intercom-tag-table-proportions-button"
      id="proportions-button"
      :class="{ active: config.proportions }"
      v-uiv-tooltip.hover="proportionTooltip"
      @click="toggleValue('proportions')"
    >
      <i class="fa fa-sm fa-percent"></i>
    </button>
    <button
      v-if="columnProportionsEnabled"
      class="btn btn-primary btn-xs intercom-tag-column-proportions-button"
      id="proportions-button"
      :class="{ active: config.columnProportions }"
      v-uiv-tooltip.hover="columnProportionTooltip"
      @click="toggleValue('columnProportions')"
    >
      Col <i class="fa fa-sm fa-percent"></i>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import Component from "@/model/component";
import { computed } from "vue";

export interface TableColumnSelectorConfig {
  changePercent: boolean;
  absoluteChange: boolean;
  changeIndex: boolean;
  proportions: boolean;
  columnProportions: boolean;
}

const props = defineProps<{ config: TableColumnSelectorConfig; component: Component }>();

const emit = defineEmits(["change"]);

const { t } = useI18n();

const changePercentTooltip = t("report.snippet.table.change_percent");

const changeAbsoluteTooltip = t("report.snippet.table.change_absolute");

const changeIndexTooltip = t("report.snippet.table.change_index");

const proportionTooltip = t("report.snippet.table.proportion");

const columnProportionTooltip = t("report.snippet.table.column_proportion");

const columnProportionsEnabled = computed(() => props.component.name === "cross_tab_custom");

const toggleValue = (key) => {
  emit("change", {
    ...props.config,
    [key]: !props.config[key],
  });
};
</script>

<style lang="scss" scoped>
.table-column-selector {
  display: inline-block;
}
</style>
