<template>
  <div class="dashboard-metric">
    <div class="row selection-row" :class="`span-${component.span}`">
      <div
        class="col-xs-6 values"
        :class="{ 'col-sm-12': component.span <= 4, 'col-md-7': component.span == 4, 'col-md-6': component.span > 4 }"
      >
        <div class="value" :class="`span-${component.span}`">
          <div
            class="symbol hidden-xs"
            :class="{
              'hidden-sm': component.span > 4,
              'hidden-md': component.span >= 4,
              'hidden-lg': component.span >= 4,
              [selectionClass]: true,
            }"
          ></div>
          {{ formatDashboxCurrency(value, precision || 0, currency, current_locale()) }}
        </div>
      </div>
      <div
        class="col-xs-6"
        :class="{
          ['hidden-sm']: component.span <= 4,
          ['hidden-md']: component.span < 4,
          ['hidden-lg']: component.span < 4,
          'col-md-5': component.span == 4,
          'col-md-6': component.span > 4,
        }"
      >
        <div class="dashbox-scale">
          <div class="graph-min">{{ formatDashboxCurrency(0, 0, "", current_locale()) }}</div>
          <div class="graph-max">
            {{ formatDashboxCurrency(parseFloat(max_value), precision || 0, "", current_locale()) }}
          </div>
          <div class="graph-frame"></div>
        </div>
        <div class="g selection">
          <div class="b" v-bind:style="{ width: countValMin }" :class="selectionClass"></div>
        </div>
      </div>
    </div>
    <div class="row comparison-row" :class="`span-${component.span}`" v-for="comparison in comparisons">
      <div
        class="col-xs-3 small-values"
        :class="{
          'col-sm-12': component.span < 4,
          'col-sm-6': component.span == 4,
          'col-sm-3': component.span > 4,
          'col-md-6': component.span < 4,
          'col-md-3': component.span >= 4,
        }"
      >
        <div
          class="symbol hidden-xs"
          :class="{
            'hidden-sm': component.span > 4,
            'hidden-md': component.span >= 4,
            'hidden-lg': component.span >= 4,
            [classType(comparison.type, comparison.label)]: true,
          }"
        ></div>
        <div class="rel" :class="[comparison.change_class, `span-${component.span}`]">
          {{ formatDashboxPercentageChange(comparison.change_percentage) }}
        </div>
      </div>
      <div
        class="col-xs-3 small-values"
        :class="{
          'hidden-sm': component.span < 4,
          'col-sm-6': component.span == 4,
          'col-md-6': component.span < 4,
          'col-md-4': component.span == 4,
          'col-md-3': component.span > 4,
        }"
      >
        <div class="abs" v-if="comparison.change" :class="[comparison.change_class, `span-${component.span}`]">
          {{ formatDashboxCurrencyChange(comparison.change, precision || 0, currency, current_locale()) }}
        </div>
      </div>
      <div
        class="col-xs-6"
        :class="{
          'col-md-5': component.span == 4,
          'hidden-sm': component.span <= 4,
          'hidden-md': component.span < 4,
          'hidden-lg': component.span < 4,
        }"
      >
        <div class="g comparison">
          <div
            class="b"
            v-bind:class="classType(comparison.type, comparison.label)"
            v-bind:style="{ width: countComp(comparison.value), filter: comparisonFilter(comparison) }"
          >
            <div class="small-value">
              {{ formatDashboxCurrency(comparison.value, precision || 0, "", current_locale()) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatDashboxCurrency,
  formatDashboxPercentageChange,
  formatDashboxCurrencyChange,
} from "../../lib/format-helper";

export default {
  props: {
    component: {
      type: Object,
      required: true,
    },
    dashData: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    },
    max_value: {
      type: String,
    },
    comparisons: {
      type: Array,
    },
    precision: {
      type: Number,
    },
    currency: {
      type: String,
    },
    seriesType: String,
  },
  data() {
    return {
      loading: false,
      locale: "",
    };
  },
  methods: {
    formatDashboxCurrency: formatDashboxCurrency,
    formatDashboxPercentageChange: formatDashboxPercentageChange,
    formatDashboxCurrencyChange: formatDashboxCurrencyChange,
    classType: function(type, label) {
      if (type && label) {
        return `bg-${type}-${label} `;
      } else if (type || label) {
        return `bg-${type ? type : label}`;
      } else {
        return "bg";
      }
    },
    countComp: function(val) {
      return parseFloat((val / this.max_value) * 100) + "%";
    },
    comparisonFilter(comparison) {
      if (comparison.scaling_modifier) {
        const percent = Math.min(Math.max((Math.round(comparison.scaling_modifier * 100) - 100) / 2 + 100, 90), 110);
        return `brightness(${percent}%)`;
      }
    },
  },
  computed: {
    countValMin: function() {
      return parseFloat((this.value / this.max_value) * 100) + "%";
    },
    selectionClass() {
      const type = !this.seriesType || this.seriesType == "actual" ? "selection" : `budget-${this.seriesType}`;
      return `bg-${type}`;
    },
  },
};
</script>

<style lang="scss">
.dashboard-metric {
  > .row {
    display: flex;

    &.selection-row {
      min-height: 70px;

      .value {
        margin-top: 23px;
        display: inline-flex;
        align-items: center;
      }

      .symbol {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 2px;
        margin-right: 10px;
      }

      &.span-3 {
        @media (min-width: 768px) {
          min-height: 0;

          .value {
            margin-top: 0;
          }
        }
      }

      &.span-4 {
        @media (min-width: 768px) and (max-width: 991px) {
          min-height: 0;

          .value {
            margin-top: 0;
          }
        }
      }
    }

    &.comparison-row {
      min-height: 45px;

      &.span-3 {
        @media (min-width: 768px) {
          min-height: 0;
        }
      }

      &.span-4 {
        @media (min-width: 768px) and (max-width: 991px) {
          min-height: 0;
        }
      }
    }

    .small-values {
      margin-top: 6px;

      @media (min-width: 992px) {
        margin-top: 0;
      }
    }

    .small-value {
      margin-top: 0;
      padding-left: 10px;
      font-size: 12px;
      font-weight: 700;
      white-space: nowrap;
    }

    .g.selection {
      width: 100%;
    }

    .g.comparison {
      padding-top: 0;
      width: 100%;
    }

    > * {
      float: none;
    }

    .col-sm-6:last-child {
      padding-left: 0;
    }
  }
}
</style>
