<template>
  <div class="default-parameter-selector flex-row align-items-center flex-wrap gap-md column-gap-xxl">
    <filter-selector
      :config="value[filter.id]"
      @update="updateDefaultParameter(filter.id, $event)"
      :excludable="false"
      :filter="filter"
      v-for="filter in availableFilters"
      :key="filter.id"
    ></filter-selector>
    <div class="help-block" v-if="showDefaultsWarning">
      <i class="fa fa-exclamation-triangle text-danger"></i>{{ $t("profile.warn_defaults_lack_rights") }}
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { hasAccessToDimension } from "@/permissions/utils";
import Vue, { defineComponent, PropType } from "vue";
import FilterSelector from "@/components/filter-selector.vue";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import Permissions from "@/model/permissions";

export default defineComponent({
  components: {
    FilterSelector,
  },
  computed: {
    availableFilters() {
      return this.$store.getters.getParameters("filters");
    },
    defaultParametersStoreList() {
      const storeConfig = (this.value || {})["store"];
      return _.values(storeConfig).map(({ name, value }) => value || name);
    },
    showDefaultsWarning() {
      return !_.every(this.defaultParametersStoreList, (storeId) =>
        hasAccessToDimension(this.permissions, "store", storeId)
      );
    },
  },
  methods: {
    updateDefaultParameter(id, config) {
      const value = { ...this.value, [id]: config };
      this.$emit("input", value);
    },
  },
  props: {
    value: {
      type: Object as PropType<Record<string, FilterConfigurationItemMap>>,
    },
    permissions: {
      type: Object as PropType<Permissions>,
    },
  },
});
</script>
