<template>
  <form
    v-if="askComment"
    class="ask-comment-form bg-surface-neutral-1 rounded px-sm flex-row align-items-center"
    @submit.prevent="onSubmit"
  >
    <input
      ref="commentInput"
      v-model="comment"
      class="flex-1"
      autofocus
      type="text"
      :placeholder="t('ai_assistant.comment_placeholder')"
    />
    <IconButton icon="fluent:arrow-right-16-filled" size="small" type="submit" />
  </form>
  <div v-else class="feedback-buttons bg-surface-neutral-1 rounded px-sm">
    <div v-if="!hasScore" class="flex-row align-items-center gam-sm">
      <FeedbackButton :like="true" @click="onLike" />
      <FeedbackButton :like="false" @click="onDislike" />
    </div>
    <FeedbackButton v-else :like="score === 1" :active="true" />
  </div>
</template>

<script setup lang="ts">
import FeedbackButton from "@/ai-assistant/feedback-button.vue";
import IconButton from "@/components/icon-button.vue";
import { useI18n } from "@/composables/i18n";
import _ from "lodash";
import { computed, ref } from "vue";

const { t } = useI18n();

const props = defineProps<{ score?: number }>();

const emit = defineEmits(["update"]);

const commentInput = ref<HTMLInputElement | null>(null);

const askComment = ref(false);

const comment = ref("");

const hasScore = computed(() => _.isNumber(props.score));

const onLike = () => {
  emit("update", { score: 1 });
};

const onDislike = () => {
  askComment.value = true;
  setTimeout(() => {
    commentInput.value?.focus();
  });
};

const onSubmit = () => {
  emit("update", { score: 0, comment: comment.value });
  askComment.value = false;
  comment.value = "";
};
</script>

<style lang="scss" scoped>
.feedback-buttons {
  height: 20px;
}

.ask-comment-form {
  width: 100%;
  max-width: 400px;

  input {
    max-width: 100%;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent;
  }
}
</style>
