<template>
  <div class="flyover-filters-new w-full" v-if="reportContext">
    <div class="structure">
      <div class="container">
        <div class="row" v-if="errors.length > 0">
          <div class="col-md-12">
            <div class="alert alert-danger">{{ errors.join(", ") }}</div>
          </div>
        </div>
        <div class="row" v-if="warnings.length > 0">
          <div class="col-md-12">
            <div class="alert alert-warning">{{ warnings.join(", ") }}</div>
          </div>
        </div>
        <div class="row time-section">
          <div class="col-xs-10 col-md-9">
            <div class="pl-lg flex-row align-items-center flex-wrap gap-md column-gap-xxl">
              <timePeriodDropdown
                v-if="isEnabled('date')"
                :value="filterConfiguration.time.selection"
                :available-items="timePeriods"
                :available-series-types="seriesTypes"
                @update="updateTime('selection', $event)"
              ></timePeriodDropdown>
              <timePeriodDropdown
                v-if="isEnabled('sale_date')"
                :title="$t('filters.selection_sale_time')"
                :value="filterConfiguration.time.selection"
                :custom="false"
                :show-items="saleDateTimePeriods.length"
                :available-items="saleDateTimePeriods"
                @update="updateTime('selection', $event)"
              ></timePeriodDropdown>
              <noOfPastWeeksComparisonDropdown
                v-if="isEnabled('no_of_past_weeks')"
                :comparison="filterConfiguration.time.comparisons[0]"
                @update="updateTime('comparisons', [$event])"
              ></noOfPastWeeksComparisonDropdown>
              <comparisonPillList
                v-if="isEnabled('comparisons')"
                :pill-style="'button'"
                :radio="isRadio('comparisons')"
                :comparisons="filterConfiguration.time.comparisons"
                :choices="comparisonChoices"
                @update="updateTime('comparisons', $event)"
              ></comparisonPillList>
              <businessHoursSelector
                v-if="isEnabled('limit_to_business_hours')"
                :value="filterConfiguration.time.limit_to_business_hours"
                @update="updateTime('limit_to_business_hours', $event)"
              ></businessHoursSelector>
            </div>
          </div>
          <div class="col-xs-2 col-md-3 flex-column align-items-flex-end gap-md" v-if="allowExport">
            <a class="export-link export-link-pdf" @click="exportPdf()" href="#" :title="$t('filters.export_pdf')"
              ><span class="text mr-sm">{{ $t("filters.export_pdf") }}</span
              ><i class="fa fa-file-pdf-o fa-lg"></i></a
            ><a
              class="export-link export-link-xlsx"
              v-if="excelEnabled"
              @click="exportXlsx()"
              href="#"
              :title="$t('filters.export_xlsx')"
              ><span class="text mr-sm">{{ $t("filters.export_xlsx") }}</span
              ><i class="fa fa-file-excel-o fa-lg"></i
            ></a>
          </div>
        </div>
        <div class="row basket-filters-section" v-if="isEnabled('basket_filters')">
          <div class="col-md-12">
            <div class="card">
              <basket-filters-section
                v-model="filterConfiguration.basket_filters"
                :items="filterItems"
              ></basket-filters-section>
            </div>
          </div>
        </div>
        <div class="row widgets-section" v-if="widgetSectionEnabled">
          <div class="col-md-12">
            <div class="card">
              <widget-filters-section
                class="mb-lg"
                :filter-configuration="filterConfiguration"
                :report-config="reportConfig"
                @update="filterConfiguration = $event"
              ></widget-filters-section>
              <div
                class="flex-row align-items-center flex-wrap gap-md column-gap-xxl mb-md"
                v-if="isEnabled('minamount') || isEnabled('min_inventory_value')"
              >
                <minAmountSelector
                  v-if="isEnabled('minamount')"
                  :config="filterConfiguration.filters.filters.minamount"
                  @update="updateFilter('minamount', $event)"
                ></minAmountSelector>
                <minAmountSelector
                  v-if="isEnabled('min_inventory_value')"
                  :title="minInventoryValueTitle"
                  :config="filterConfiguration.filters.filters.min_inventory_value"
                  @update="updateFilter('min_inventory_value', $event)"
                ></minAmountSelector>
              </div>
            </div>
          </div>
        </div>
        <div class="row filters-section">
          <div class="col-md-12">
            <div class="card" v-if="isLoadingFilters">
              <spinner></spinner>
            </div>
            <div class="card" v-else>
              <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
                <filtersetSelector
                  :config="filterConfiguration.filters"
                  @update="updateFiltersConfig"
                ></filtersetSelector>
                <showFiltersetNameToggle
                  v-if="hasEnabledFilterSets && chartOptionsData"
                  :value="chartOptionsData.show_filterset_name"
                  @input="updateChartOptions('show_filterset_name', $event)"
                ></showFiltersetNameToggle>
              </div>
              <div class="flex-column gap-xl">
                <filter-category-section
                  v-for="(filters, category) in groupedFilterItems"
                  :key="category"
                  :category="category"
                  :filters="filters"
                  :filters-configuration="filterConfiguration.filters"
                  @update="updateFiltersConfig($event)"
                >
                </filter-category-section>
              </div>
              <div class="flex-row justify-content-flex-end gap-md mt-lg mb-md">
                <button
                  class="btn btn-primary-inverted btn-sm intercom-tag-filters-clear-filters-button"
                  :disabled="!hasEnabledFilters"
                  @click="clearFilters"
                >
                  {{ $t("filters.filterset.clear_filters") }}
                </button>
                <save-filters-button
                  class="intercom-tag-filters-save-filters-button"
                  :config="filterConfiguration.filters"
                  @update="updateFiltersConfig"
                ></save-filters-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="flex-row justify-content-flex-end mt-lg mb-lg">
              <a class="btn btn-primary intercom-tag-apply-filters-button" @click="refresh()">{{
                $t("actions.apply")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { filterToFlyover, validateFilters, VALIDATION_ERROR, VALIDATION_WARNING } from "../lib/filter-util";
import FilterConfiguration from "../model/filter-configuration";
import Vue, { defineComponent } from "vue";
import FiltersConfiguration from "../model/filters-configuration";
import ChartOptions from "../model/chart-options";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import TranslationService from "../core/translation.service";
import i18n from "../i18n";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import noOfPastWeeksComparisonDropdown from "../components/no-of-past-weeks-comparison-dropdown.vue";
import widgetFilterSelector from "../components/widget-filter-selector.vue";
import metricRangeSelector from "../components/metric-range-selector.vue";
import rangefilterUnionSelector from "../components/rangefilter-union-selector.vue";
import likeForLikeSelector from "../components/like-for-like-selector.vue";
import zerofillSelector from "../components/zerofill-selector.vue";
import filterSelector from "../components/filter-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import minAmountSelector from "../components/min-amount-selector.vue";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import Mutations from "../store/mutations";
import { exportDashboard, exportReport } from "../lib/export";
import TableConfig from "../model/table-config";
import Report from "../model/report";
import ReportContext from "../model/report-context";
import ReportConfig from "../model/report-config";
import WidgetFiltersSection, { isWidgetFilterSectionEnabled } from "./widget-filters-section.vue";
import BasketFiltersSection from "./basket-filters-section.vue";
import FilterCategorySection from "./filter-category-section.vue";
import SavedFilterSelector from "main/components/saved-filter-selector.vue";
import SaveFiltersButton from "main/components/save-filters-button.vue";
import spinner from "main/components/spinner.vue";

export default defineComponent({
  components: {
    timePeriodDropdown,
    comparisonPillList,
    businessHoursSelector,
    noOfPastWeeksComparisonDropdown,
    widgetFilterSelector,
    metricRangeSelector,
    rangefilterUnionSelector,
    likeForLikeSelector,
    zerofillSelector,
    filterSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    minAmountSelector,
    WidgetFiltersSection,
    BasketFiltersSection,
    FilterCategorySection,
    SavedFilterSelector,
    SaveFiltersButton,
    spinner,
  },
  data() {
    const chartOptionsData: ChartOptions = null;
    const filterConfiguration: FilterConfiguration = null;

    return {
      filterConfiguration,
      chartOptionsData,
      errors: [],
      warnings: [],
    };
  },
  computed: {
    reportContext(): ReportContext {
      return this.$store.getters.getReportContext;
    },
    config(): FilterConfiguration {
      return this.reportContext?.filter_configuration;
    },
    chartOptions(): ChartOptions {
      return this.reportContext?.chart_options;
    },
    tableConfig(): TableConfig {
      return this.reportContext?.table_config;
    },
    reportConfig(): ReportConfig {
      return (
        this.reportContext?.report?.config ||
        ({
          filters: this.reportContext.active_filters,
        } as ReportConfig)
      );
    },
    dependencies() {
      return [this.config, this.chartOptions];
    },
    zoinedContext() {
      return window.zoinedContext;
    },
    allowExport() {
      return this.zoinedContext.allowExport;
    },
    excelEnabled() {
      return this.$store.getters.getReportContext?.excel_export;
    },
    hasEnabledFilterSets() {
      const { sets } = this.filterConfiguration.filters || {};
      const enabledSets = sets?.filter(({ enabled }) => enabled);
      return enabledSets && enabledSets.length > 0;
    },
    activeFilters() {
      return this.$store.getters.getReportContext?.active_filters;
    },
    timePeriods(): MenuItem[] {
      return this.$store.getters.getParameters("timePeriods");
    },
    seriesTypes() {
      return window.zoinedContext.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
    },
    saleDateTimePeriods() {
      return [
        "whole_history",
        "last_7_days",
        "last_30_days",
        "last_90_days",
        "last_180_days",
        "last_365_days",
        "last_2_years",
      ].map((key) => this.selectionMenuItem(key));
    },
    comparisonChoices() {
      return this.$store.getters.getParameters("comparisons");
    },
    groupings(): MenuItem[] {
      return this.$store.getters.getParameters("grouping");
    },
    filterItems(): MenuItem[] {
      return this.$store.getters.getParameters("filters");
    },
    isLoadingFilters() {
      return !this.filterItems;
    },
    filterItemsWithCategory(): MenuItem[] {
      return (
        this.filterItems?.map((item) => {
          const key = menuItemKey(item);
          const grouping = this.groupings.find((grouping) => menuItemKey(grouping) === key);
          return {
            ...item,
            category: grouping?.category || i18n.t("chart.other"),
          };
        }) || []
      );
    },
    groupedFilterItems() {
      return _.groupBy(this.filterItemsWithCategory, (item) => item.category);
    },
    enabledFilterIds() {
      return this.filterItems?.map((item) => menuItemKey(item)) || [];
    },
    minInventoryValueTitle() {
      return i18n.t("filter.config.min", { metric: i18n.t("filter.config.inventory_value") });
    },
    report(): Report {
      return this.reportContext.report;
    },
    hasEnabledFilters() {
      return !_.isEmpty(this.filterConfiguration.filters.filters);
    },
    widgetSectionEnabled() {
      return (
        isWidgetFilterSectionEnabled({
          reportConfig: this.reportConfig,
          filterConfiguration: this.filterConfiguration,
        }) ||
        this.isEnabled("minamount") ||
        this.isEnabled("min_inventory_value")
      );
    },
  },
  methods: {
    refresh() {
      let config = {};

      Object.assign(config, this.filterConfiguration);

      const validationErrors = validateFilters(config);

      this.errors = validationErrors.filter(({ level }) => level === VALIDATION_ERROR).map(({ message }) => message);
      this.warnings = validationErrors
        .filter(({ level }) => level === VALIDATION_WARNING)
        .map(({ message }) => message);
      if (!_.isEmpty(this.errors)) {
        return;
      }

      this.$store.commit(Mutations.setFilterConfiguration, config);
      this.$store.commit(Mutations.setChartOptions, this.chartOptionsData);
      this.$store.commit(Mutations.closeFlyoverFilters);
    },
    isEnabled(filter) {
      return this.activeFilters?.includes(filter);
    },
    updateTime(key, config) {
      Vue.set(this.filterConfiguration.time, key, config);
    },
    updateWidget(widget, config) {
      Vue.set(this.filterConfiguration.widgets, widget, config);
    },
    updateBasketFilters(config) {
      Vue.set(this.filterConfiguration, "basket_filters", config);
    },
    updateFilter(filter: string, config: FilterConfigurationItemMap) {
      Vue.set(this.filterConfiguration.filters, "filters", {
        ...this.filterConfiguration.filters.filters,
        [filter]: config,
      });
    },
    updateRawFilters(key, config) {
      Vue.set(this.filterConfiguration, "raw_filters", {
        ...(this.filterConfiguration.raw_filters || {}),
        [key]: config,
      });
    },
    updateFiltersConfig(config: FiltersConfiguration) {
      this.filterConfiguration.filters = config;
    },
    updateChartOptions(key, value) {
      Vue.set(this.chartOptionsData, key, value);
    },
    clearFilters() {
      this.updateFiltersConfig({
        v: 2,
        sets: [],
        filters: {},
      });
    },
    selectionMenuItem(key): MenuItem {
      return {
        key,
        name: new TranslationService().selectionTitle({ type: key }),
      };
    },
    isRadio(widget) {
      const radioSelectors = this.$store.getters.getReportContext?.radio_selectors;

      return radioSelectors?.includes(widget);
    },
    exportPdf() {
      if (this.report) {
        exportReport(this.report.name, {
          filterConfiguration: this.config,
          chartOptions: this.chartOptions,
          tableConfig: this.tableConfig,
          format: "pdf",
        });
      } else {
        exportDashboard({
          filterConfiguration: this.config,
          format: "pdf",
        });
      }
    },
    exportXlsx() {
      exportReport(this.report.name, {
        filterConfiguration: this.config,
        chartOptions: this.chartOptions,
        tableConfig: this.tableConfig,
        format: "xlsx",
      });
    },
    updateModel(newVal, oldVal) {
      if (_.isEqual(newVal, oldVal)) {
        return;
      }
      const filterConfiguration: FilterConfiguration | any = filterToFlyover(this.config || {});
      Vue.set(this, "filterConfiguration", filterConfiguration);
      Vue.set(this, "chartOptionsData", _.cloneDeep(this.chartOptions));
    },
    removeOrphanedFilters() {
      if (!this.filterConfiguration.filters) {
        return;
      }
      if (this.enabledFilterIds.length > 0) {
        const filters = _.reduce(
          this.filterConfiguration.filters.filters,
          (result, filters, dimension) => {
            return Object.assign(
              result,
              // basket cross sell
              dimension == "minamount" ||
                // shelf warmers (min_inventory_value)
                dimension.startsWith("min_") ||
                // regular filters
                this.enabledFilterIds.includes(dimension)
                ? { [dimension]: filters }
                : {}
            );
          },
          {}
        );
        // Update filters if needed
        if (!_.isEqual(this.filterConfiguration.filters.filters, filters)) {
          this.filterConfiguration.filters.filters = filters;
          this.refresh();
        }
      }
    },
  },
  watch: {
    dependencies: [{ deep: true, immediate: true, handler: "updateModel" }],
    enabledFilterIds: [
      {
        handler: "removeOrphanedFilters",
      },
    ],
  },
});
</script>

<style lang="scss" scoped>
.flyover-filters-new {
  background-color: #2e2e2f;
  padding-top: 30px;

  .time-section::v-deep,
  .widgets-section::v-deep,
  .filters-section::v-deep {
    .title {
      font-size: 12px;
    }
  }
  .filters-section .card {
    min-height: 100px;
  }
}

.card {
  background-color: #353535;
  padding: 15px;
  padding-bottom: 5px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.export-link {
  text-shadow: none;
  font-size: inherit;
  font-weight: normal;
  color: darken(#fff, 15%);
  &:hover {
    color: darken(#fff, 30%);
    opacity: 1;
  }
  opacity: 1;

  span.text {
    display: none;
    font-size: 85%;
  }
  @media (min-width: 768px) {
    span.text {
      display: inline-block;
    }
  }
}
</style>
