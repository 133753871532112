<template>
  <span class="more-with-pro-text">{{ $t("limited_version.more_options_with") }} <pro-text></pro-text></span>
</template>

<script lang="ts">
import ProText from "./pro-text.vue";

export default {
  name: "MoreWithProText",
  components: {
    ProText,
  },
};
</script>
