<template>
  <div class="time-period-dropdown intercom-tag-filters-time-periods">
    <div class="title" v-if="showTitle">{{ title }}</div>
    <dropdown>
      <template v-slot:toggle="toggleProps">
        <div class="btn btn-sm btn-primary" :class="btnClasses" @click="toggleProps.toggleDropdown()">
          {{ label }} <span class="caret"></span>
        </div>
      </template>
      <template slot="menu">
        <div class="simple-spinner" v-if="!availableItems">
          <div class="spinner"></div>
        </div>
        <div v-if="availableItems">
          <dropdown-item @click="selectItem(item)" v-for="item in items" :key="menuItemKey(item)">{{
            getLabel({ type: menuItemKey(item) })
          }}</dropdown-item>
          <div class="divider" v-if="isLimited"></div>
          <more-with-pro-menu>
            <div class="divider" v-if="restItems.length > 0"></div>
            <dropdown-submenu v-if="restItems.length > 0" :title="$t('filters.date_selector.more_options')">
              <dropdown-item @click="selectItem(item)" v-for="item in restItems" :key="menuItemKey(item)">{{
                getLabel({ type: menuItemKey(item) })
              }}</dropdown-item>
            </dropdown-submenu>
            <div class="divider" v-if="custom"></div>
            <dropdown-submenu v-if="custom" :title="$t('filters.date_selector.custom')">
              <dropdown-calendar mode="range" @select-custom-range="selectCustomRange"></dropdown-calendar>
            </dropdown-submenu>
            <div class="divider"></div>
            <dropdown-submenu :title="$t('filters.date_selector.rolling_yearly_period')">
              <dropdown-submenu :title="$t('filters.date_selector.until_yesterday')">
                <div class="text-xs px-md pt-sm">{{ $t("filters.date_selector.pick_start_date") }}</div>
                <div class="divider"></div>
                <dropdown-calendar
                  mode="single"
                  :min-date="yearStartDate"
                  :max-date="yearEndDate"
                  @select-date="selectCustomUntilYesterday"
                >
                  <template v-slot:title="headerProps">{{ headerProps.monthLabel }}</template>
                </dropdown-calendar>
              </dropdown-submenu>
            </dropdown-submenu>
            <div v-if="availableSeries.length > 0">
              <div class="divider"></div>
              <dropdown-submenu :title="$t('filters.date_selector.time_series_type')">
                <dropdown-item @click="selectSeries(series)" v-for="series in availableSeries" :key="series.name">{{
                  series.name
                }}</dropdown-item>
              </dropdown-submenu>
            </div>
          </more-with-pro-menu>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts">
import moment from "moment";
import TranslationService from "../core/translation.service";
import i18n from "../i18n";
import Vue, { defineComponent, PropType } from "vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";
import Dropdown from "./dropdown.vue";
import DropdownItem from "./dropdown-item.vue";
import DropdownSubmenu from "./dropdown-submenu.vue";
import DropdownCalendar from "./dropdown-calendar.vue";
import MoreWithProMenu from "./more-with-pro-menu.vue";

export default defineComponent({
  components: {
    Dropdown,
    DropdownItem,
    DropdownSubmenu,
    DropdownCalendar,
    MoreWithProMenu,
  },
  data() {
    return {
      isLimited: window.zoinedContext.limited,
    };
  },
  computed: {
    btnClasses() {
      return {
        default: this.default && !this.value,
      };
    },
    label() {
      if (this.value) {
        return this.getLabel(this.value);
      } else if (this.default) {
        return this.getLabel(this.default);
      } else {
        return "Select";
      }
    },
    items() {
      return this.isLimited
        ? this.availableItems.filter((item) => !this.isLimited || item.enabled_for_limited)
        : _.take(this.availableItems, this.showItems);
    },
    restItems() {
      return _.drop(this.availableItems, this.showItems);
    },
    availableSeries() {
      return (this.availableSeriesTypes || []).map((type) => ({
        type,
        name: new TranslationService().seriesTitle(type),
      }));
    },
    yearStartDate() {
      return moment()
        .subtract(1, "year")
        .startOf("year")
        .toDate();
    },
    yearEndDate() {
      return moment()
        .subtract(1, "year")
        .endOf("year")
        .toDate();
    },
    dropdown: {
      cache: false,
      get() {
        return this.$refs["dropdown"] as Dropdown;
      },
    },
  },
  methods: {
    menuItemKey(item) {
      return menuItemKey(item);
    },
    selectItem(item) {
      const current = this.value || this.default || {};
      this.$emit("update", { ...current, type: menuItemKey(item) });
    },
    selectSeries({ type }) {
      const current = this.value || this.default || {};
      this.$emit("update", { ...current, series: type });
    },
    getLabel(selection) {
      return new TranslationService().selectionTitle(selection, this.availableItems);
    },
    selectCustomUntilYesterday(date) {
      let start = moment(date);
      const selection = {
        type: "custom_until_yesterday",
        start_time: start.format("YYYY-MM-DD"),
      };
      this.$emit("update", selection);
    },
    selectCustomRange([d1, d2]) {
      let start = moment(d1);
      let end = moment(d2);
      [start, end] = start.isAfter(end) ? [end, start] : [start, end];

      const current = this.value || this.default || {};
      const selection = {
        ...current,
        type: "custom",
        start_time: start.format("YYYY-MM-DD"),
        end_time: end.format("YYYY-MM-DD"),
      };

      this.$emit("update", selection);
    },
  },
  props: {
    availableItems: {
      type: Array as PropType<MenuItem[]>,
    },
    availableSeriesTypes: {
      type: Array as PropType<string[]>,
    },
    default: {
      type: Object as PropType<any>,
    },
    value: {
      type: Object as PropType<any>,
    },
    showTitle: { default: true, type: Boolean },
    custom: { default: true, type: Boolean },
    showItems: { default: 5, type: Number },
    title: { default: i18n.t("dashboard_custom.report_editor.time"), type: String },
  },
});
</script>

<style lang="scss" scoped>
.time-period-dropdown {
  display: inline-block;

  .title {
    display: inline-block;
    margin-right: 15px;
  }

  .btn.default {
    opacity: 0.5;
  }

  .dropdown-submenu {
    position: relative;
    .dropdown-menu {
      top: 0;
      left: 100%;
      &.open {
        display: block;
      }
      @media (max-width: 767px) {
        position: absolute;
        left: 0;
      }
    }
  }

  .vc-container {
    border: 0;
  }
}
</style>
