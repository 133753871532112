import { useQuery } from "@tanstack/vue-query";
import { makeApiInstance } from "./instance";
import { MaybeRefOrGetter, toRef } from "@vueuse/core";
import _ from "lodash";

const api = makeApiInstance({ baseURL: "/api/v1/parameters" });

type UseParametersOptions = {
  params?: MaybeRefOrGetter<Record<string, any>>;
  enabled?: MaybeRefOrGetter<boolean>;
};

const useParameters = (id: MaybeRefOrGetter<string>, options: UseParametersOptions = {}) => {
  id = toRef(id);
  const params = toRef(options.params);
  const enabled = toRef(_.isUndefined(options.enabled) ? true : options.enabled);
  return useQuery({
    queryKey: ["parameters", id, params],
    queryFn: ({ signal }) => api.get(id.value, { params: params?.value, signal }).then((res) => res.data),
    enabled,
  });
};

export { useParameters };

export default api;
