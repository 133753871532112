<template>
  <dropdown v-on="$listeners">
    <template v-slot:toggle="toggleProps">
      <div class="submenu-title flex-row align-items-center gap-md" @click="toggleProps.toggleDropdown()">
        <div class="flex-1">{{ title }}</div>
        <i class="fa fa-chevron-right fa-sm"></i>
      </div>
    </template>
    <template v-slot:menu>
      <slot></slot>
    </template>
  </dropdown>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import Dropdown from "./dropdown.vue";
export default defineComponent({
  components: {
    Dropdown,
  },
  props: {
    title: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.submenu-title {
  cursor: pointer;
  padding: 5px 15px;
  white-space: nowrap;

  i.fa {
    font-size: 10px;
  }
}
.dropdown {
  display: block;
  &::v-deep {
    .dropdown-menu {
      left: 100%;
      top: 0;
    }
  }
}
</style>
