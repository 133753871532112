<template>
  <div class="report-template-editor">
    <div class="loading-indicator" v-if="!loaded">
      <spinner></spinner>
    </div>
    <div class="editor" v-else>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group" :class="{ 'has-error': v$.template.name.$error }">
            <label class="control-label" for="name">{{ $t("attributes.name") }}</label>
            <input class="form-control" id="name" type="text" v-model="template.name" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">{{ $t("attributes.description") }}</label>
            <input id="name" type="text" v-model="template.description" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" :class="{ 'has-error': v$.template.translation_key.$error }">
            <label class="control-label" for="title" style="margin-right: 5px;">{{
              $t("dashboard_templates.translation_key")
            }}</label>
            <div class="subtitle">
              Translation is saved in localizations (localeapp) under "analytics_templates.titles.[key]"
            </div>
            <input class="form-control" id="title" type="text" v-model="template.translation_key" />
            <div class="help-block" v-if="v$.template.translation_key.$error">
              Key can contain only characters a-z and _
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>{{ $t("attributes.visibility") }}</h3>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div>
              <label>{{ $t("activerecord.attributes.dashboard_template.visible_in_zoined") }}</label>
            </div>
            <toggle-button v-model="template.visible_in_zoined"></toggle-button>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div>
              <label>{{ $t("activerecord.attributes.dashboard_template.add_for_new_customers") }}</label>
            </div>
            <toggle-button v-model="template.add_for_new_customers"></toggle-button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <div>
              <label>{{ $t("activerecord.attributes.dashboard_template.partners") }}</label>
            </div>
            <partner-selector v-model="template.partners"></partner-selector>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("custom_report.report") }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="m-sm type-item component"
            v-for="reportType in reportTypes"
            :class="{ selected: reportType.config.report_type === selectedReportType.config.report_type }"
            @click="selectReportType(reportType.config.report_type)"
          >
            <div class="chartImg" v-bind:class="reportType.config.report_type.replace(/[^a-zA-Z]/g, '')"></div>
            <p class="type-text">
              {{ $t("dashboard_custom.report_editor.component_types." + reportType.config.report_type) }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("dashboard_custom.report_editor.filters") }}</h4>
        </div>
      </div>
      <div class="row time-filters">
        <div class="col-md-12">
          <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
            <time-period-dropdown
              v-if="isFilterEnabled('date')"
              :available-items="options.time_ranges"
              :available-series-types="options.seriesTypes"
              :value="filterConfiguration.time.selection"
              @update="updateSelection($event)"
            ></time-period-dropdown>
            <comparison-pill-list
              v-if="isFilterEnabled('comparisons')"
              :comparisons="filterConfiguration.time.comparisons"
              @update="updateComparisons"
            ></comparison-pill-list>
            <business-hours-selector
              v-if="isFilterEnabled('limit_to_business_hours')"
              :value="filterConfiguration.time.limit_to_business_hours"
              @update="updateBusinessHours($event)"
            ></business-hours-selector>
          </div>
          <widget-filters-section
            :filter-configuration="filterConfiguration"
            :report-config="selectedReportType.config"
            @update="updateFilterConfiguration"
          ></widget-filters-section>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
            <filter-selector
              :config="filters[filter.id]"
              :filter="filter"
              @update="updateFilter(filter.id, $event)"
              v-for="filter in options.filters"
              :key="filter.id"
            ></filter-selector>
          </div>
        </div>
      </div>
      <div class="row buttons">
        <div class="col-md-12">
          <div class="pull-left" v-if="id">
            <confirm-button
              :button-title="$t('actions.delete')"
              variant="danger"
              @confirm="deleteTemplate()"
            ></confirm-button>
          </div>
          <div class="pull-right">
            <button class="pull-right btn btn-primary" @click="save" :disabled="submitDisabled">
              {{ $t("actions.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _, { template } from "lodash";
import { convertToNewFilterConfiguration, filterToFlyover, refreshFilterTimes } from "@/lib/filter-util";
import Vue, { defineComponent } from "vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import filterSelector from "../../components/filter-selector.vue";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import ConfirmButton from "../../components/confirm-button.vue";
import { makeApiInstance } from "../../api/instance";
import AnalyticsTemplate from "../../model/analytics-template";
import i18n from "../../i18n";
import spinner from "../../components/spinner.vue";
import pillList from "../../components/pill-list.vue";
import toggleButton from "../../components/toggle-button.vue";
import Actions from "../../store/actions";
import partnerSelector from "../../components/partner-selector.vue";
import { reportTypes } from "../../custom-report/report-types";
import FilterConfiguration from "@/model/filter-configuration";
import WidgetFiltersSection from "../../flyover-filters/widget-filters-section.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      template: {
        name: { required },
        translation_key: {
          valid: (key: string) => !key || !!key.match(/^[a-z_]*$/),
        },
      },
    };
  },
  components: {
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    ConfirmButton,
    spinner,
    pillList,
    toggleButton,
    partnerSelector,
    WidgetFiltersSection,
  },
  data() {
    const template: Partial<AnalyticsTemplate> = null;

    return {
      template,
      selectedReportType: null,
    };
  },
  computed: {
    api() {
      return makeApiInstance({ baseURL: "/api/zoined_admin/analytics_templates/" });
    },
    myReportsApi() {
      return makeApiInstance({ baseURL: "/api/v1/my_reports/" });
    },
    title() {
      return this.id ? i18n.t("report_templates.edit_report_template") : i18n.t("report_templates.new_report_template");
    },
    loaded() {
      return this.template && this.selectReportType && this.options;
    },
    submitDisabled() {
      return false;
    },
    reportTypes() {
      return reportTypes;
    },
    options() {
      const filters = this.$store.state.parameters.filters.all || [];
      const groupings = this.$store.state.parameters.grouping.all || [];
      const metrics = this.$store.state.parameters.metrics.all || [];
      const time_ranges = (this.$store.state.parameters.timePeriods.all || []).map(({ id }) => ({ key: id }));
      const sort = this.$store.state.parameters.sort.all || [];

      const seriesTypes = window.zoinedContext.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];

      return {
        filters,
        groupings,
        metrics,
        time_ranges,
        sort,
        seriesTypes,
      };
    },
    filterConfiguration() {
      return _.cloneDeep(this.template.config.filterConfiguration);
    },
    filters() {
      return _.reduce(
        this.filterConfiguration.filters.filters,
        (result, config, filter) => {
          return {
            ...result,
            [filter]: _.fromPairs(
              _.map(config, ({ enabled, exclude, value, name }, key) => [
                key,
                {
                  value: key,
                  enabled,
                  exclude: !!exclude,
                  name: name || value || key,
                },
              ])
            ),
          };
        },
        {}
      );
    },
    activeFilters() {
      // Get names of active filters. Some filters might have some default value set.
      const activeFilters = _.union(this.template.config.filters, Object.keys(this.selectedReportType.defaults || {}));

      if (activeFilters.includes("date")) {
        // Filter key for date is selection (not date)
        activeFilters.push("selection");
      }

      return activeFilters;
    },
  },
  created() {
    this.fetchOrInitTemplate();
  },
  methods: {
    isFilterEnabled(filter) {
      return this.activeFilters.includes(filter);
    },
    selectReportType(type) {
      const currentFilters = filterToFlyover(this.template.config.filterConfiguration);

      this.selectedReportType =
        reportTypes.find((reportType) => reportType.config.report_type === type) || reportTypes[0];
      this.template.config = _.cloneDeep(this.selectedReportType.config);

      // some sensible global defaults
      const defaults = filterToFlyover({
        selection: { type: "4_weeks" },
        comparisons: [{ type: "prev_year_corresponding", enabled: true }],
        metrics: { sales: { enabled: true } },
        grouping: { store: { enabled: true } },
        column_grouping: { weekday: { enabled: true } },
      });

      //  defaults for selected type
      const reportTypeDefaults = filterToFlyover(
        Object.keys(this.selectedReportType.filters || {}).reduce((defaults, key) => {
          const defaultValue = _.get(this.selectedReportType.filters[key], "default");
          return defaultValue
            ? { ...defaults, [key]: _.isFunction(defaultValue) ? defaultValue() : defaultValue }
            : defaults;
        }, this.selectedReportType.defaults || {})
      );

      // remove filters that are not used for this report type and set correct defaults
      const filterConfiguration = Object.assign({}, currentFilters, {
        time: _.pick({ ...defaults.time, ...currentFilters.time, ...reportTypeDefaults.time }, this.activeFilters),
        widgets: _.pick(
          { ...defaults.widgets, ...currentFilters.widgets, ...reportTypeDefaults.widgets },
          this.activeFilters
        ),
        raw_filters: _.pick(
          { ...defaults.raw_filters, ...currentFilters.raw_filters, ...reportTypeDefaults.raw_filters },
          this.activeFilters
        ),
      });

      Vue.set(this.template.config, "filterConfiguration", filterConfiguration);
    },
    updateSelection(selection) {
      Vue.set(this.template.config.filterConfiguration.time, "selection", selection);
    },
    updateComparisons(comparisons) {
      Vue.set(this.template.config.filterConfiguration.time, "comparisons", comparisons);
    },
    updateBusinessHours(limitToBusinessHours) {
      Vue.set(this.template.config.filterConfiguration.time, "limit_to_business_hours", limitToBusinessHours);
    },
    updateFilter(filter, filterConfig) {
      if (!_.isEmpty(filterConfig)) {
        const config = _.reduce(
          _.values(filterConfig),
          (result, { enabled, exclude, value, name }) => ({
            ...result,
            [value]: { enabled, exclude: !!exclude, value, name: name || value },
          }),
          {}
        );
        Vue.set(this.template.config.filterConfiguration.filters.filters, filter, config);
      } else {
        Vue.delete(this.template.config.filterConfiguration.filters.filters, filter);
      }
    },
    updateFilterConfiguration(filterConfiguration: FilterConfiguration) {
      Vue.set(this.template.config, "filterConfiguration", filterConfiguration);
    },
    async deleteTemplate() {
      await this.api.delete(this.id);

      this.$router.back();
    },
    async save() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      const url = this.id ? this.id : "";
      const method = this.id ? "put" : "post";
      const analytics_template: Partial<AnalyticsTemplate> = {
        name: this.template.name,
        description: this.template.description,
        analytics_type: "report",
        config: this.template.config,
        add_for_new_customers: this.template.add_for_new_customers,
        visible_in_zoined: this.template.visible_in_zoined,
        partner_ids: this.template.partners?.map(({ id }) => id) || [],
        translation_key: this.template.translation_key,
      };

      await this.api.request({
        url,
        method,
        data: {
          analytics_template,
        },
      });

      this.$store.dispatch(Actions.fetchNavigation);

      this.$router.back();
    },
    async fetchOrInitTemplate() {
      if (this.id) {
        await this.api.get(this.id).then(({ data }) => {
          this.template = data;
        });
      } else if (this.reportId) {
        const { filters, report } = await this.myReportsApi.get(this.reportId).then((result) => result.data);

        this.template = {
          name: report.metadata.title,
          description: report.metadata.description,
          analytics_type: "report",
          config: { ...report.config, filterConfiguration: filters },
          visible_in_zoined: true,
          add_for_new_customers: false,
        };
      } else if (this.templateId) {
        await this.api.get(this.templateId).then((response) => {
          const template: AnalyticsTemplate = response.data;
          this.template = {
            name: i18n.t("newsletter.definition.copy_of", {
              title: template.name,
            }),
            description: template.description,
            analytics_type: "report",
            config: template.config,
            visible_in_zoined: template.visible_in_zoined,
            add_for_new_customers: template.add_for_new_customers,
          };
        });
      } else {
        const filterConfiguration = convertToNewFilterConfiguration({
          selection: { type: "4_weeks" },
          comparisons: [{ type: "prev_year_corresponding", enabled: true }],
        });

        this.template = {
          name: "",
          description: "",
          config: {
            components: [],
            filters: ["date", "comparisons", "limit_to_business_hours"],
            sort: ["-metric", "metric", "grouping"],
            default: {},
            radio_selectors: ["grouping"],
            excel: true,
            benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
            budgets_enabled: true,
            filterConfiguration,
          },
          visible_in_zoined: true,
          add_for_new_customers: false,
        };
      }

      this.template.config.filterConfiguration = convertToNewFilterConfiguration(
        this.template.config.filterConfiguration
      );
      this.template.config.filterConfiguration.time = refreshFilterTimes(this.template.config.filterConfiguration.time);

      this.selectReportType(this.template.config.report_type);
    },
  },
  props: {
    id: {
      type: String,
    },
    reportId: {
      type: String,
    },
    templateId: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.report-template-editor {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.title {
  margin-bottom: 20px;
}

.buttons {
  margin-top: 15px;
}

.time-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
</style>
