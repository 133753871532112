<template>
  <div class="table-settings-modal">
    <a class="btn btn-default btn-sm btn-icon" @click="show"><i class="fa fa-table"></i></a>
    <modal v-if="config" v-model="open" :append-to-body="true" :title="title" size="lg">
      <h2 v-if="isCrossTab">{{ $t("table_settings.settings") }}</h2>
      <div class="row" v-if="isCrossTab">
        <div class="col-md-4">
          <label>{{ $t("table_settings.combine_groupings_label") }}</label>
          <toggle-button v-model="config.combine_groupings"></toggle-button>
        </div>
      </div>
      <h2>{{ $t("table_settings.column_visibility") }}</h2>
      <table class="column-configuration">
        <tr>
          <th>{{ $t("filter.config.metrics") }}</th>
          <th>{{ $t("table_settings.serie") }}</th>
          <th>{{ $t("table_settings.column") }}</th>
          <th v-if="columnGrouping">{{ columnGroupingTitle }}</th>
          <th v-if="isCrossTab">{{ $t("report.snippet.table.total") }}</th>
          <th v-else>{{ $t("attributes.visibility") }}</th>
        </tr>
        <tr v-for="column in config.columns">
          <td>{{ metricLabel(column.metric) }}</td>
          <td>{{ seriesLabel(column.series) }}</td>
          <td>{{ columnLabel(column.column) }}</td>
          <td v-if="columnGrouping">
            <input type="checkbox" v-model="column.visibility[columnGrouping]" />
          </td>
          <td>
            <input type="checkbox" v-model="column.visibility.total" />
          </td>
        </tr>
      </table>

      <h2 v-if="isCrossTab">{{ $t("table_settings.total_rows") }}</h2>
      <table class="total-visibility" v-if="isCrossTab">
        <tr>
          <th>{{ $t("filter.config.grouping") }}</th>
          <th>{{ $t("table_settings.show_total_rows") }}</th>
        </tr>
        <tr v-for="item in config.totals" :key="item.grouping">
          <td>{{ item.grouping == "total" ? reportTotalTitle : groupingTitle(item.grouping) }}</td>
          <td>
            <input
              type="radio"
              :name="'total_visibility[' + item.grouping + ']'"
              value="before"
              v-model="item.total_visibility"
            />
            <label>{{ $t("table_settings.total_visibility.before") }}</label>
            <input
              type="radio"
              :name="'total_visibility[' + item.grouping + ']'"
              value="after"
              v-model="item.total_visibility"
            />
            <label>{{ $t("table_settings.total_visibility.after") }}</label>
            <input
              type="radio"
              :name="'total_visibility[' + item.grouping + ']'"
              value="hidden"
              v-model="item.total_visibility"
            />
            <label>{{ $t("table_settings.total_visibility.hidden") }}</label>
          </td>
        </tr>
      </table>

      <div slot="footer">
        <button class="btn btn-default" @click="open = false">{{ $t("actions.cancel") }}</button>
        <button class="btn btn-primary" @click="update">{{ $t("actions.save") }}</button>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import { Modal } from "uiv";
import i18n from "../i18n";
import TranslationService from "../core/translation.service";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import configPillList from "./config-pill-list.vue";
import toggleButton from "./toggle-button.vue";
import _ from "lodash";
import TableConfig from "../model/table-config";
import Component from "@/model/component";
import ChartOptions from "@/model/chart-options";

export default defineComponent({
  components: { Modal, configPillList, toggleButton },
  props: {
    tableConfig: { type: Object as PropType<TableConfig>, required: false },
    filterConfiguration: { type: Object, required: true },
    chartOptions: { type: Object as PropType<ChartOptions>, required: false },
    component: { type: Object as PropType<Component>, required: true },
  },
  data() {
    return {
      open: false,
      config: null,
      count: 0,
    };
  },
  computed: {
    title() {
      "Table settings";
    },

    columnGrouping() {
      const columnGrouping = _.find(
        _.toPairs<any>(this.filterConfiguration.column_grouping),
        // eslint-disable-next-line no-unused-vars
        ([key, config]) => config.enabled
      );
      if (columnGrouping) {
        return columnGrouping[0];
      } else {
        return null;
      }
    },

    columnGroupingTitle() {
      return this.groupingTitle(this.columnGrouping);
    },

    reportTotalTitle() {
      return i18n.t("table_settings.report_total");
    },

    comparisons() {
      return this.filterConfiguration.comparisons.filter(({ enabled }) => enabled);
    },

    metrics() {
      return (
        _.toPairs<any>(this.filterConfiguration.metrics)
          // eslint-disable-next-line no-unused-vars
          .filter(([key, { enabled }]) => enabled)
          .map(([key]) => key)
      );
    },

    dependencies() {
      return [this.filterConfiguration, this.tableConfig];
    },

    metricsParams() {
      return this.$store.getters.getParameters("metrics");
    },

    groupingParams() {
      return this.$store.getters.getParameters("grouping");
    },

    isCrossTab() {
      return this.component.name == "cross_tab_custom";
    },

    selectionColumns() {
      const columns = ["actual"];
      const config = { ...this.filterConfiguration, ...this.chartOptions };
      if (config.proportions) {
        columns.push("proportion");
      }
      if (config.column_proportions) {
        columns.push("column_proportion");
      }
      return columns;
    },

    comparisonColumns() {
      const columns = ["actual"];
      const config = { ...this.filterConfiguration, ...this.chartOptions };
      if (config.show_percentual_change) {
        columns.push("change_percent");
      }
      if (config.show_absolute_change) {
        columns.push("change_absolute");
      }
      if (config.show_index) {
        columns.push("change_index");
      }
      if (config.proportions) {
        columns.push("proportion");
      }
      if (config.column_proportions) {
        columns.push("column_proportion");
      }
      return columns;
    },
  },
  methods: {
    metricLabel(metric) {
      if (this.metricsParams) {
        const metricParam = this.metricsParams.find(({ key }) => key === metric);
        if (metricParam) {
          return metricParam.name;
        }
      }

      return i18n.t(`metadata.metrics.${metric}`);
    },
    groupingTitle(groupingKey) {
      const groupings = this.$store.getters.getParameters("grouping");
      const grouping = groupings?.find(({ key }) => key == groupingKey);
      return (grouping && grouping.name) || i18n.t(`filter.config.${groupingKey}`);
    },
    seriesLabel(series) {
      if (series === "selection") {
        return i18n.t("selection");
      } else {
        return new TranslationService().comparisonTitle(menuKeyToComparison(series));
      }
    },
    columnLabel(column) {
      if (column == "actual") {
        return i18n.t("filters.date_selector.actual");
      } else {
        return i18n.t(`report.snippet.table.${column}`);
      }
    },
    show() {
      this.buildConfig();
      this.open = true;
    },
    update() {
      this.$emit("update", this.config);
      this.open = false;
    },
    buildConfig() {
      const config: any = {};

      let tableConfig = this.tableConfig || {};
      const columnConfig = (tableConfig.columns || []).reduce((config, item) => {
        const { metric, series, column, visibility } = item;
        config[metric] = config[metric] || {};
        config[metric][series] = config[metric][series] || {};
        config[metric][series][column] = visibility;
        return config;
      }, {});

      const columns = [];

      const defaultVisibility: any = {
        total: true,
      };
      if (this.columnGrouping) {
        defaultVisibility[this.columnGrouping] = true;
      }

      this.metrics.forEach((metric) => {
        this.selectionColumns.forEach((column) => {
          const series = "selection";
          columns.push({
            metric,
            column,
            series,
            visibility: {
              ...defaultVisibility,
              ..._.get(columnConfig, [metric, series, column]),
            },
          });
        });
        this.comparisons.forEach((comparison) => {
          const series = comparisonToMenuKey(comparison);
          this.comparisonColumns.forEach((column) => {
            columns.push({
              metric,
              column,
              series,
              visibility: {
                total: true,
                ...defaultVisibility,
                ..._.get(columnConfig, [metric, series, column]),
              },
            });
          });
        });
      });

      config.columns = columns;

      if (this.isCrossTab) {
        const totalConfig = _.cloneDeep(
          (tableConfig.totals || []).reduce((config, item) => {
            return {
              ...config,
              [item.grouping]: item.total_visibility,
            };
          }, {})
        );

        const groupingKeys = _.toPairs<any>(this.filterConfiguration.grouping)
          // eslint-disable-next-line no-unused-vars
          .filter(([key, config]) => config.enabled)
          .slice(0, -1)
          .map(([grouping]) => grouping)
          .concat(["total"]);

        const totals = groupingKeys.map((grouping) => ({
          grouping,
          total_visibility: totalConfig[grouping] || "before",
        }));

        config.totals = totals;

        config.combine_groupings = !!tableConfig.combine_groupings;
      }

      this.config = config;
    },
  },
});
</script>

<style lang="scss" scoped>
.table-settings-modal {
  display: inline-block;
}

label {
  display: block;
}
table.column-configuration {
  width: 100%;
  td {
    text-align: left;
  }
}
table.total-visibility {
  width: 100%;
  label {
    display: inline-block;
    font-weight: normal;
    margin-right: 10px;
    margin-left: 5px;
  }
}
</style>
