<template>
  <div class="report-attachment-section">
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.report_attachment.report") }}</label>
      <select :value="section.report" @input="updateReport">
        <option v-for="option in reportOptions" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import Actions from "../../store/actions";
import Vue, { defineComponent, PropType } from "vue";
export default defineComponent({
  computed: {
    reportOptions() {
      return this.$store.state.excelReports.all;
    },
  },
  methods: {
    updateReport(event) {
      const report = Number.parseInt(event.target.value);
      this.$emit("update-section", { ...this.section, report });
    },
  },
  props: {
    section: {
      type: Object as PropType<any>,
    },
  },
});
</script>
