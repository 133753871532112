<template>
  <select v-model="model" @change="onModelChange">
    <option :value="item.key" v-for="item in items">{{ item.name }}</option>
  </select>
</template>

<script lang="ts">
import Comparison from "../model/comparison";
import Vue, { defineComponent, PropType } from "vue";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import MenuItem from "../interfaces/menu-item";
import _ from "lodash";

export default defineComponent({
  data() {
    const model: string = null;

    return {
      model,
    };
  },
  computed: {
    items(): MenuItem[] {
      return (
        this.choices?.map((comparison) => {
          return {
            key: comparisonToMenuKey(comparison),
            name: comparison.name,
          };
        }) || []
      );
    },
  },
  created() {
    this.updateModel(this.value);
  },
  methods: {
    onModelChange() {
      this.$emit("input", menuKeyToComparison(this.model));
    },
    updateModel(value) {
      if (!value || _.isEmpty(value)) {
        this.model = null;
      } else {
        value = _.isString(value) ? { type: value } : value;
        this.model = comparisonToMenuKey(value);
      }
    },
  },
  props: {
    value: {
      type: Object as PropType<Comparison>,
    },
    choices: {
      type: Array as PropType<any[]>,
    },
  },
  watch: {
    value: [
      {
        handler: "updateModel",
      },
    ],
  },
});
</script>
