<template>
  <div class="flex-row align-items-center gap-lg">
    <div class="title">{{ $t("filter.config.show_filterset_name") }}</div>
    <btn-group class="btn-multiple">
      <btn class="btn-sm" type="primary" input-type="radio" :input-value="true" v-model="model" @click="update(true)">{{
        $t("options.yes")
      }}</btn>
      <btn
        class="btn-sm"
        type="primary"
        input-type="radio"
        :input-value="false"
        v-model="model"
        @click="update(false)"
        >{{ $t("options.no") }}</btn
      >
    </btn-group>
  </div>
</template>
<script>
import { Btn, BtnGroup } from "uiv";

export default {
  components: {
    Btn,
    BtnGroup,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const model = !!this.value;
    return {
      model,
    };
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
};
</script>
