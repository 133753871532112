<template>
  <div class="public-report-page" v-if="config">
    <zoined-report
      :components="config.components"
      :filter-configuration="config.filter_configuration"
      :chart-options="config.chart_options"
      :table-config="config.table_config"
      :highchart-options="config.highchart_options"
      :custom="config.custom"
      :public="true"
    ></zoined-report>
  </div>
  <div class="public-report-page" v-else>
    <h2 class="text-center">{{ $t("errors.messages.page_not_found") }}</h2>
  </div>
</template>

<script lang="ts">
import Mutations from "../store/mutations";
import Vue, { defineComponent } from "vue";
import ZoinedReport from "../analytics/zoined-report.vue";

export default defineComponent({
  components: { ZoinedReport },
  data() {
    const config: any = null;

    return {
      config,
    };
  },
  computed: {
    reportContextState() {
      return this.$store.state.reportContext.state;
    },
  },
  created() {
    if (!window.publicReportConfig) {
      return;
    }

    this.config = window.publicReportConfig;

    if (this.reportContextState === "initial" && window.reportContext) {
      this.$store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
    }

    if (this.config.refresh) {
      // Refresh every 5 minutes
      window.setInterval(() => this.refresh(), 1000 * 300);
    }
  },
  methods: {
    refresh() {
      const config = this.config;
      this.config = null;
      window.setTimeout(() => (this.config = config));
    },
  },
});
</script>
