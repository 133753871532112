<template>
  <newsletter-definition-editor-view
    :definition="definition"
    :error="error"
    :save="save"
  ></newsletter-definition-editor-view>
</template>

<script lang="ts">
import i18n from "@/i18n";
import Configuration from "@/newsletter-definition-editor/configuration";
import Vue, { defineComponent } from "vue";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import newsletterTemplatesApi from "../api/newsletter-templates-api";
import toastr from "toastr";
import _ from "lodash";
import NewsletterDefinitionEditorView from "./newsletter-definition-editor-view.vue";

export default defineComponent({
  components: {
    NewsletterDefinitionEditorView,
  },
  data() {
    const error: string = null;

    return {
      definition: null,
      error,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    currency() {
      return window.zoinedContext.defaultCurrency.currency;
    },
  },
  created() {
    if (this.id || this.duplicateId) {
      const params = {
        company_id: this.companyId,
        id: this.id || this.duplicateId,
      };
      newsletterDefinitionsApi.get(params).then((definition) => {
        return Configuration.fromJson(definition.configuration).then((config) => {
          if (!this.id && this.duplicateId) {
            definition.id = null;
            definition.team_id = null;
            definition.partner_id = null;
            definition.title = i18n.t("newsletter.definition.copy_of", {
              title: definition.title,
            });
          }
          definition.configuration = config;
          this.definition = definition;
        });
      });
    } else if (this.templateId) {
      newsletterTemplatesApi.getDefinition(this.templateId).then((definition) => {
        Configuration.fromJson(definition.configuration).then((configuration) => {
          definition.configuration = configuration;
          this.definition = {
            company_id: this.companyId,
            currency: this.currency,
            ...definition,
          };
        });
      });
    } else {
      this.definition = {
        newsletter_type: "",
        company_id: this.companyId,
        currency: this.currency,
        translations: {},
        configuration: new Configuration({
          use_default_parameters: false,
        }),
      };
    }
  },
  methods: {
    async save(definition) {
      definition = _.cloneDeep(definition);
      const configuration = definition.configuration;

      definition.configuration = configuration.toJson();

      const id = definition.id;
      const company_id = definition.company_id;

      const action = !id
        ? newsletterDefinitionsApi.post({ company_id, newsletter_definition: definition })
        : newsletterDefinitionsApi.put({ id, company_id, newsletter_definition: definition });

      return action
        .then((result) => {
          if (!id) {
            definition.id = result.id;

            this.$router.replace(`${result.id}/edit`);
          }
          this.error = null;

          toastr.clear();
          toastr.success(i18n.t("newsletter.definition.save.success"));
        })
        .catch((error) => {
          toastr.clear();
          toastr.error(i18n.t("newsletter.definition.save.failure"), { timeOut: 0 });
          this.error = _.get(error, "response.data.error");
        });
    },
  },
  props: {
    id: {
      type: String,
    },
    templateId: {
      type: String,
    },
    duplicateId: {
      type: String,
    },
  },
});
</script>
