<template>
  <inline-svg :src="iconSrc"></inline-svg>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
export default defineComponent({
  components: {
    InlineSvg,
  },
  computed: {
    iconSrc() {
      return require(`@assets/images/icons/${this.name}.svg`);
    },
  },
  props: {
    name: {
      type: String,
    },
  },
});
</script>
