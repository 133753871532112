<template>
  <div class="markdown-renderer" v-html="html" />
</template>

<script setup lang="ts">
import MarkdownIt from "markdown-it";
import { computed } from "vue";

const props = defineProps<{
  source: string;
}>();

const markdown = new MarkdownIt();

const html = computed(() => markdown.render(props.source));
</script>

<style scoped></style>
