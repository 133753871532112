<template>
  <PillList
    :title="title"
    :items="items"
    :available-items="availableItems"
    :searchable="searchable"
    :togglable="false"
    :multi-select="true"
    :excludable="true"
    @update="onUpdate"
  />
</template>

<script setup lang="ts">
import PillList from "@/components/pill-list.vue";
import MenuItem from "@/interfaces/menu-item";
import PillItem from "@/interfaces/pill-item";
import MyReportAccess from "@/model/my_report_access";
import { computed } from "vue";

export type MyReportAccessEntity = {
  id: number;
  name: string;
};

const props = defineProps<{
  title: string;
  value: MyReportAccess[];
  entityType: string;
  entities: MyReportAccessEntity[];
}>();

const emit = defineEmits(["input"]);

const items = computed<PillItem[]>(() => {
  return props.value
    .filter((access) => access.entity_type === props.entityType)
    .map((access) => ({
      value: access.entity_id.toString(),
      name: access.entity_name,
      exclude: !!access.exclude,
    }));
});

const availableItems = computed<MenuItem[]>(() => {
  return props.entities.map((entity) => ({
    id: entity.id.toString(),
    name: entity.name,
  }));
});

const searchable = computed<boolean>(() => {
  return props.entities.length > 10;
});

const onUpdate = ({ items }: { items: PillItem[] }) => {
  const accesses: MyReportAccess[] = items.map(({ value, name, exclude }) => ({
    entity_id: parseInt(value),
    entity_name: name,
    entity_type: props.entityType,
    exclude: !!exclude,
  }));

  emit("input", accesses);
};
</script>
