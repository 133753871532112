<template>
  <div class="dashboard-components-grid-editor">
    <draggable
      class="component-row"
      v-model="components"
      :options="{ draggable: '.component-wrapper', handle: '.handle' }"
      @change="updateComponents(components)"
    >
      <div
        class="component-wrapper"
        v-for="(component, index) in components"
        :class="{ half: component.span == 6, third: component.span == 4, quarter: component.span == 3 }"
      >
        <zoined-report-component
          class="component-item"
          :snippet="component"
          :component="component"
          :filter-configuration="flatFilterConfiguration"
          :editor="true"
          :chart-options="effectiveChartOptions(component)"
          :showFilterConfiguration="true"
          :custom="true"
          :dashboard="true"
          :editable="true"
          :duplicatable="true"
          :removable="true"
          @componentUpdated="onComponentUpdated(component, $event)"
          @edit="showAddComponentModal(index)"
          @remove="removeComponent(index)"
          @duplicate="duplicateComponent(index)"
        ></zoined-report-component>
      </div>
      <div class="add-component-button half">
        <div class="panel panel-default" id="new-component-button">
          <div class="button intercom-tag-custom-dashboard-add-component" @click="showAddComponentModal()">
            <div class="icon"><i class="fa fa-plus"></i></div>
            <div class="title">{{ $t("dashboard_custom.report_editor.add_component") }}</div>
          </div>
          <div class="button intercom-tag-custom-dashboard-embed-report" @click="showEmbedReportModal()">
            <div class="icon"><i class="fa fa-download"> </i></div>
            <div class="title">{{ $t("dashboard_custom.report_editor.embed_report") }}</div>
          </div>
        </div>
      </div>
    </draggable>
    <add-component-modal
      ref="addComponentModal"
      :addComponent="addComponent"
      :setComponent="setComponent"
      :filter-configuration="flatFilterConfiguration"
      :shared="shared"
    ></add-component-modal>
    <embed-report-modal ref="embedReportModal" @embedComponents="embedComponents"></embed-report-modal>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { flatFilterConfiguration } from "../lib/filter-util";
import Vue, { defineComponent, PropType } from "vue";
import draggable from "vuedraggable";
import AddComponentModal from "./add-component-modal.vue";
import EmbedReportModal from "./embed-report-modal.vue";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import ReportConfig from "../model/report-config";
import FilterConfiguration from "../model/filter-configuration";

export default defineComponent({
  components: {
    draggable,
    AddComponentModal,
    EmbedReportModal,
    zoinedReportComponent,
  },
  data() {
    const components: any[] = null;

    return {
      components,
    };
  },
  computed: {
    flatFilterConfiguration() {
      return flatFilterConfiguration(this.filterConfiguration);
    },
    addComponentModal: {
      cache: false,
      get() {
        return this.$refs["addComponentModal"] as any;
      },
    },
    embedReportModal: {
      cache: false,
      get() {
        return this.$refs["embedReportModal"] as any;
      },
    },
  },
  created() {
    this.onConfigUpdated();
  },
  methods: {
    effectiveChartOptions(component) {
      return {
        ...this.chartOptions,
        ...component.chartOptions,
      };
    },
    showAddComponentModal(index) {
      if (parseInt(index) > -1) {
        this.addComponentModal.showAddComponentModal(this.components, index);
      } else {
        this.addComponentModal.showAddComponentModal();
      }
    },
    showEmbedReportModal() {
      this.embedReportModal.show();
    },
    embedComponents(components) {
      this.updateComponents([...this.components, ...components]);
    },
    removeComponent(index) {
      this.components.splice(index, 1);
      this.updateComponents(this.components);
    },
    duplicateComponent(index) {
      const duplicate = _.cloneDeep(this.components[index]);
      this.components.splice(index + 1, 0, duplicate);
      this.updateComponents(this.components);
    },
    addComponent(component) {
      const components = [...this.components, component];
      this.updateComponents(components);
    },
    setComponent(component, index) {
      this.components.splice(index, 1, component);
      this.updateComponents(this.components);
    },
    onComponentUpdated(component, newComponent) {
      const index = this.config.components.indexOf(component);
      this.setComponent(newComponent, index);
    },
    updateComponents(components: any[]) {
      this.$emit("update", {
        ...this.config,
        components,
      });
    },
    onConfigUpdated() {
      this.components = [...this.config.components];
    },
  },
  props: {
    filterConfiguration: {
      type: Object as PropType<FilterConfiguration>,
    },
    config: {
      type: Object as PropType<ReportConfig>,
    },
    chartOptions: {
      type: Object as PropType<any>,
    },
    shared: { default: false, type: Boolean },
  },
  watch: {
    config: [
      {
        handler: "onConfigUpdated",
      },
    ],
  },
});
</script>

<style lang="scss" scoped>
.panel {
  border-style: dashed !important;
}

#new-component-button {
  height: 250px;
  display: flex;
  color: #555;

  .button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: #81e4ff;
    }

    .icon {
      font-size: 120px;
      line-height: 1;
    }

    &:first-child {
      border-right: 1px dashed #ddd;
    }
  }
}

.component-row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  grid-column-gap: 30px;
  grid-row-gap: 30px;

  > * {
    grid-column-end: span 12;

    &.half {
      grid-column-end: span 6;
    }

    &.third {
      grid-column-end: span 4;
    }

    &.quarter {
      grid-column-end: span 3;
    }
  }
}
</style>
