<template>
  <div class="category-view">
    <div class="category-title flex-row" @click="open = !open">
      <div class="category-icon"><i class="fa" :class="open ? 'fa-folder-open' : 'fa-folder'"></i></div>
      <div class="flex-1">
        <b> {{ category.name }} <i v-if="isDefault">(default folder) </i></b>
      </div>
      <div class="actions">
        <i class="fa fa-pencil" v-if="$listeners.edit" @click.stop="$emit('edit')"></i>
        <confirm-button v-if="$listeners.delete && this.model.length == 0" @confirm="$emit('delete')"
          ><i class="fa fa-trash"></i
        ></confirm-button>
      </div>
    </div>
    <div class="category-reports">
      <draggable :list="model" group="reports" ghost-class="report-ghost" @change="onOrderChange">
        <div class="report" v-if="open" v-for="report in model">{{ report.name }}</div>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import draggable from "vuedraggable";
import ConfirmButton from "../components/confirm-button.vue";
import MyReport from "../model/my-report";
import MyReportFolder from "../model/my-report-folder";
import MyReportsApiService from "../api/my-reports-api-service";
import _ from "lodash";

export default defineComponent({
  components: {
    draggable,
    ConfirmButton,
  },
  data() {
    const model: MyReport[] = null;

    return {
      model,
      open: true,
    };
  },
  computed: {
    myReportsService() {
      return new MyReportsApiService();
    },
  },
  created() {
    this.model = [...(this.reports || [])];
  },
  methods: {
    async onOrderChange() {
      const updatedMyReports = [];

      this.model = this.model.map((report, index) => {
        const updated = {
          ...report,
          sort_order: index + 1,
          my_report_folder_id: this.category?.id || null,
        };
        if (!_.isEqual(report, updated)) {
          updatedMyReports.push(updated);
        }
        return updated;
      });

      await Promise.all(updatedMyReports.map((myReport) => this.myReportsService.update(myReport)));

      this.$emit("reports-updated", this.model);
    },
  },
  props: {
    category: {
      type: Object as PropType<MyReportFolder>,
    },
    isDefault: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
    reports: {
      type: Array as PropType<MyReport[]>,
    },
  },
});
</script>

<style lang="scss" scoped>
.category-view {
  cursor: pointer;
  .category-title {
    padding: 5px;
    .category-icon {
      color: var(--color-yellow);
      width: 20px;
    }
  }
  .actions {
    i.fa:not(:last-child) {
      margin-right: 5px;
    }
  }
  .category-reports {
    padding-left: 25px;
  }

  .report {
    padding: 2px 0;
  }
}
</style>

<style lang="scss">
.report-ghost {
  opacity: 0.5;
  background-color: #ccc;
}
</style>
