<template>
  <uiv-modal v-if="team" v-model="open" :title="title">
    <div class="row" v-if="error">
      <div class="col-md-12">
        <div class="alert alert-danger">{{ error }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("activerecord.attributes.team.name") }}</h4>
        <input type="text" v-model="team.name" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("activerecord.attributes.team.identifier") }}</h4>
        <input type="text" v-model="team.identifier" placeholder="example-team" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("setup.teams.team_permissions") }}<span class="info-bubble" v-uiv-tooltip="permissionsTooltip"></span>
        </h4>
        <permissions-editor
          :permissions="team.permissions"
          default-permission="none"
          @update="updatePermissions"
        ></permissions-editor>
      </div>
    </div>
    <div class="row" v-if="restrictedDashboards && restrictedDashboards.length > 0">
      <div class="col-md-12">
        <h4>
          {{ $t("profile.allowed_restricted_dashboards")
          }}<span class="info-bubble" v-uiv-tooltip="allowedDashboardsTooltip"> </span>
        </h4>
        <div class="permissions-row">
          <allowed-reports
            :title="$t('dashboard_custom.dashboards')"
            :restricted-reports="restrictedDashboards"
            :allowed-reports="team.permitted_dashboards"
            @change="updatePermittedDashboards"
          ></allowed-reports>
        </div>
      </div>
    </div>
    <div class="row" v-if="restrictedReports && restrictedReports.length > 0">
      <div class="col-md-12">
        <h4>
          {{ $t("profile.allowed_restricted_reports")
          }}<span class="info-bubble" v-uiv-tooltip="allowedReportsTooltip"> </span>
        </h4>
        <div class="permissions-row">
          <allowed-reports
            :title="$t('analytics.reports')"
            :restricted-reports="restrictedReports"
            :allowed-reports="team.permitted_reports"
            @change="updatePermittedReports"
          ></allowed-reports>
        </div>
      </div>
    </div>
    <div class="row" v-if="newsletterDefinitions && newsletterDefinitions.length > 0">
      <div class="col-md-12">
        <h4>
          {{ $t("newsletter.subscription.list_title")
          }}<span class="info-bubble" v-uiv-tooltip="newsletterSubscriptionsTooltip"></span>
        </h4>
        <pill-list
          :items="newsletterSubscriptions"
          :available-items="newsletterDefinitions"
          :label="$t('profile.subscriptions')"
          sort="title"
          :togglable="false"
          :multi-select="true"
          @update="updateNewsletterSubscriptions"
        ></pill-list>
      </div>
    </div>
    <div slot="footer">
      <confirm-button
        class="pull-left"
        v-if="team.id"
        @confirm="deleteTeam"
        :button-title="$t('actions.delete')"
      ></confirm-button>
      <button class="btn" @click="cancel">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" type="submit" @click="save">{{ $t("actions.save") }}</button>
    </div>
  </uiv-modal>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import Team from "../model/team";
import adminTeamsApi from "../api/admin-teams-api";
import PermissionsEditor from "../permissions/permissions-editor.vue";
import AllowedReports from "../permissions/allowed_reports.vue";
import ConfirmButton from "../components/confirm-button.vue";
import i18n from "../i18n";
import adminNewsletterDefinitions from "../api/admin-newsletter-definitions-api";
import adminCompaniesApi from "../api/admin-companies-api";
import PillList from "../components/pill-list.vue";
import PillItem from "@/interfaces/pill-item";
import MenuItem from "@/interfaces/menu-item";

export default defineComponent({
  components: {
    PermissionsEditor,
    AllowedReports,
    ConfirmButton,
    PillList,
  },
  data() {
    const newsletterDefinitions: MenuItem[] = null;
    const newsletterSubscriptions: PillItem[] = [];
    const team: Team = null;

    return {
      team,
      newsletterSubscriptions,
      open: false,
      error: null,
      newsletterDefinitions,
      restrictedDashboards: null,
      restrictedReports: null,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    title() {
      return this.team?.id ? this.team.name : "New team";
    },
    permissionsTooltip() {
      return i18n.t("setup.teams.tooltips.permissions");
    },
    allowedDashboardsTooltip() {
      return i18n.t("setup.teams.tooltips.allowed_dashboards");
    },
    allowedReportsTooltip() {
      return i18n.t("setup.teams.tooltips.allowed_reports");
    },
    newsletterSubscriptionsTooltip() {
      return i18n.t("setup.teams.tooltips.newsletter_subscriptions");
    },
  },
  created() {
    adminNewsletterDefinitions.get({ company_id: this.companyId }).then((newsletterDefinitions) => {
      this.newsletterDefinitions = newsletterDefinitions.map(({ id, title }) => ({ id, name: title }));
    });
    adminCompaniesApi.getRestrictedDashboards({ id: this.companyId }).then((dashboards) => {
      this.restrictedDashboards = dashboards;
    });
    adminCompaniesApi.getRestrictedReports({ id: this.companyId }).then((reports) => {
      this.restrictedReports = reports;
    });
  },
  methods: {
    show(team: Team = null) {
      this.team = team || {
        id: null,
        name: "",
        identifier: "",
        permissions: null,
        permitted_dashboards: [],
        permitted_reports: [],
        newsletter_subscriptions: [],
      };
      this.newsletterSubscriptions = (this.team.newsletter_subscriptions || []).map(({ id, title }) => ({
        value: id,
        name: title,
        enabled: true,
      }));

      this.open = true;
    },
    async save() {
      try {
        const { id, name, identifier, permissions, permitted_dashboards, permitted_reports } = this.team;
        const attrs = {
          name,
          identifier,
          permissions,
          newsletter_definition_ids: this.newsletterSubscriptions.map(({ value }) => value),
          permitted_dashboard_ids: permitted_dashboards.map(({ id }) => id),
          permitted_report_ids: permitted_reports.map(({ id }) => id),
        };
        const action = id
          ? adminTeamsApi.put({ id, company_id: this.companyId, team: attrs })
          : adminTeamsApi.post({ company_id: this.companyId, team: attrs });

        const team = await action;
        this.$emit("save", team);
        this.open = false;
      } catch (error) {
        if (error.data && error.data.error) {
          this.error = error.data.error;
        } else {
          throw error;
        }
      }
    },
    cancel() {
      this.open = false;
    },
    async deleteTeam() {
      await adminTeamsApi.delete({ id: this.team.id, company_id: this.companyId });
      this.$emit("delete", this.team);
    },
    updatePermissions(permissions) {
      this.team.permissions = permissions;
    },
    updatePermittedReports(reports) {
      this.team.permitted_reports = reports;
    },
    updatePermittedDashboards(dashboards) {
      this.team.permitted_dashboards = dashboards;
    },
    updateNewsletterSubscriptions({ items }) {
      this.newsletterSubscriptions = items;
    },
  },
});
</script>
