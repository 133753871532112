<template>
  <b-modal
    id="password-modal"
    ref="passwordModal"
    no-fade
    lazy
    :ok-title="$t('actions.save')"
    :cancel-title="$t('actions.cancel')"
    :title="$t('profile.change_password')"
    title-tag="h4"
    @ok="handleOk"
    ok-variant="primary save"
    @show="show"
  >
    <b-alert variant="danger" v-if="error" show dismissible v-html="error"></b-alert>
    <spinner v-if="loading"></spinner>
    <div class="row">
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-md-4 control-label" for="current_password">{{
            $t("activerecord.attributes.user.current_password")
          }}</label>
          <div class="col-md-8">
            <input type="password" v-model="current_password" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 string required control-label" for="new_password">{{
            $t("devise.passwords.edit.new_password")
          }}</label>
          <div class="col-md-8">
            <input type="password" v-model="new_password" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="confirm_new_password">{{
            $t("devise.passwords.edit.confirm_new_password")
          }}</label>
          <div class="col-md-8">
            <input type="password" v-model="confirm_new_password" />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { changePassword } from "../lib/data/profile";
import { i18n } from "../lib/vue/filters/i18n";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import bAlert from "bootstrap-vue/es/components/alert/alert";
import spinner from "../components/spinner.vue";

export default {
  components: {
    spinner,
    "b-modal": bModal,
    "b-alert": bAlert,
  },
  directives: {
    "b-modal": bModalDirective,
  },
  data() {
    return {
      loading: false,
      error: null,
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    };
  },
  computed: {
    userInputs() {
      const email = window.zoinedContext.email;
      const parts = email.split(/[^\w]/);
      return [email, ...parts];
    },
  },
  methods: {
    handleOk: function(e) {
      e.preventDefault();

      let blank = i18n("errors.messages.blank");

      if (!this.current_password.length) {
        this.error = `${i18n("activerecord.attributes.user.current_password")} ${blank}`;
      } else if (!this.new_password.length) {
        this.error = `${i18n("devise.passwords.edit.new_password")} ${blank}`;
      } else if (!this.confirm_new_password.length) {
        this.error = `${i18n("devise.passwords.edit.confirm_new_password")} ${blank}`;
      } else {
        this.handleSubmit();
      }
    },
    handleSubmit: function() {
      let data = {
        current_password: this.current_password,
        password: this.new_password,
        password_confirmation: this.confirm_new_password,
      };

      this.loading = true;

      changePassword(data)
        .then((res) => {
          this.$root.$emit("getAlert", res.success);
          this.$refs.passwordModal.hide();
          this.error = null;
          this.current_password = "";
          this.new_password = "";
          this.confirm_new_password = "";
          this.loading = false;
        })
        .catch((error) => {
          this.error = error.message;
          this.loading = false;
        });
    },
    show: function() {
      this.loading = false;
    },
  },
  mounted: function() {
    this.loading = true;
  },
};
</script>
