<template>
  <div
    class="active-filters-badge flex-row gap-sm align-items-center bg-surface-neutral-1 rounded-full px-lg py-sm font-size-sm"
    :class="{
      'fg-text-emphasis': type === 'include',
      'fg-semantic-negative-text': type === 'exclude',
    }"
    v-uiv-tooltip="itemsText"
    @click="emit('click')"
  >
    <div v-if="printLabel" class="font-bold hidden visible-print mr-sm">{{ printLabel }}</div>
    <div v-if="type === 'include'" class="icon-container hidden print-flex-row">
      <icon icon="fluent:add-16-filled" />
    </div>
    <div v-if="type === 'exclude'" class="icon-container hidden print-flex-row">
      <icon icon="fluent:subtract-16-filled" />
    </div>
    <div v-if="type === 'range'" class="icon-container">
      <icon icon="fluent:arrow-fit-16-filled" />
    </div>
    <div class="items-text">{{ itemsText }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Icon } from "@iconify/vue2";

export type ActiveFiltersBadgeProps = {
  items: string[];
  type: "include" | "exclude" | "range";
  separator?: string;
  printLabel?: string;
};

const props = defineProps<ActiveFiltersBadgeProps>();

const emit = defineEmits(["click"]);

const itemsText = computed(() => props.items.join(props.separator || ", "));
</script>

<style scoped lang="scss">
$grid-float-breakpoint: 992px;

.active-filters-badge {
  @media screen {
    max-width: 300px;
  }

  @media print {
    border: 1px solid black;
  }
}

.icon-container {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  font-size: 16px;
  color: var(--color-text-emphasis);
}

.items-text {
  @media screen {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
