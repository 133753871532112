<template>
  <div class="important-messages" v-if="messages && messages.length > 0">
    <div class="alert panel panel-snippet" v-for="message in messages">
      <div class="panel-heading">
        <h2>{{ title(message) }}</h2>
      </div>
      <div class="panel-body" v-html="messageHtml(message)"></div>
      <div class="panel-body">
        <button class="btn btn-default btn-sm" @click="markAsRead(message)">Do not show this message again</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import MessagesApiService from "../api/messages-api-service";
import sanitizeHtml from "sanitize-html";

export default defineComponent({
  data() {
    return {
      messages: null,
    };
  },
  created() {
    this.fetchMessages();
  },
  methods: {
    title(message) {
      return message.title;
    },
    messageHtml(message) {
      return sanitizeHtml(message.message);
    },
    fetchMessages() {
      new MessagesApiService().getActiveMessages().then((messages) => (this.messages = messages));
    },
    markAsRead(message) {
      new MessagesApiService().markMessageViewed(message.id);

      this.messages = this.messages.filter((each) => each !== message);
    },
  },
});
</script>

<style lang="scss" scoped>
.panel-snippet {
  > .panel-heading {
    border-color: transparent;
  }
  > .panel-body {
    button {
      float: right;
    }
  }
}
</style>
