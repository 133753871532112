export default class ErrorReportingService {
  error(msg, payload) {
    if (window.Rollbar) {
      window.Rollbar.error(msg, payload);
    }
    console.error(msg, payload);
  }

  warning(msg, payload) {
    if (window.Rollbar) {
      window.Rollbar.warning(msg, payload);
    }
    console.warn(msg, payload);
  }

  info(msg, payload) {
    if (window.Rollbar) {
      window.Rollbar.info(msg, payload);
    }
    console.log(msg, payload);
  }
}
