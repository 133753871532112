<template>
  <div class="sidenav-search-box">
    <svg-icon name="search"></svg-icon>
    <input
      type="text"
      :placeholder="$t('actions.search')"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <svg-icon v-if="value.length > 0" name="search-close" @click.native="$emit('input', '')"></svg-icon>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import SvgIcon from "../components/svg-icon.vue";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    value: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-search-box {
  height: 40px;
  background-color: var(--color-sidenav-search-box-background);
  border: 1.5px solid var(--color-sidenav-search-box-border);
  border-radius: var(--border-radius-sm);
  margin: 0 15px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  input[type="text"] {
    color: var(--color-sidenav-search-box-text);
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
