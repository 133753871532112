<template>
  <div
    ref="aiChat"
    class="ai-chat flex-1 fg-text-variant flex-column gap-xxl min-h-0 overflow-y-scroll scrollbar-hidden"
  >
    <div>{{ t("ai_assistant.chat_info_text") }}</div>
    <ai-assistant-lottery
      v-if="aiMessages.length == 0"
      @create-message="emit('create-message', $event)"
    ></ai-assistant-lottery>
    <div class="messages flex-column gap-xl">
      <ai-message-view :ai-message="aiMessage" v-for="aiMessage in aiMessages" :key="aiMessage.id"></ai-message-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import { computed, ref, watch } from "vue";
import AiMessageView from "@/ai-assistant/ai-message-view.vue";
import aiAssistantLottery from "@/ai-assistant/ai-assistant-lottery.vue";
import { useI18n } from "@/composables/i18n";

const emit = defineEmits(["create-message"]);

const { t } = useI18n();

const store = useStore();

const aiChat = ref<HTMLElement | null>(null);

const sessionId = computed(() => store.state.aiAssistant.session_id);

const aiMessages = computed(() => store.state.aiAssistant.messages);

watch(
  sessionId,
  (id) => {
    if (!id) {
      aiChat.value?.scrollTo(0, 0);
    }
  },
  {
    immediate: true,
  }
);
</script>

<style scoped lang="scss">
.ai-chat {
  padding: 10px 15px;

  @media screen and (min-width: 992px) {
    margin: 0 -5px;
    padding: 10px 5px;
  }
}

.messages {
  @media screen and (min-width: 992px) {
    min-height: 100%;
  }
}
</style>
