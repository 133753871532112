<template>
  <div class="edit-custom-report-page" v-if="loaded">
    <div class="mt-md"></div>
    <custom-report-editor ref="editor" :default-report="report" :default-my-report="myReport"></custom-report-editor>
  </div>
  <spinner v-else></spinner>
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue, { defineComponent, PropType } from "vue";
import Report from "../model/report";
import MyReport from "../model/my-report";
import CustomReportEditor from "./custom-report-editor.vue";
import spinner from "../components/spinner.vue";
import Mutations from "../store/mutations";
import ReportContext from "../model/report-context";
import { makeApiInstance } from "../api/instance";
import _ from "lodash";
import FilterConfiguration from "../model/filter-configuration";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";

export default defineComponent({
  components: { CustomReportEditor, spinner },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    this.handleRouteChange(next);
  },
  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    this.handleRouteChange(next);
  },
  data() {
    const myReport: MyReport = null;
    const report: Report = null;

    return {
      report,
      myReport,
    };
  },
  computed: {
    title() {
      if (this.id) {
        return this.myReport.name;
      } else {
        return i18n.t("custom_report.new_report");
      }
    },
    loaded() {
      return this.report && this.myReport;
    },
    editor: {
      cache: false,
      get() {
        return this.$refs["editor"] as CustomReportEditor;
      },
    },
  },
  async created() {
    if (this.id) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_id: this.id } })
        .then((response) => response.data);
      this.report = reportContext.report;
      this.myReport = reportContext.my_report;
    } else if (this.templateId) {
      const { title, config } = await makeApiInstance()
        .get(`/api/v1/analytics_templates/${this.templateId}`)
        .then((response) => response.data);
      this.report = {
        metadata: {
          title,
        },
        config,
      } as Report;
      this.myReport = {
        user_id: window.zoinedContext.userId,
        filters: _.cloneDeep(config.filterConfiguration),
      } as MyReport;
    } else if (this.duplicateId) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_id: this.duplicateId } })
        .then((response) => response.data);
      const { metadata, config } = reportContext.report;
      this.report = { metadata, config } as Report;
      this.report.metadata.title = `Copy of ${this.report.metadata.title}`;
      this.myReport = {
        dashboard: false,
        user_id: window.zoinedContext.userId,
        filters: reportContext.filter_configuration,
      } as MyReport;
    } else {
      this.report = {
        metadata: {
          title: "",
        },
        config: {
          components: [],
        },
      } as Report;
      this.myReport = {
        user_id: window.zoinedContext.userId,
      } as MyReport;
    }

    // Overrides
    if (this.config?.filter_configuration) {
      this.myReport.filters = this.config.filter_configuration;
    }
    if (this.config?.chart_options) {
      this.myReport.control_state = this.config.chart_options;
    }
    if (this.config?.table_config) {
      this.myReport.table_config = this.config.table_config;
    }

    this.$store.commit(Mutations.setTitle, this.title);
  },
  methods: {
    beforeRouteUpdate(to, from, next) {
      this.handleRouteChange(next);
    },
    beforeRouteLeave(to, from, next) {
      this.handleRouteChange(next);
    },
    handleRouteChange(next) {
      let confirmed = true;

      if (this.editor?.hasChanges) {
        confirmed = confirm(i18n.t("confirm.unsaved_changes_confirmation"));
      }

      if (confirmed) {
        this.$store.commit(Mutations.clearTitle);
        next();
      } else {
        next(false);
      }
    },
  },
  props: {
    id: {
      type: String,
    },
    templateId: {
      type: String,
    },
    duplicateId: {
      type: String,
    },
    config: {
      type: Object as PropType<{
        filter_configuration?: FilterConfiguration;
        chart_options?: ChartOptions;
        table_config?: TableConfig;
      }>,
    },
  },
});
</script>
