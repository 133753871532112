<template>
  <pill-list :title="title" :items="items" :availableItems="menuItems" @update="onUpdate"></pill-list>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import pillList from "../components/pill-list.vue";
import MenuItem from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";

export default defineComponent({
  components: {
    pillList,
  },
    computed: {
        items(): PillItem[] {
            return this.allowedReports.map(({ id, name }) => ({ value: id, name }));
        },
        menuItems(): MenuItem[] {
            return this.restrictedReports.map(({ id, name }) => ({ key: id, name }));
        }
    },
    methods: {
        onUpdate({ items }) {
            this.$emit(
              "change",
              items.map(({ value, name }) => ({ id: value, name }))
            );
        }
    },
    props: {
        title: {
            type: String
        },
        restrictedReports: {
            type: Object as PropType<Array<any>>
        },
        allowedReports: {
            type: Object as PropType<Array<any>>
        }
    }
})

</script>
