import { render, staticRenderFns } from "./newsletter-templates-list.vue?vue&type=template&id=639f0a7a&scoped=true"
import script from "./newsletter-templates-list.vue?vue&type=script&lang=ts"
export * from "./newsletter-templates-list.vue?vue&type=script&lang=ts"
import style0 from "./newsletter-templates-list.vue?vue&type=style&index=0&id=639f0a7a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639f0a7a",
  null
  
)

export default component.exports