<template>
  <calendar
    is-expanded
    :class="{ 'hide-year-nav': hideYearNav }"
    :attributes="customCalendarAttributes"
    :first-day-of-week="firstDayOfWeek + 1"
    :locale="locale"
    :from-date="fromDate"
    :min-date="minDate"
    :max-date="maxDate"
    @dayclick="onDayClick"
  >
    <template v-slot:header-title="headerProps">
      <slot name="title" v-bind="headerProps">{{ headerProps.title }}</slot>
    </template>
  </calendar>
</template>

<script lang="ts">
import Calendar from "v-calendar/lib/components/calendar.umd";
import Vue, { defineComponent, PropType } from "vue";
import i18n from "../i18n";
import moment from "moment";

export default defineComponent({
  inject: ["dropdown"],
  components: {
    Calendar,
  },
  data() {
    return {
      rangeStartDate: null,
      firstDayOfWeek: window.zoinedContext.firstDayOfWeek,
      locale: i18n.locale,
    };
  },
  computed: {
    customCalendarAttributes() {
      return [
        {
          highlight: true,
          dates: this.rangeStartDate,
        },
      ];
    },
    fromDate() {
      if (this.maxDate && moment().isAfter(this.maxDate)) {
        return this.minDate || this.maxDate;
      }
    },
    hideYearNav() {
      return this.minDate && this.maxDate && this.minDate?.getFullYear() === this.maxDate?.getFullYear();
    },
  },
  mounted() {
    this.rangeStartDate = null;
  },
  methods: {
    onDayClick({ date }) {
      if (this.mode === "range") {
        if (this.rangeStartDate) {
          this.$emit("select-custom-range", [this.rangeStartDate, date]);
          this.rangeStartDate = null;
          this.dropdown?.close();
        } else {
          this.rangeStartDate = date;
        }
      } else {
        this.$emit("select-date", date);
        this.dropdown?.close();
      }
    },
  },
  props: {
    minDate: { default: null, type: Object as PropType<Date> },
    maxDate: { default: null, type: Object as PropType<Date> },
    mode: { default: "single", type: Object as PropType<"single" | "range"> },
  },
});
</script>

<style lang="scss" scoped>
.hide-year-nav::v-deep {
  .vc-nav-container > .vc-nav-header {
    display: none;
  }
}
</style>
