<template>
  <b-modal
    id="email-modal"
    ref="emailModal"
    no-fade
    lazy
    :ok-title="$t('actions.save')"
    :cancel-title="$t('actions.cancel')"
    :title="$t('profile.change_email')"
    title-tag="h4"
    @ok="handleOk"
    ok-variant="primary save"
    @show="show"
  >
    <b-alert variant="danger" v-if="error" show dismissible>{{ error }}</b-alert>
    <spinner v-if="loading"></spinner>
    <div class="row" v-else>
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-md-4 control-label" for="email">{{ $t("activerecord.attributes.user.email") }}</label>
          <div class="col-md-8">
            <input type="email" v-model="email" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="current_password">{{
            $t("activerecord.attributes.user.current_password")
          }}</label>
          <div class="col-md-8">
            <input type="password" v-model="current_password" />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import bAlert from "bootstrap-vue/es/components/alert/alert";
import { changeEmail } from "../lib/data/profile";

import spinner from "../components/spinner.vue";

export default {
  components: {
    spinner,
    "b-modal": bModal,
    "b-alert": bAlert,
  },
  directives: {
    "b-modal": bModalDirective,
  },
  data() {
    return {
      loading: false,
      current_password: "",
      email: "",
      error: null,
    };
  },
  methods: {
    handleOk: function(e) {
      e.preventDefault();
      this.handleSubmit();
    },
    handleSubmit: function() {
      let data = {
        email: this.email,
        current_password: this.current_password,
      };

      this.loading = true;

      changeEmail(data)
        .then((res) => {
          this.$root.$emit("getAlert", res.success);
          this.$root.$emit("fetchData");
          this.$refs.emailModal.hide();
          this.email = "";
          this.current_password = "";
          this.error = null;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error.message;
          this.email = "";
          this.current_password = "";
          this.loading = false;
        });
    },
    show: function() {
      this.loading = false;
    },
  },
  mounted: function() {
    this.loading = true;
  },
};
</script>
