<template>
  <div class="newsletter-definitions-page">
    <div class="newsletter-definitions">
      <div class="pull-right">
        <mass-send-newsletters-button></mass-send-newsletters-button>
        <new-newsletter-button
          :enable-templates="true"
          @new-newsletter="onNewNewsletter"
          @new-newsletter-from-template="onNewNewsletterFromTemplate"
        ></new-newsletter-button>
      </div>
      <div class="table-controls">
        <div class="input-group">
          <span class="input-group-addon"> <div class="fa fa-search"></div></span>
          <input
            class="form-control"
            id="search"
            type="text"
            v-model="search"
            :placeholder="$t('company_admin.actions.search_for_name')"
          />
        </div>
      </div>
      <spinner v-if="!definitions"></spinner>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t("newsletter.title") }}</th>
            <th>{{ $t("newsletter.description") }}</th>
            <th>{{ $t("newsletter.definition.type") }}</th>
            <th>{{ $t("newsletter.definition.frequency") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="definitions && definitions.length == 0">
            <td colspan="6">{{ $t("table.no_results") }}</td>
          </tr>
          <tr v-for="definition in currentPageItems">
            <td>{{ definition.title }}</td>
            <td>{{ definition.description }}</td>
            <td>{{ $t(definition.newsletterType == "alert" ? "newsletter.type.alert" : "newsletter.type.custom") }}</td>
            <td>{{ $t("newsletter.frequency." + definition.frequency) }}</td>
            <td class="actions">
              <confirm-button class="mr-sm" @confirm="deliverDefinition(definition)">
                <a>{{ $t("actions.deliver") }}</a></confirm-button
              >
              <router-link v-if="definition.editable" :to="'newsletter_definitions/' + definition.id + '/edit'">{{
                $t("actions.edit")
              }}</router-link>
              <router-link :to="'newsletter_definitions/new?duplicateId=' + definition.id">{{
                $t("actions.duplicate")
              }}</router-link>
              <confirm-button v-if="definition.editable" @confirm="deleteDefinition(definition)"
                ><a>{{ $t("actions.delete") }}</a></confirm-button
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div class="centered" v-if="definitions">
        <uiv-pagination v-model="page" :total-page="totalPages" size="sm"></uiv-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ZoinedContext from "../model/zoined-context";
import Vue, { defineComponent } from "vue";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import spinner from "../components/spinner.vue";
import newNewsletterButton from "./new-newsletter-button.vue";
import _ from "lodash";
import confirmButton from "../components/confirm-button.vue";
import toaster from "toastr";
import i18n from "../i18n";
import MassSendNewslettersButton from "./mass-send-newsletters-button.vue";

export default defineComponent({
  components: {
    spinner,
    newNewsletterButton,
    confirmButton,
    MassSendNewslettersButton,
  },
  data() {
    const definitions: any[] = null;

    return {
      search: "",
      definitions,
      page: 1,
    };
  },
  computed: {
    zoinedContext(): ZoinedContext {
      return window.zoinedContext;
    },
    companyId() {
      return this.zoinedContext.companyId;
    },
    pageSize() {
      return 15;
    },
    totalPages() {
      return Math.ceil(this.definitions.length / this.pageSize);
    },
    currentPageItems() {
      const start = (this.page - 1) * this.pageSize;
      return this.definitions?.slice(start, start + this.pageSize);
    },
  },
  created() {
    this.fetchDefinitions();
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
    onNewNewsletter() {
      this.$router.push("newsletter_definitions/new");
    },
    onNewNewsletterFromTemplate(id) {
      this.$router.push("newsletter_definitions/new?templateId=" + id);
    },
    deliverDefinition(definition) {
      newsletterDefinitionsApi.deliver({ company_id: this.companyId, id: definition.id }).then(() => {
        toaster.success(i18n.t("newsletter_subscription.scheduled_for_delivery"));
      });
    },
    async deleteDefinition(definition) {
      const params = { company_id: this.companyId, id: definition.id };
      await newsletterDefinitionsApi.delete(params);
      this.fetchDefinitions();
    },
    async fetchDefinitions() {
      this.definitions = null;
      const params: any = { company_id: this.companyId };
      if (this.search?.length > 0) {
        params.q = this.search;
      }
      this.definitions = await newsletterDefinitionsApi.get(params);
    },
  },
  watch: {
    search: _.debounce(function() {
      this.fetchDefinitions();
    }, 300),
  },
});
</script>

<style lang="scss" scoped>
h3 {
  margin: 20px 0;
}

.table-controls {
  margin-bottom: 10px;
}

table.table {
  margin-bottom: 0;

  td.actions {
    a:not(:last-child) {
      margin-right: 5px;
    }

    a {
      cursor: pointer;
    }
  }
}
</style>
