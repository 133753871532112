<template>
  <div class="company-admin">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
export default defineComponent({
  computed: {
    navigationItems() {
      const category = this.$store.state.navigation?.data.settings?.find(
        (category) => category.id === "company_settings"
      );
      const folders = category?.folders || [];
      const reports = folders.map((folder) => folder.reports).flat();
      return reports;
    },
  },
});
</script>
