import { render, staticRenderFns } from "./limit-selector.vue?vue&type=template&id=599a776e&scoped=true"
import script from "./limit-selector.vue?vue&type=script&lang=ts"
export * from "./limit-selector.vue?vue&type=script&lang=ts"
import style0 from "./limit-selector.vue?vue&type=style&index=0&id=599a776e&prod&lang=scss&scoped=true"
import style1 from "./limit-selector.vue?vue&type=style&index=1&id=599a776e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599a776e",
  null
  
)

export default component.exports