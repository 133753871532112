<template>
  <uiv-modal :title="$t('public_share.generate_filtered_links')" v-model="open" :append-to-body="true">
    <div v-if="links">
      <textarea class="form-control links-area" rows="5" readonly="readonly">{{ linksText }}</textarea>
      <div class="mt-md">
        <a class="cursor-pointer" @click="copyLinks()">{{ $t("public_share.copy_links") }}</a>
      </div>
    </div>
    <spinner v-else-if="generating"></spinner>
    <div v-else>
      <label>{{ $t("public_share.select_filter") }}</label>
      <select class="form-control" v-model="filter">
        <option v-for="filter in filterItems" :value="filter.id">{{ filter.name }}</option>
      </select>
      <div class="mt-md" v-if="selectedFilter">
        <filter-selector
          :config="config"
          :filter="selectedFilter"
          :excludable="false"
          @update="onUpdateFilterConfig"
        ></filter-selector>
      </div>
    </div>
    <div slot="footer">
      <div v-if="links">
        <button class="btn btn-default" @click="open = false">{{ $t("actions.close") }}</button>
      </div>
      <div v-else-if="!generating">
        <button class="btn btn-default" @click="open = false">{{ $t("actions.cancel") }}</button>
        <button class="btn btn-primary" @click="generateLinks()" :disabled="!valid">
          {{ $t("public_share.generate_links") }}
        </button>
      </div>
    </div>
  </uiv-modal>
</template>

<script lang="ts">
import _ from "lodash";
import { makeApiInstance } from "@/api/instance";
import Filter from "@/model/filter";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import MyReport from "@/model/my-report";
import Report from "@/model/report";
import Vue, { defineComponent, PropType } from "vue";
import FilterSelector from "../filter-selector.vue";
import Spinner from "../spinner.vue";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import i18n from "@/i18n";
import toastr from "toastr";

export default defineComponent({
  components: {
    FilterSelector,
    Spinner,
  },
  data() {
    const links: Record<string, string> = null;
    const config: FilterConfigurationItemMap = null;
    const filter: string = null;
    const generating: boolean = false;
    const open: boolean = false;

    return {
      open,
      generating,
      filter,
      config,
      links,
    };
  },
  computed: {
    filterItems(): Filter[] {
      return this.$store.getters.getParameters("filters");
    },
    selectedFilter(): Filter {
      return this.filterItems?.find((f) => f.id === this.filter);
    },
    valid() {
      return this.config && !_.isEmpty(this.config);
    },
    linksText() {
      return _.map(this.links, (link, name) => `${name}: ${link}`).join("\n");
    },
  },
  methods: {
    show() {
      this.filter = this.filterItems?.find((f) => f.id === "store") ? "store" : this.filterItems[0]?.id;
      this.config = {};
      this.links = null;
      this.generating = false;
      this.open = true;
    },
    onUpdateFilterConfig(config: FilterConfigurationItemMap) {
      console.log(config);
      this.config = config;
    },
    async generateLinks() {
      try {
        this.generating = true;

        const links = await Promise.all(
          _.map(this.config, async (item) => {
            const name = item.name || item.value;
            const filterConfiguration: FlatFilterConfiguration = {
              [this.filter]: { [item.value]: item },
            };
            const config = { filter_configuration: filterConfiguration };
            const link = await this.fetchLink(config);
            return [name, link];
          })
        );
        this.links = _.fromPairs(links);
      } finally {
        this.generating = false;
      }
    },
    fetchLink(config) {
      const url = `/api/v1/reports/${this.report.id}/filtered_link`;
      const params: any = {};
      if (this.myReport?.id) {
        params.my_report_id = this.myReport.id;
      }
      const data: any = { config };
      return makeApiInstance()
        .post(url, data, { params })
        .then((response) => response.data)
        .then(({ link }) => link);
    },
    copyLinks() {
      navigator.clipboard.writeText(this.linksText);
      toastr.success(i18n.t("public_share.links_copied_to_clipboard"));
    },
    onFilterChanged() {
      this.config = {};
    },
  },
  props: {
    report: {
      type: Object as PropType<Report>,
    },
    myReport: {
      type: Object as PropType<MyReport>,
    },
  },
  watch: {
    filter: [
      {
        handler: "onFilterChanged",
      },
    ],
  },
});
</script>

<style lang="scss" scoped>
.links-area {
  width: 100%;
  max-width: none;
  resize: none;
}
</style>
