<template>
  <spinner v-if="loading"></spinner>
  <div v-else>
    <h4 class="mt-0">{{ $t("profile.my_top_reports") }}</h4>
    <div class="panel">
      <div class="panel-body">
        <div class="profile-reports">
          <ol v-if="hasReports">
            <li v-for="report in reports">
              <a v-bind:href="'analytics/report/' + report.name">{{ $t(`report.${report.name}.title`) }}</a>
            </li>
          </ol>
          <p v-else>{{ $t("no_records") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import spinner from "../components/spinner.vue";

export default {
  components: {
    spinner,
  },
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted: function() {
    this.loading = !!this.reports.length;
  },
  computed: {
    hasReports: function() {
      return !!this.reports.length;
    },
    linkTo: function(name) {
      return `/analytics/reports/${name}`;
    },
  },
};
</script>
