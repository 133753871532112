<template>
  <div class="legends" :class="`span-${span}`">
    <div class="selection-section" v-if="selection">
      <div class="flex-row align-items-center legend-item">
        <i class="symbol" :class="selectionClass(selection)"></i
        ><span class="daterange">{{ dateRange(selection) }}:</span
        ><span class="description">{{ selectionTitle(selection) }} </span
        ><span class="detail" v-if="selection.type == 'today_until_last_full_hour'">(0:00 - {{ lastFullHour }}:00)</span
        ><span class="detail" v-if="selection.type == 'last_full_hour'"
          >({{ secondLastFullHour }}:00 - {{ lastFullHour }}:00)</span
        >
      </div>
    </div>
    <div class="comparisons-section" v-if="comparisons && comparisons.length > 0">
      <div
        class="flex-row align-items-center legend-item"
        v-show="comparisonShow(comparison)"
        v-for="comparison in comparisons"
      >
        <i class="symbol" :class="comparisonClass(comparison)" :style="{ filter: comparisonFilter(comparison) }"></i
        ><span class="daterange" v-show="comparison.start_time">{{ dateRange(comparison) }}:</span
        ><span class="description">{{ comparisonTitle(comparison) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TranslationService from "../core/translation.service";
import daterangeFilter from "../core/daterange.filter";
import moment from "moment";

const dateRange = daterangeFilter();
const translationService = new TranslationService();

export default {
  props: {
    selection: {
      type: Object,
      required: false,
    },
    comparisons: {
      type: Array,
      required: false,
    },
    showSymbol: {
      type: Boolean,
      default: true,
    },
    span: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    lastFullHour() {
      let time = moment();
      if (window.zoinedContext.companyTimeZone) {
        time = time.tz(window.zoinedContext.companyTimeZone);
      }
      return time.format("HH");
    },
    secondLastFullHour() {
      let time = moment().subtract(1, "hour");
      if (window.zoinedContext.companyTimeZone) {
        time = time.tz(window.zoinedContext.companyTimeZone);
      }
      return time.format("HH");
    },
  },
  methods: {
    dateRange,

    selectionTitle(selection) {
      return translationService.selectionTitle(selection);
    },

    selectionClass(selection) {
      const { series } = selection;
      const type = !series || series == "actual" ? "selection" : `budget-${series}`;
      return `bg-${type}`;
    },

    comparisonTitle(comparison) {
      return translationService.comparisonTitle(comparison);
    },

    comparisonClass(comparison) {
      return "bg-" + [comparison.type, comparison.label].filter(Boolean).join("-");
    },

    comparisonShow(comparison) {
      return comparison.type && comparison.type != "no_comparison" && comparison.enabled;
    },

    comparisonFilter(comparison) {
      if (comparison.scaling_modifier) {
        const percent = Math.min(Math.max((Math.round(comparison.scaling_modifier * 100) - 100) / 2 + 100, 90), 110);
        return `brightness(${percent}%)`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$grid-float-breakpoint: 992px;

.legends {
  background-color: var(--color-surface-neutral-1);
  color: var(--color-text-primary);
  padding: 5px 0;
  border-radius: 5px;

  max-width: 100%;
  white-space: nowrap;

  font-weight: 400;
  font-size: 14px;

  @media (min-width: $grid-float-breakpoint) {
    font-size: 13px;
    background-color: transparent;
  }

  .legend-item {
    overflow: hidden;
    padding-right: 20px;

    > *:last-child {
      flex-shrink: 1;
      /* Allow the child to shrink if necessary */
      white-space: nowrap;
      /* Prevent text from wrapping to the next line */
      overflow: hidden;
      /* Hide the overflowing text */
      text-overflow: ellipsis;
      /* Add ellipsis to the overflowing text */
      min-width: 0;
      /* Necessary for some browsers to correctly apply ellipsis */
    }
  }

  .comparisons-section {
    .legend-item {
      margin-top: 5px;
    }
  }

  i.symbol {
    width: 2px;
    height: 18px;
    margin-right: 5px;
    border: none;
    flex-shrink: 0;

    @media (min-width: $grid-float-breakpoint) {
      width: 4px;
    }
  }

  .daterange {
    padding-right: 5px;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.span-3 {
    @media (min-width: 768px) {
      .daterange {
        display: none;
      }
    }
  }

  &.span-4 {
    @media (min-width: 768px) and (max-width: 991px) {
      .daterange {
        display: none;
      }
    }
  }
}
</style>
