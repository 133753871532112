<template>
  <div class="progress-bar">
    <div class="progress-indicator" :style="{ width }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  progress: number;
}>();

const width = computed(() => `${props.progress * 100}%`);
</script>

<style scoped>
.progress-bar {
  background-color: var(--color-surface-neutral-2);
  width: 100%;
  height: 4px;
  padding: 1px;
  border-radius: 2px;
}

.progress-indicator {
  background: linear-gradient(90deg, var(--color-border-emphasis-variant) 0%, var(--color-border-emphasis) 100%);
  height: 2px;
  border-radius: 1px;
  transition: width 0.1s;
}
</style>
