<template>
  <div class="currency-selector">
    <dropdown v-if="availableCurrencies.length > 1" menu-right v-model="open">
      <div class="toggle">
        <navbar-dropdown-button
          :title="$t('activerecord.attributes.user.currency')"
          :subtitle="currentCurrency.currency"
        >
          <template slot="icon">
            <div class="currency-icon">{{ currentCurrency.symbol }}</div>
          </template>
        </navbar-dropdown-button>
      </div>
      <template slot="dropdown">
        <li v-for="currency in availableCurrencies">
          <a class="currency-switch" @click="switchCurrency(currency)">{{ currencyTitle(currency) }}</a>
        </li>
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import i18n from "../i18n";
import { Dropdown } from "uiv";
import { postForm } from "../lib/form";
import Currency from "../model/currency";
import NavbarDropdownButton from "../components/navbar-dropdown-button.vue";
import { showOverlay } from "@/ui/overlay";

export default defineComponent({
  components: {
    Dropdown,
    NavbarDropdownButton,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    currentCurrency() {
      return window.zoinedContext.currency;
    },
    availableCurrencies() {
      return window.zoinedContext.currencies || [];
    },
  },
  methods: {
    currencyTitle(currency: Currency) {
      return `${currency.currency} (${currency.symbol})`;
    },
    toggleOpen() {
      console.log("toggle");
      this.open = !this.open;
    },
    switchCurrency(currency: Currency) {
      this.open = false;
      showOverlay(i18n.t("please_wait"));
      postForm(`/switch_currency/${currency.currency}`);
    },
  },
});
</script>
<style lang="scss">
.currency-selector {
  .dropdown-menu {
    min-width: initial;

    li + li {
      border-top: 1px solid #eee;
    }
  }

  .currency-icon {
    font-size: 16px;
  }
}
</style>
