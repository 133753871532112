<template>
  <pill-list :items="items" :available-items="availableItems" @update="onUpdate"></pill-list>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import pillList from "./pill-list.vue";

export default defineComponent({
  props: {
    value: Array,
    onlyIds: Array,
  },
  components: {
    pillList,
  },
  computed: {
    allMetrics() {
      return this.$store.getters.getParameters("metrics");
    },
    items() {
      return (
        this.value &&
        this.value.map(({ id, enabled }) => ({
          value: id,
          enabled: !!enabled,
        }))
      );
    },
    availableItems() {
      let metrics = this.allMetrics;
      if (metrics && this.onlyIds) {
        metrics = metrics.filter(({ id }) => this.onlyIds.includes(id));
      }
      return metrics;
    },
  },
  methods: {
    onUpdate({ items }) {
      this.$emit(
        "input",
        items.map(({ value, enabled }) => ({ id: value, enabled }))
      );
    },
  },
});
</script>
