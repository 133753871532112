<template>
  <div class="flex-row align-items-center flex-wrap gap-sm">
    <pill-button
      :enabled="!obj.config.invisible"
      :removable="false"
      v-for="obj in collection"
      :key="obj.key"
      @toggle="editObject(obj)"
      >{{ obj.label }} &nbsp;&nbsp;<i class="fa fa-edit"></i
    ></pill-button>
    <metric-dialog ref="metricDialog" :type="type" @save="onSave"></metric-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import _ from "lodash";
import MetricDialog from "./metric-dialog.vue";
import pillButton from "@/components/pill-button.vue";

export default defineComponent({
  components: {
    MetricDialog,
    pillButton,
  },
  computed: {
    collection() {
      return _.sortBy(
        _.map(this.objects, (v, k) => {
          return { key: k, label: v.label[this.locale] || k, config: v };
        }),
        "label"
      );
    },
    metricDialog: {
      cache: false,
      get() {
        return this.$refs["metricDialog"] as MetricDialog;
      },
    },
  },
  methods: {
    editObject(obj) {
      this.metricDialog.show(obj);
    },
    onSave(obj) {
      this.$emit("save", obj);
    },
  },
  props: {
    objects: {
      type: Object,
    },
    locale: {
      type: String,
    },
    type: {
      type: String,
    },
  },
});
</script>
