<template>
  <div class="basket-filters" v-if="items">
    <div class="title">{{ $t("filters.basket_filters") }}</div>
    <div class="edit flex-row align-items-center flex-wrap gap-lg column-gap-xxl pb-lg" v-if="edit">
      <filter-selector
        :config="value[filter.id]"
        :filter="filter"
        @update="updateFilter(filter.id, $event)"
        v-for="filter in items"
        :key="filter.id"
      ></filter-selector>
    </div>
    <div class="view" v-else>
      <span class="title mr-md" v-if="currentFiltersText">{{ currentFiltersText }}</span>
      <button class="btn btn-primary btn-sm" @click="edit = true">{{ $t("actions.edit") }}</button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import FilterSelector from "../components/filter-selector.vue";
import _ from "lodash";

export default defineComponent({
  components: {
    FilterSelector,
  },
  data() {
    return {
      edit: false,
    };
  },
  computed: {
    selectedItems() {
      return _.compact(
        Object.keys(this.value).map((key) => {
          const itemMap = this.value[key];
          let items = _.compact(
            _.map(itemMap, ({ name, value, enabled, exclude }) => {
              if (!enabled) {
                return null;
              } else {
                return (exclude ? "-" : "") + (name || value);
              }
            })
          );
          if (key == "hour") {
            items.sort();
          }
          if (items.length > 0) {
            return `${this.filterName(key)}: ${items.join(", ")}`;
          } else {
            return null;
          }
        })
      );
    },
    currentFiltersText() {
      if (_.isEmpty(this.selectedItems)) {
        return null;
      } else {
        return this.selectedItems.join(", ");
      }
    },
  },
  methods: {
    filterName(key) {
      return this.items.find((item) => menuItemKey(item) === key)?.name || key;
    },
    updateFilter(key, config) {
      this.$emit("input", { ...this.value, [key]: config });
    },
  },
  props: {
    value: { default: () => ({}), type: Object as PropType<Record<string, FilterConfigurationItemMap>> },
    items: {
      type: Array as PropType<MenuItem[]>,
    },
  },
});
</script>

<style lang="scss" scoped>
.basket-filters {
  .title {
    margin-bottom: 15px;
  }

  .view {
    padding-bottom: 15px;
  }
}
</style>
