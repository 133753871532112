<template>
  <a class="fo-handle intercom-tag-filters-button" :class="{ active: open }" @click="toggle">
    <div class="flex-row align-items-center gap-sm">
      <icon icon="fluent:filter-12-filled"></icon><span class="text">{{ $t("filters.title") }}</span>
    </div></a
  >
</template>

<script lang="ts">
import Mutations from "../store/mutations";
import Vue, { defineComponent } from "vue";
import { Icon } from "@iconify/vue2";

export default defineComponent({
  components: {
    Icon,
  },
  computed: {
    open() {
      return this.$store.state.flyoverFilters.open;
    },
  },
  methods: {
    show() {
      this.$store.commit(Mutations.openFlyoverFilters);
      this.$nextTick(() => {
        $("html,body").animate(
          {
            scrollTop: 0,
          },
          200
        );
      });
    },
    hide() {
      this.$store.commit(Mutations.closeFlyoverFilters);
    },
    toggle() {
      if (this.open) {
        this.hide();
      } else {
        this.show();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
$grid-float-breakpoint: 992px;

.filters-display {
  overflow: hidden;

  .flyover-handle-list {
    background-color: #656566;
    border-radius: 9999px;
    padding: 0px 15px;
    height: 25px;
    color: white;
    font-size: 10px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fo-handle {
  cursor: pointer;
  border-radius: 9999px;
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--color-interactive-fill-primary);
  color: var(--color-text-button-primary);
  flex-shrink: 0;
  transition: background-color 0.2s ease;

  @media (min-width: $grid-float-breakpoint) {
    height: 35px;
  }

  &:hover {
    background-color: var(--color-interactive-fill-primary-variant);
    color: var(--color-text-button-primary);
  }

  &:active,
  &.active {
    background-color: var(--color-interactive-fill-primary-variant);
    color: var(--color-text-button-primary);
  }
}
</style>
