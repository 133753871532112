<template>
  <div v-if="component" class="ai-assistant-component-view" ref="el">
    <SaveReportDialog ref="saveReportDialog" :config="saveReportConfig" />
    <ZoinedReportRow
      :components="[component]"
      :custom="true"
      :dashboard="true"
      :saveable="true"
      :editable="true"
      @save="saveReportDialog.show()"
      @component-updated="onComponentUpdated"
      @excel-export-component="excelExport"
      @loaded="onComponentLoaded"
      @edit="onEdit"
    />
    <AddComponentModal
      ref="addComponentModal"
      :set-component="setComponent"
      :filter-configuration="filterConfiguration"
      :shared="false"
    />
  </div>
</template>

<script setup lang="ts">
import ZoinedReportRow from "@/analytics/zoined-report-row.vue";
import { exportReport } from "@/lib/export";
import { filterToFlyover } from "@/lib/filter-util";
import Component from "@/model/component";
import _ from "lodash";
import { computed, ref } from "vue";
import ReportConfig from "@/model/report-config";
import SaveReportDialog from "@/save-report/save-report-dialog.vue";
import AddComponentModal from "@/custom-dashboard-editor/add-component-modal.vue";

const props = defineProps<{
  component: Component;
}>();

const emit = defineEmits(["component-updated", "update-overrides", "loaded"]);

const el = ref<HTMLElement | null>(null);

const isLoaded = ref(false);

const saveReportDialog = ref<typeof SaveReportDialog>(null);

const addComponentModal = ref<typeof AddComponentModal>(null);

const filterConfiguration = filterToFlyover({});

const saveReportConfig = computed<ReportConfig>(() => {
  return {
    components: [props.component],
  } as ReportConfig;
});

const onComponentUpdated = ({ updatedComponent }: { updatedComponent: Component }) => {
  emit("component-updated", updatedComponent);
};

const excelExport = () => {
  const c = _.cloneDeep(props.component);
  exportReport(c.name, {
    filterConfiguration: filterToFlyover(c.filterConfiguration),
    components: [c],
    format: "xlsx",
  });
};

const onComponentLoaded = () => {
  isLoaded.value = true;
  emit("loaded");
};

const onEdit = () => {
  addComponentModal.value.showAddComponentModal([props.component], 0);
};

const setComponent = (component) => {
  emit("component-updated", component);
};

defineExpose({
  el,
  isLoaded,
});
</script>

<style scoped lang="scss">
$grid-float-breakpoint: 992px;

.ai-assistant-component-view {
  &:last-child {
    min-height: calc(100vh - var(--header-height) - var(--sidenav-iconbar-width) - 30px);

    @media screen and (min-width: $grid-float-breakpoint) {
      min-height: calc(100vh - var(--header-height) - 30px);
    }
  }
}
</style>
