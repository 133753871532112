<template>
  <div class="pdf-attachment-section">
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.pdf_attachment.report") }}</label>
      <select :value="section.report" @input="updateReport">
        <option v-for="option in reportOptions" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("pdf.choose_layout") }}</label>
      <select :value="orientation" @input="updateOrientation">
        <option value="portrait">{{ $t("pdf.orientation.portrait") }}</option>
        <option value="landscape">{{ $t("pdf.orientation.landscape") }}</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import Actions from "../../store/actions";
import Vue, { defineComponent, PropType } from "vue";
export default defineComponent({
  computed: {
    reportOptions() {
      return this.$store.state.pdfReports.all;
    },
    orientation() {
      return this.section.orientation || "portrait";
    },
  },
  methods: {
    updateReport(event) {
      const report = Number.parseInt(event.target.value);
      this.$emit("update-section", { ...this.section, report });
    },
    updateOrientation(event) {
      const orientation = event.target.value;
      this.$emit("update-section", { ...this.section, orientation });
    },
  },
  props: {
    section: {
      type: Object as PropType<any>,
    },
  },
});
</script>
