const Mutations = {
  get clearReportContext() {
    return "reportContext/clear";
  },

  get setFilterConfiguration() {
    return "reportContext/setFilterConfiguration";
  },

  get setChartOptions() {
    return "reportContext/setChartOptions";
  },

  get setTableConfig() {
    return "reportContext/setTableConfig";
  },

  get setHighchartOptions() {
    return "reportContext/setHighchartOptions";
  },

  get setHasChanges() {
    return "reportContext/setHasChanges";
  },

  get fetchReportContextSuccess() {
    return "reportContext/fetchSuccess";
  },

  get fetchNavigationSuccess() {
    return "navigation/fetchSuccess";
  },

  get openFlyoverFilters() {
    return "flyoverFilters/open";
  },

  get closeFlyoverFilters() {
    return "flyoverFilters/close";
  },

  get setRoleData() {
    return "role/setRoleData";
  },

  get setDefaultDashboardId() {
    return "role/setDefaultDashboardId";
  },

  get setPreferences() {
    return "preferences/setPreferences";
  },

  get setTitle() {
    return "currentPage/setTitle";
  },

  get clearTitle() {
    return "currentPage/clearTitle";
  },

  get toggleAiAssistant() {
    return "aiAssistant/toggle";
  },

  get closeAiAssistant() {
    return "aiAssistant/close";
  },

  get setAiSessionId() {
    return "aiAssistant/setSessionId";
  },

  get setAiMessages() {
    return "aiAssistant/setMessages";
  },

  get addAiMessage() {
    return "aiAssistant/addMessage";
  },

  get replaceLastAiMessage() {
    return "aiAssistant/replaceLastMessage";
  },

  get updateAiMessageScore() {
    return "aiAssistant/updateMessageScore";
  },

  get updateAiMessageProgress() {
    return "aiAssistant/updateMessageProgress";
  },

  get setCreatingAiMessage() {
    return "aiAssistant/setCreatingMessage";
  },

  get setAiComponent() {
    return "aiAssistant/setComponent";
  },
};

export default Mutations;
