<template>
  <div class="zoined-spinner">
    <div class="spin-area">
      <div class="cog cog-1">
        <div class="wheel">
          <div class="fa fa-cog"></div>
        </div>
      </div>
      <div class="cog cog-2">
        <div class="wheel">
          <div class="fa fa-cog"></div>
        </div>
      </div>
      <div class="cog cog-3">
        <div class="wheel">
          <div class="fa fa-cog"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
