<template>
  <uiv-modal v-if="config" v-model="open">
    <div slot="header">
      <code class="pull-right small">{{ dbKey }}</code>
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="btn-group btn-multiple">
      <div class="btn btn-primary" :class="{ active: !config.invisible }" @click="setVisible(true)">
        {{ $t("company_admin.configuration.label.visible") }}
      </div>
      <div class="btn btn-primary" :class="{ active: config.invisible }" @click="setVisible(false)">
        {{ $t("company_admin.configuration.label.hidden") }}
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>{{ $t("company_admin.configuration.label.locale") }}</th>
          <th>{{ $t("company_admin.configuration.label.current") }}</th>
          <th>{{ $t("company_admin.configuration.label.original") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, lang) in config.label">
          <td>{{ lang }}</td>
          <td>
            <input v-model="config.label[lang]" />
          </td>
          <td>{{ config.original_label[lang] }}</td>
        </tr>
      </tbody>
    </table>
    <div slot="footer">
      <button class="btn btn-default" @click="reset()">{{ $t("company_admin.configuration.actions.reset") }}</button>
      <button class="btn btn-default" @click="cancel()">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" @click="ok()">{{ $t("actions.save") }}</button>
    </div>
  </uiv-modal>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import i18n from "../i18n";
import _ from "lodash";
export default defineComponent({
  data() {
    return {
      dbKey: null,
      config: null,
      open: false,
    };
  },
  computed: {
    title() {
      return i18n.t("company_admin.configuration.edit_" + this.type);
    },
  },
  methods: {
    show({ key, config }) {
      this.dbKey = key;
      this.config = _.cloneDeep(config);
      this.open = true;
    },
    ok() {
      this.$emit("save", { key: this.dbKey, config: this.config });
      this.open = false;
    },
    cancel() {
      this.open = false;
    },
    setVisible(value) {
      return (this.config.invisible = !value);
    },
    reset() {
      return (this.config.label = _.cloneDeep(this.config.original_label));
    },
  },
  props: {
    type: {
      type: String,
    },
  },
});
</script>
