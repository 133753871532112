<template>
  <modal
    class="newsletter-template-from-newsletter-modal"
    v-model="open"
    :append-to-body="true"
    :title="$t('newsletter_templates.select_newsletter')"
    :footer="false"
  >
    <div class="loading-indicator" v-if="!newsletterDefinitions">
      <spinner></spinner>
    </div>
    <div class="content" v-else>
      <div
        class="item"
        v-for="newsletterDefinition in newsletterDefinitions"
        @click="createFromNewsletterDefinition(newsletterDefinition)"
      >
        {{ newsletterDefinition.title }}
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import { Modal } from "uiv";
import spinner from "../../components/spinner.vue";
import newsletterDefinitionsApi from "../../api/admin-newsletter-definitions-api";

export default defineComponent({
  components: {
    Modal,
    spinner,
  },
  data() {
    return {
      open: false,
      newsletterDefinitions: null,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
  },
  methods: {
    show() {
      this.open = true;
      if (!this.newsletterDefinitions) {
        this.fetchNewsletterDefinitions();
      }
    },
    createFromNewsletterDefinition(newsletterDefinition) {
      this.open = false;
      this.$emit("select", newsletterDefinition.id);
    },
    async fetchNewsletterDefinitions() {
      this.newsletterDefinitions = await newsletterDefinitionsApi.get({ company_id: this.companyId });
    },
  },
});
</script>

<style lang="scss" scoped>
.newsletter-template-from-newsletter-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
