<template>
  <div v-if="randomCharts">
    <ai-chat-card>
      <div class="whitespace-pre">{{ t("ai_assistant.random_chart_info_text") }}</div>
      <div class="flex-column gap-lg mt-lg">
        <ChartCard :chart="chart" v-for="(chart, index) in randomCharts" :key="index" @click="selectChart(chart)" />
      </div>
    </ai-chat-card>
  </div>
</template>

<script setup lang="ts">
import { ChartState, randomChart } from "@/ai-assistant/ai-assistant-lottery-state";
import { useStore } from "@/composables/store";
import _ from "lodash";
import { computed, ref } from "vue";
import aiChatCard from "@/ai-assistant/ai-chat-card.vue";
import { useI18n } from "@/composables/i18n";
import MenuItem, { menuItemKey } from "@/interfaces/menu-item";
import { filterToFlyover, refreshFilterTimes } from "@/lib/filter-util";
import { until } from "@vueuse/core";
import ChartCard from "@/ai-assistant/chart-card.vue";

const emit = defineEmits(["create-message"]);

const { t } = useI18n();

const store = useStore();

const metrics = computed<MenuItem[]>(() => store.getters.getParameters("metrics"));
const groupings = computed<MenuItem[]>(() => store.getters.getParameters("grouping"));

const randomCharts = ref(null);

const chartTitle = (chart: ChartState) => {
  const time_selection = t("filters.date_selector." + chart.selection);
  const comparison = chart.comparison && t("filters.benchmark." + chart.comparison);
  const metric = metrics.value.find((m) => menuItemKey(m) === chart.metric).name;
  const grouping = groupings.value.find((g) => menuItemKey(g) === chart.grouping).name;

  if (comparison) {
    return t("ai_assistant.random_chart_title_with_comparison", { time_selection, comparison, metric, grouping });
  } else {
    return t("ai_assistant.random_chart_title", { time_selection, metric, grouping });
  }
};

const selectChart = (chart: ChartState) => {
  const message = t("ai_assistant.random_chart_message", { chart: chartTitle(chart) });
  const filterConfiguration: any = {
    selection: { type: chart.selection },
    grouping: { [chart.grouping]: { enabled: true, value: chart.grouping } },
    metrics: { [chart.metric]: { enabled: true, value: chart.metric } },
  };
  if (chart.comparison) {
    filterConfiguration.comparisons = [{ type: chart.comparison, enabled: true }];
  }
  const use = {
    chart: chart.chart,
    filterConfiguration: filterToFlyover(refreshFilterTimes(filterConfiguration)),
  };
  emit("create-message", { message, use });
};

const isLoaded = computed(() => !!(metrics.value && groupings.value));

until(isLoaded)
  .toBeTruthy()
  .then(() => {
    const availableGroupings = groupings.value?.map((grouping) => menuItemKey(grouping));
    const charts = [];
    while (charts.length < 3) {
      const chart = randomChart(availableGroupings);
      if (!charts.find((c) => _.isEqual(c, chart))) {
        charts.push(chart);
      }
    }
    randomCharts.value = charts;
  });
</script>

<style scoped>
.lottery-chart {
  background-color: var(--color-surface-neutral-3);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: var(--color-surface-neutral-2);
  }
}
</style>
