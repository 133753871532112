var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-metric"},[_c('div',{staticClass:"row selection-row",class:`span-${_vm.component.span}`},[_c('div',{staticClass:"col-xs-6 values",class:{ 'col-sm-12': _vm.component.span <= 4, 'col-md-7': _vm.component.span == 4, 'col-md-6': _vm.component.span > 4 }},[_c('div',{staticClass:"value",class:`span-${_vm.component.span}`},[_c('div',{staticClass:"symbol hidden-xs",class:{
            'hidden-sm': _vm.component.span > 4,
            'hidden-md': _vm.component.span >= 4,
            'hidden-lg': _vm.component.span >= 4,
            [_vm.selectionClass]: true,
          }}),_vm._v("\n        "+_vm._s(_vm.formatDashboxCurrency(_vm.value, _vm.precision || 0, _vm.currency, _vm.current_locale()))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col-xs-6",class:{
        ['hidden-sm']: _vm.component.span <= 4,
        ['hidden-md']: _vm.component.span < 4,
        ['hidden-lg']: _vm.component.span < 4,
        'col-md-5': _vm.component.span == 4,
        'col-md-6': _vm.component.span > 4,
      }},[_c('div',{staticClass:"dashbox-scale"},[_c('div',{staticClass:"graph-min"},[_vm._v(_vm._s(_vm.formatDashboxCurrency(0, 0, "", _vm.current_locale())))]),_vm._v(" "),_c('div',{staticClass:"graph-max"},[_vm._v("\n          "+_vm._s(_vm.formatDashboxCurrency(parseFloat(_vm.max_value), _vm.precision || 0, "", _vm.current_locale()))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"graph-frame"})]),_vm._v(" "),_c('div',{staticClass:"g selection"},[_c('div',{staticClass:"b",class:_vm.selectionClass,style:({ width: _vm.countValMin })})])])]),_vm._v(" "),_vm._l((_vm.comparisons),function(comparison){return _c('div',{staticClass:"row comparison-row",class:`span-${_vm.component.span}`},[_c('div',{staticClass:"col-xs-3 small-values",class:{
        'col-sm-12': _vm.component.span < 4,
        'col-sm-6': _vm.component.span == 4,
        'col-sm-3': _vm.component.span > 4,
        'col-md-6': _vm.component.span < 4,
        'col-md-3': _vm.component.span >= 4,
      }},[_c('div',{staticClass:"symbol hidden-xs",class:{
          'hidden-sm': _vm.component.span > 4,
          'hidden-md': _vm.component.span >= 4,
          'hidden-lg': _vm.component.span >= 4,
          [_vm.classType(comparison.type, comparison.label)]: true,
        }}),_vm._v(" "),_c('div',{staticClass:"rel",class:[comparison.change_class, `span-${_vm.component.span}`]},[_vm._v("\n        "+_vm._s(_vm.formatDashboxPercentageChange(comparison.change_percentage))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col-xs-3 small-values",class:{
        'hidden-sm': _vm.component.span < 4,
        'col-sm-6': _vm.component.span == 4,
        'col-md-6': _vm.component.span < 4,
        'col-md-4': _vm.component.span == 4,
        'col-md-3': _vm.component.span > 4,
      }},[(comparison.change)?_c('div',{staticClass:"abs",class:[comparison.change_class, `span-${_vm.component.span}`]},[_vm._v("\n        "+_vm._s(_vm.formatDashboxCurrencyChange(comparison.change, _vm.precision || 0, _vm.currency, _vm.current_locale()))+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-xs-6",class:{
        'col-md-5': _vm.component.span == 4,
        'hidden-sm': _vm.component.span <= 4,
        'hidden-md': _vm.component.span < 4,
        'hidden-lg': _vm.component.span < 4,
      }},[_c('div',{staticClass:"g comparison"},[_c('div',{staticClass:"b",class:_vm.classType(comparison.type, comparison.label),style:({ width: _vm.countComp(comparison.value), filter: _vm.comparisonFilter(comparison) })},[_c('div',{staticClass:"small-value"},[_vm._v("\n            "+_vm._s(_vm.formatDashboxCurrency(comparison.value, _vm.precision || 0, "", _vm.current_locale()))+"\n          ")])])])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }