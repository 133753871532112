<template>
  <select :value="value" @change="onChange">
    <option :value="null">{{ $t("newsletter.definition.sending_time_default") }}</option>
    <option :value="item.id" v-for="item in sendingTimeOptions">{{ item.name }}</option>
  </select>
</template>

<script lang="ts">
import _ from "lodash";
import moment from "moment";
import Vue, { defineComponent } from "vue";
export default defineComponent({
  computed: {
    sendingTimeOptions() {
      return _.map(_.range(0, 96), function(v) {
        const m = moment([2000, 1, 1]).add(15 * v, "minutes");
        const id = m.format("HH:mm");
        return {
          id,
          name: m.format("LT"),
        };
      });
    },
  },
  methods: {
    onChange(evt) {
      this.$emit("input", evt.target.value);
    },
  },
  props: {
    value: {
      type: String,
    },
  },
});
</script>
