import { makeApiInstance } from "@/api/instance";
import { useQuery } from "@tanstack/vue-query";
import { MaybeRef, toRef } from "@vueuse/core";
import _ from "lodash";
import { computed } from "vue";

const api = makeApiInstance({ baseURL: "/api/v1" });

type UseCompaniesParams = {
  partner_id?: string;
  enabled?: boolean;
};

export const useCompanies = (params: MaybeRef<UseCompaniesParams> = {}) => {
  params = toRef(params);
  const enabled = computed(() => (_.isBoolean(params.value.enabled) ? params.value.enabled : true));
  return useQuery({
    queryKey: ["companies", params],
    queryFn: () => api.get("/companies", { params: params.value }).then((res) => res.data),
    enabled,
  });
};
