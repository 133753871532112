<template>
  <div class="language-selector">
    <dropdown v-if="availableLanguages && availableLanguages.length > 1" menu-right v-model="open">
      <div class="toggle">
        <navbar-dropdown-button
          :title="$t('activerecord.attributes.user.language')"
          :subtitle="currentLanguageName"
          icon="globe"
        ></navbar-dropdown-button>
      </div>
      <template slot="dropdown">
        <li v-for="language in availableLanguages">
          <a class="language-switch" @click="switchLanguage(language)">{{ language.name }}</a>
        </li>
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts">
import Language from "../model/language";
import Vue, { defineComponent } from "vue";
import i18n from "../i18n";
import { Dropdown } from "uiv";
import { postForm } from "../lib/form";
import NavbarDropdownButton from "../components/navbar-dropdown-button.vue";
import { showOverlay } from "@/ui/overlay";

export default defineComponent({
  components: {
    Dropdown,
    NavbarDropdownButton,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    currentLanguageName() {
      return window.zoinedContext.current_language_name;
    },
    currentLanguageShortName() {
      return window.zoinedContext.current_language_short_name;
    },
    availableLanguages() {
      return window.zoinedContext.available_languages;
    },
  },
  methods: {
    toggleOpen() {
      console.log("toggle");
      this.open = !this.open;
    },
    switchLanguage(language: Language) {
      this.open = false;
      showOverlay(i18n.t("please_wait"));
      postForm(`/profile/switch_language/${language.code}`);
    },
  },
});
</script>
<style lang="scss">
.language-selector {
  .dropdown-menu {
    min-width: initial;

    li + li {
      border-top: 1px solid #eee;
    }
  }
}
</style>
