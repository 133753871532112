<template>
  <report-view v-if="loaded" :key="$route.path"></report-view>
</template>

<script lang="ts">
import ReportView from "./report-view.vue";
import Vue, { defineComponent } from "vue";
import spinner from "../components/spinner.vue";
import Mutations from "@/store/mutations";

export default defineComponent({
  components: {
    ReportView,
    spinner,
  },
  computed: {
    reportContextState() {
      return this.$store.state.reportContext.state;
    },
    loaded() {
      return this.reportContextState === "loaded";
    },
  },
  created() {
    this.$store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
  },
});
</script>
