<template>
  <div class="selectors">
    <draggable class="flex-row align-items-center flex-wrap gap-sm" v-model="model" @update="onOrderUpdated">
      <pill-button
        :enabled="item.enabled"
        :removable="false"
        @toggle="clickItem(item)"
        v-for="item in model"
        :key="item.key"
        >{{ item.name || item.label }}<span v-if="item.translatable">&nbsp;&nbsp;</span
        ><i class="fa fa-edit" v-if="item.translatable"></i
      ></pill-button>
    </draggable>
    <metric-dialog ref="metricDialog" :type="type" @save="onSave"></metric-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import _ from "lodash";
import MetricDialog from "./metric-dialog.vue";
import draggable from "vuedraggable";
import pillButton from "@/components/pill-button.vue";

export default defineComponent({
  components: {
    MetricDialog,
    draggable,
    pillButton,
  },
  data() {
    return {
      model: null,
    };
  },
  methods: {
    onOrderUpdated() {
      const model = this.model.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      this.$emit("update", model);
    },
    clickItem(item) {
      if (item.translatable === true) {
        this.editTranslations(item);
      } else {
        item.enabled = !item.enabled;
        this.$emit("update", this.model);
      }
    },
    editTranslations(item) {
      const config = {
        invisible: !item.enabled,
        label: item.custom_labels,
        original_label: item.originalLabels,
      };
      this.metricDialog.show({ key: item.key, config });
    },
    onSave({ key, config: { invisible, label } }) {
      const model = this.model.map((item) => {
        return item.key === key ? { ...item, enabled: !invisible, custom_labels: label } : item;
      });
      this.$emit("update", model);
    },
    onItemsUpdated() {
      this.model = _.cloneDeep(this.items);
    },
  },
  props: {
    items: {
      type: Array as PropType<any[]>,
    },
    type: {
      type: String,
    },
  },
  computed: {
    metricDialog: {
      cache: false,
      get() {
        return this.$refs["metricDialog"] as MetricDialog;
      },
    },
  },
  watch: {
    items: [{ immediate: true, handler: "onItemsUpdated" }],
  },
});
</script>
