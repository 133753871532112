<template>
  <div class="newsletter-definition-editor-view">
    <div class="mb-md">
      <a class="btn btn-primary" @click="back()">{{ $t("actions.back") }}</a>
    </div>
    <div class="newsletter-definition-editor">
      <spinner v-if="loading"></spinner>
      <div v-if="!loading">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-danger" v-if="error">
              <span>{{ error }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" :class="{ 'has-error': v$.model.title.$error }">
              <label class="control-label" for="title" style="margin-right: 5px;"> {{ $t("newsletter.title") }} </label>
              <translationModal
                :title="$t('newsletter.title')"
                :config="model.translations.title"
                @update="onUpdateTitleTranslations"
                ><i class="fa fa-edit"></i
              ></translationModal>
              <input class="form-control" id="title" type="text" v-model="model.title" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="newsletter_type">{{ $t("newsletter.definition.type") }}</label>
              <select id="newsletter_type" name="newsletter_type" v-model="model.newsletter_type">
                <option value="">{{ $t("newsletter.type.custom") }}</option>
                <option value="alert" :disabled="!alertsEnabled">{{ $t("newsletter.type.alert") }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="description">{{ $t("newsletter.description") }}</label>
              <textarea class="description" rows="2" cols="100" v-model="model.description"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" :class="{ 'has-error': v$.model.frequency.$error }">
              <label class="control-label" for="frequency">{{ $t("newsletter.definition.frequency") }}</label>
              <select class="form-control" id="frequency" v-model="model.frequency">
                <option></option>
                <option :value="item.id" v-for="item in frequencies">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-if="model.frequency == 'weekly' || model.frequency == 'at_most_weekly'">
            <div class="form-group">
              <label for="day_of_week">{{ $t("newsletter.definition.day_of_week") }}</label>
              <select id="day_of_week" v-model="model.day_of_week">
                <option :value="item.id" v-for="item in weekDays">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-if="model.frequency == 'monthly' || model.frequency == 'at_most_monthly'">
            <div class="form-group">
              <label for="day_of_month">{{ $t("newsletter.definition.day_of_month") }}</label>
              <select id="day_of_month" v-model="model.day_of_month">
                <option :value="item.id" v-for="item in monthDays">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2" v-if="model.frequency == 'quarterly'">
            <div class="form-group">
              <label for="month_number">{{ $t("filter.config.month") }}</label>
              <select id="month_number" v-model="model.month">
                <option :value="item.id" v-for="item in quarterlyMonthNumbers">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2" v-if="model.frequency == 'quarterly'">
            <div class="form-group">
              <label for="day_of_month">{{ $t("newsletter.definition.day_of_month") }}</label>
              <select id="day_of_month" v-model="model.day_of_month">
                <option :value="item.id" v-for="item in monthDays">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2" v-if="model.frequency == 'yearly'">
            <div class="form-group">
              <label for="month">{{ $t("filter.config.month") }}</label>
              <select id="month" v-model="model.month">
                <option :value="item.id" v-for="item in months">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2" v-if="model.frequency == 'yearly'">
            <div class="form-group">
              <label for="day_of_month">{{ $t("newsletter.definition.day_of_month") }}</label>
              <select id="day_of_month" v-model="model.day_of_month">
                <option :value="item.id" v-for="item in monthDays">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-if="model.newsletter_type != 'alert'">
            <div class="form-group">
              <label for="sending_time">{{ $t("newsletter.definition.sending_time") }}</label>
              <time-select name="sending_time" v-model="model.sending_time"></time-select>
            </div>
          </div>
          <div
            class="col-md-4"
            v-if="
              model.newsletter_type == 'alert' &&
                model.frequency != 'at_most_hourly' &&
                model.frequency != 'at_most_once'
            "
          >
            <div class="form-group">
              <label for="sending_time">{{ $t("newsletter.definition.alert_time") }}</label>
              <select name="sending_time" v-model="model.sending_time">
                <option :value="null">{{ $t("newsletter.definition.sending_time_default") }}</option>
                <option :value="item.id" v-for="item in sendingTimeOptions">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-if="!template && model.frequency == 'daily'">
            <div class="form-group">
              <label for="day_of_month">{{ $t("actions.send") }}</label>
              <select name="force" v-model="model.force">
                <option :value="false">{{ $t("newsletter.definition.send_only_if_new_data") }}</option>
                <option :value="true">{{ $t("newsletter.definition.send_always") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" v-if="template">
          <div class="col-md-12">
            <div class="form-group" :class="{ 'has-error': v$.model.translation_key.$error }">
              <label class="control-label" for="title" style="margin-right: 5px;">{{
                $t("dashboard_templates.translation_key")
              }}</label>
              <div class="subtitle">
                Translation is saved in localizations (localeapp) under "newsletter_templates.titles.[key]"
              </div>
              <input class="form-control" id="title" type="text" v-model="model.translation_key" />
              <div class="help-block" v-if="v$.model.translation_key.$error">
                Key can contain only characters a-z and _
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="model.frequency == 'at_most_hourly'">
            <div class="form-group">
              <label for="sending_times">{{ $t("newsletter.definition.alert_times") }}</label>
              <p>{{ $t("newsletter.definition.alert_times_help_text") }}</p>
              <div class="sending-times-component">
                <pillList
                  :items="sendingTimeItems"
                  :available-items="hourlySendingTimeOptions"
                  :togglable="false"
                  :multi-select="true"
                  :searchable="false"
                  @update="updateSendingTimes"
                ></pillList>
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="
              model.frequency == 'daily' || model.frequency == 'at_most_daily' || model.frequency == 'at_most_hourly'
            "
          >
            <div class="form-group">
              <label for="exclude_weekdays">{{ $t("newsletter.definition.exclude_weekdays") }}</label>
              <p>{{ $t("newsletter.definition.exclude_weekdays_help_txt") }}</p>
              <div class="flex-row flex-wrap gap-sm">
                <button
                  class="btn btn-primary"
                  :class="{ disabled: day.disabled }"
                  v-for="day in weekDays"
                  @click="toggleExcludeWeekday(day)"
                >
                  {{ day.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" v-if="availableCurrencies.length > 1">
            <div class="form-group">
              <label for="currency">{{ $t("newsletter.currency") }}</label>
              <select id="currency" name="currency" v-model="model.currency">
                <option value="">{{ $t("newsletter.default_currency") }}</option>
                <option :value="c.currency" v-for="c in availableCurrencies">{{ c.currency }} ({{ c.symbol }})</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" v-if="!template">
          <div class="col-md-12">
            <newsletter-owner-selector
              :newsletter-definition="model"
              @update-newsletter-definition="model = $event"
            ></newsletter-owner-selector>
          </div>
        </div>
        <div class="row" v-if="!template && model.partner_id">
          <div class="col-md-12">
            <visible-to-customers-selector v-model="model.visible_to_customers"></visible-to-customers-selector>
          </div>
        </div>
        <div class="row" v-if="model.team_id">
          <div class="col-md-4">
            <div class="form-group">
              <label>{{ $t("newsletter.definition.subscribe") }}</label>
              <div>
                <toggle-button
                  v-model="model.team_subscription"
                  :true-title="$t('newsletter.definition.all_users_in_team')"
                  :false-title="$t('newsletter.definition.selected_users')"
                ></toggle-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="model.team_id && !model.team_subscription && availableUsersItems">
          <div class="col-md-12">
            <pill-list
              :title="$t('activerecord.models.user.other')"
              :items="subscribedUsersItems"
              :available-items="availableUsersItems"
              :searchable="availableUsersItems.length > 10"
              @update="updateSubscribedUsers"
            ></pill-list>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group checkbox">
              <label>
                <input type="checkbox" v-model="model.auto_subscribe" />{{ $t("newsletter.definition.auto_subscribe") }}
              </label>
            </div>
          </div>
          <div class="col-md-4" v-if="!template">
            <div class="form-group checkbox">
              <label>
                <input type="checkbox" v-model="model.include_attachments" />{{
                  $t("newsletter.definition.include_attachments")
                }}
              </label>
            </div>
          </div>
        </div>
        <div class="row" v-if="template">
          <div class="col-md-12">
            <h3>{{ $t("attributes.visibility") }}</h3>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <div>
                <label>{{ $t("activerecord.attributes.dashboard_template.visible_in_zoined") }}</label>
              </div>
              <toggle-button v-model="model.visible_in_zoined"></toggle-button>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <div>
                <label>{{ $t("activerecord.attributes.dashboard_template.add_for_new_customers") }}</label>
              </div>
              <toggle-button v-model="model.add_for_new_customers"></toggle-button>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div>
                <label>{{ $t("activerecord.attributes.dashboard_template.partners") }}</label>
              </div>
              <partner-selector v-model="model.partners"></partner-selector>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3>{{ $t("newsletter.definition.global.filters_title") }}</h3>
          </div>
          <div class="col-md-12">
            <div class="form-group checkbox">
              <defaultParameterSelector
                v-model="model.configuration.global"
                :title="$t('newsletter.definition.use_default_parameters_label')"
              ></defaultParameterSelector>
            </div>
            <div class="form-group">
              <div v-if="availableFilters && availableFilters.length > 0">
                <div style="display: inline-block; margin-right: 20px;">
                  <filtersetSelector
                    :config="model.configuration.global.filters"
                    @update="updateFiltersConfig"
                  ></filtersetSelector>
                </div>
                <showFiltersetNameToggle
                  :value="chartOptions.show_filterset_name"
                  @input="updateShowFiltersetName"
                  v-if="hasEnabledFilterSets"
                ></showFiltersetNameToggle>
                <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl" style="margin-top: 15px;">
                  <filterSelector
                    :config="model.configuration.global.filters.filters[filter.id]"
                    :filter="filter"
                    @update="updateFilter(filter.id, $event)"
                    v-for="filter in availableFilters"
                    :key="filter.id"
                  ></filterSelector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3>{{ $t("newsletter.definition.sections.title") }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 newsletter-definition">
            <draggable class="sections" v-model="model.configuration.sections">
              <div v-for="section in model.configuration.sections">
                <newsletterSection
                  :section="section"
                  :newsletter-type="model.newsletter_type"
                  :time-periods="timePeriods"
                  @remove="removeSection"
                  @duplicate="duplicateSection"
                  @update="updateSection"
                ></newsletterSection>
              </div>
            </draggable>
          </div>
        </div>
        <div class="row buttons-row">
          <div class="col-md-12 margin-bottom-fix-50">
            <button class="btn btn-primary" @click="openAddSectionModal()">
              {{ $t("newsletter.definition.actions.add_section") }}
            </button>
            <div class="actions pull-right">
              <button class="btn btn-default" @click="preview()">{{ $t("actions.preview") }}</button>
              <button class="btn btn-primary" @click="onSaveClicked">{{ $t("actions.save") }}</button>
            </div>
          </div>
        </div>
        <add-section-modal
          ref="addSectionModal"
          :newsletter-type="model.newsletter_type"
          :allowed-section-types="allowedSectionTypes"
          @add="addSection"
        ></add-section-modal>
        <newsletter-preview-modal ref="newsletterPreviewModal" :definition="model"></newsletter-preview-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import draggable from "vuedraggable";
import translationModal from "../components/translation-modal.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import filterSelector from "../components/filter-selector.vue";
import newsletterSection from "./newsletter-section.vue";
import _ from "lodash";
import moment from "moment";
import i18n from "../i18n";
import Actions from "../store/actions";
import spinner from "../components/spinner.vue";
import { combinePeriods } from "./custom_periods_helper";
import { periodDefaults } from "./constants";
import AddSectionModal from "./add-section-modal.vue";
import NewsletterPreviewModal from "./newsletter-preview-modal.vue";
import pillList from "../components/pill-list.vue";
import toggleButton from "../components/toggle-button.vue";
import partnerSelector from "../components/partner-selector.vue";
import DefaultParameterSelector from "../components/default-parameter-selector.vue";
import TeamsApiService from "../api/teams-api-service";
import MenuItem from "@/interfaces/menu-item";
import PillItem from "@/interfaces/pill-item";
import timeSelect from "../components/time-select.vue";
import NewsletterOwnerSelector from "@/components/newsletter-owner-selector.vue";
import VisibleToCustomersSelector from "@/components/visible-to-customers-selector.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      model: {
        title: {
          required,
        },
        frequency: {
          required,
        },
        translation_key: {
          valid: (key: string) => !key || !!key.match(/^[a-z_]*$/),
        },
      },
    };
  },
  components: {
    draggable,
    translationModal,
    filtersetSelector,
    showFiltersetNameToggle,
    filterSelector,
    newsletterSection,
    spinner,
    pillList,
    toggleButton,
    partnerSelector,
    AddSectionModal,
    NewsletterPreviewModal,
    DefaultParameterSelector,
    timeSelect,
    NewsletterOwnerSelector,
    VisibleToCustomersSelector,
  },
  data() {
    const teams: any[] = null;
    const partnerIds: string[] = null;
    const model: any = null;

    return {
      model,
      dependenciesLoaded: false,
      partnerIds,
      selectedTeam: null,
      teams,
    };
  },
  computed: {
    loading() {
      return !this.model || !this.dependenciesLoaded;
    },
    zoinedContext() {
      return window.zoinedContext;
    },
    isAdmin() {
      return window.zoinedContext.isAdmin;
    },
    companyId() {
      return this.zoinedContext.companyId;
    },
    availableCurrencies() {
      return this.zoinedContext.currencies;
    },
    firstDayOfWeek() {
      return this.zoinedContext.firstDayOfWeek;
    },
    weekDays() {
      const days = i18n
        .t("date.day_names")
        .map((v, i) => ({
          id: i,
          name: v.charAt(0).toUpperCase() + v.slice(1),
          disabled: this.model.exclude_weekdays?.includes(i),
        }))
        .sort(({ id: a }, { id: b }) => {
          if (a < this.firstDayOfWeek) {
            a += 7;
          }
          if (b < this.firstDayOfWeek) {
            b += 7;
          }
          return a - b;
        });

      return days;
    },
    alertsEnabled() {
      return !!this.zoinedContext.insights.visible;
    },
    enabledSets() {
      const sets = _.get(this.model.configuration, "global.filters.sets");

      return sets?.filter(({ enabled }) => enabled) || [];
    },
    hasEnabledFilterSets() {
      return this.enabledSets.length > 0;
    },
    monthDays() {
      return _.map(_.range(1, 32), function(v) {
        return {
          id: v,
          name: v,
        };
      });
    },
    months() {
      return _.map(_.range(1, 13), function(v) {
        return {
          id: v,
          name: i18n.t("date.month_names." + v),
        };
      });
    },
    quarterlyMonthNumbers() {
      return _.map(_.range(1, 4), function(v) {
        return {
          id: v,
          name: v,
        };
      });
    },
    sendingTimeOptions() {
      return _.map(_.range(0, 96), function(v) {
        const m = moment([2000, 1, 1]).add(15 * v, "minutes");
        const id = m.format("HH:mm");
        return {
          id,
          name: m.format("LT"),
        };
      });
    },
    hourlySendingTimeOptions() {
      return _.map(_.range(0, 48), function(v) {
        const m = moment([2000, 1, 1, 0, 15, 0]).add(30 * v, "minutes");
        const id = m.format("HH:mm");
        return {
          id,
          key: id,
          name: m.format("LT"),
        };
      });
    },
    sendingTimeItems() {
      const sendingTimes = this.model ? this.model.sending_times : null;

      return sendingTimes?.map((value) => ({
        value,
        name: this.sendingTimeOptions.find(({ id }) => id === value).name,
      }));
    },
    newsletterType() {
      return this.model?.newsletter_type;
    },
    frequency() {
      return this.model?.frequency;
    },
    frequencies() {
      const frequencies =
        this.newsletterType === "alert"
          ? ["at_most_once", "at_most_hourly", "at_most_daily", "at_most_weekly", "at_most_monthly"]
          : ["daily", "weekly", "monthly", "quarterly", "yearly"];

      return frequencies.map((id) => ({
        id,
        name: i18n.t(`newsletter.frequency.${id}`),
      }));
    },
    availableFilters() {
      return this.$store.state.parameters.filters.all;
    },
    timePeriods() {
      return this.$store.state.parameters.timePeriods.all;
    },
    chartOptions() {
      return this.model.configuration.global?.chart_options || {};
    },
    customPeriods() {
      return this.$store.state.customPeriods.all;
    },
    periodOptions() {
      return this.customPeriods && combinePeriods(this.customPeriods);
    },
    teamId() {
      return this.model?.team_id;
    },
    subscribedUsersItems(): PillItem[] {
      if (this.model?.team_member_subscriber_ids && this.selectedTeam) {
        return this.model.team_member_subscriber_ids.map((value) => ({
          value,
          name: this.selectedTeam.members.find(({ id }) => id === value)?.name,
        }));
      } else {
        return [];
      }
    },
    availableUsersItems(): MenuItem[] {
      return this.selectedTeam?.members.map(({ id, name }) => ({ id, name }));
    },
    addSectionModal: {
      cache: false,
      get() {
        return this.$refs["addSectionModal"] as AddSectionModal;
      },
    },
    newsletterPreviewModal: {
      cache: false,
      get() {
        return this.$refs["newsletterPreviewModal"] as NewsletterPreviewModal;
      },
    },
  },
  created() {
    const actions = [];

    if (!this.$store.state.customPeriods) {
      actions.push(this.$store.dispatch(Actions.fetchCustomPeriods));
    }
    if (!this.$store.state.pdfReports.all) {
      actions.push(this.$store.dispatch(Actions.fetchPdfReports));
    }
    if (!this.$store.state.excelReports.all) {
      actions.push(this.$store.dispatch(Actions.fetchExcelReports));
    }
    if (!this.$store.state.filtersets.all) {
      actions.push(this.$store.dispatch(Actions.fetchFiltersets));
    }

    if (this.isAdmin) {
      actions.push(new TeamsApiService().getTeams().then((teams) => (this.teams = teams)));
    } else {
      // team admin
      this.teams = this.zoinedContext.current_role.team_memberships
        .filter(({ role }) => role === "admin")
        .map(({ team_id, name }) => ({ id: team_id, name }));
    }

    Promise.all(actions).then(() => (this.dependenciesLoaded = true));

    this.updateModel();
  },
  methods: {
    back() {
      history.back();
    },
    onUpdateTitleTranslations(translations) {
      Vue.set(this.model.translations, "title", translations);
    },
    trySetPeriodDefault(s, frequency) {
      const defaults = periodDefaults[s.type.id];
      if (
        defaults &&
        defaults[frequency] &&
        (!s.time_period ||
          !_.find(_.get(this.periodOptions, "frequency"), function(o) {
            return o.id === s.time_period;
          }))
      ) {
        Vue.set(s, "time_period", defaults[frequency]);
      }
    },
    toggleExcludeWeekday(day) {
      let weekdays = this.model.exclude_weekdays || [];
      if (weekdays.includes(day.id)) {
        weekdays.splice(weekdays.indexOf(day.id), 1);
      } else {
        weekdays.push(day.id);
      }
      Vue.set(this.model, "exclude_weekdays", weekdays);
    },
    onSaveClicked() {
      this.v$.$touch();

      // Checks that it's valid and sets errors
      if (this.v$.$invalid || !this.model.configuration.valid(this.model)) {
        return;
      }

      this.save(this.model);
    },
    preview() {
      if (!this.model.configuration.valid(this.definition)) {
        return;
      }
      const definition = { ...this.model, configuration: this.model.configuration.toJson() };
      this.newsletterPreviewModal.show(definition);
    },
    removeSection(section) {
      const i = this.model.configuration.sections.indexOf(section);
      if (i !== -1) {
        this.model.configuration.sections.splice(i, 1);
      }
    },
    duplicateSection(section) {
      const i = this.model.configuration.sections.indexOf(section);
      if (i >= 0) {
        const newSection = _.cloneDeep(section);
        this.model.configuration.sections.splice(i + 1, 0, newSection);
      }
    },
    openAddSectionModal() {
      this.addSectionModal.show();
    },
    addSection(type) {
      const section = {
        type,
        ...type.defaults,
      };
      this.trySetPeriodDefault(section, this.frequency);
      const sections = [...this.model.configuration.sections, section];
      Vue.set(this.model.configuration, "sections", sections);
    },
    updateSection(section, config) {
      const sections = this.model.configuration.sections.map((each) => (each === section ? config : each));
      Vue.set(this.model.configuration, "sections", sections);
    },
    updateFilter(filter, filters) {
      this.model.configuration.global.filters.filters = {
        ...this.model.configuration.global.filters.filters,
        [filter]: filters,
      };
    },
    updateSendingTimes({ items }) {
      this.model.sending_times = items.map(({ value }) => value);
    },
    updateShowFiltersetName(show_filterset_name) {
      this.updateChartOptions({
        ...this.chartOptions,
        show_filterset_name,
      });
    },
    updateFiltersConfig(config) {
      Vue.set(this.model.configuration.global, "filters", config);
    },
    updateChartOptions(config) {
      Vue.set(this.model.configuration.global, "chart_options", config);
    },
    updateSubscribedUsers({ items }: { items: PillItem[] }) {
      this.model.team_member_subscriber_ids = items.map(({ value }) => value);
    },
    updateModel() {
      if (!this.definition) {
        this.model = null;
        return;
      }
      const defaults = {
        frequency: "",
        newsletter_type: "",
        day_of_week: this.firstDayOfWeek,
        day_of_month: 1,
        month_number: 1,
        month: 1,
        sending_time: null,
        sending_times: null,
        team_id: null,
        team_subscription: true,
        team_member_subscriber_ids: [],
        force: false,
      };
      if (this.template) {
        Object.assign(defaults, {
          visible_in_zoined: true,
          add_for_new_customers: false,
          translation_key: null,
        });
      }
      if (!this.isAdmin) {
        Object.assign(defaults, {
          team_id: this.teams[0].id,
        });
      }

      const model = { ...defaults, ..._.cloneDeep(this.definition) };

      if (model.newsletter_type === null) {
        model.newsletter_type = "";
      }

      this.model = model;
    },
    onNewsletterTypeChanged(value, old) {
      if (old !== undefined) {
        // Clear sections
        this.model.configuration.sections = [];

        if (value == "alert") {
          this.model.frequency = "at_most_weekly";
        } else {
          this.model.frequency = "daily";
        }
      }
    },
    onFrequencyChanged(value, old) {
      if (value && old !== undefined && value !== old) {
        if (value === "weekly") {
          this.model.day_of_week = this.firstDayOfWeek;
        }
        if (value === "quarterly") {
          this.model.month_number = 1;
          this.model.day_of_month = 1;
        }
        if (value === "monthly") {
          this.model.day_of_month = 1;
        }
        if (value === "yearly") {
          this.model.day_of_month = 1;
          this.model.month = 1;
        }
        if (value === "at_most_hourly") {
          this.model.sending_time = null;
        } else {
          this.model.sending_times = null;
        }
        _.each(this.model.configuration.sections, (s) => {
          this.trySetPeriodDefault(s, value);
        });
      }
    },
    async onTeamChanged(teamId) {
      if (teamId) {
        this.selectedTeam = await new TeamsApiService().getTeam(teamId);
      } else {
        this.selectedTeam = null;
      }
    },
  },
  props: {
    definition: {
      type: Object as PropType<any>,
    },
    error: {
      type: String,
    },
    save: {
      type: Object as PropType<Function>,
    },
    title: { default: i18n.t("newsletter.definition.edit_form_title"), type: String },
    template: { default: false, type: Boolean },
    allowedSectionTypes: {
      type: Array as PropType<string[]>,
    },
  },
  watch: {
    definition: [
      {
        handler: "updateModel",
      },
    ],
    newsletterType: [
      {
        handler: "onNewsletterTypeChanged",
      },
    ],
    frequency: [
      {
        handler: "onFrequencyChanged",
      },
    ],
    teamId: [
      {
        handler: "onTeamChanged",
      },
    ],
  },
});
</script>
