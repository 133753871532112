import { render, staticRenderFns } from "./item-menu-dropdown.vue?vue&type=template&id=5c40f3af&scoped=true"
import script from "./item-menu-dropdown.vue?vue&type=script&lang=ts"
export * from "./item-menu-dropdown.vue?vue&type=script&lang=ts"
import style0 from "./item-menu-dropdown.vue?vue&type=style&index=0&id=5c40f3af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c40f3af",
  null
  
)

export default component.exports