import VueFilters from "./lib/vue/filters";
import Vue from "vue";
import * as uiv from "uiv";
import Vuex from "vuex";
import VueRouter from "vue-router";
import { VueQueryPlugin } from "@tanstack/vue-query";
import i18n from "./i18n";

// Register base components globally
import "./base-components";
import { handleError } from "./core/error-handling";

Vue.use(VueFilters);
Vue.use(uiv, { prefix: "uiv" });
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueQueryPlugin);

Vue.config.errorHandler = handleError;
Vue.prototype.$t = i18n.t.bind(i18n);
