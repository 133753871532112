import { formatNumber } from "../formatters";
import { weatherTooltipDesc } from "./weather";
import _ from "lodash";
import "whatwg-fetch";
import qs from "qs";

export function tooltipFormatter(options, config = {}) {
  const type = options.chart.name || options.chart.type;
  console.log("type", type, options.tooltip.shared);
  switch (type) {
    case "heatmap":
      return heatmapFormatter(options);
    case "bubble":
      return bubbleFormatter(options);
    case "scatter":
      return scatterFormatter(options);
    case "stacked":
      return stackedFormatter(options);
    case "pie":
      return pieFormatter();
    case "line":
    case "trend_insights":
      return multiSeriesTooltipFormatter();
    default:
      if (options.tooltip.shared) return sharedTooltipFormatter(options, config);
      else return null;
  }
}

export function heatmapFormatter(data) {
  if (__guard__(data.extra != null ? data.extra.filters : undefined, (x1) => x1.grouping) === "minute-hour") {
    // Show hour and minute.
    return function() {
      let s = `<b>${this.point.y}:${this.series.xAxis.categories[this.point.x]}</b><br/>${
        this.series.name
      }: ${formatNumber(this.point.value, {
        precision: this.series.tooltipOptions.valueDecimals,
        style: this.series.tooltipOptions.valueStyle,
        currency: this.series.tooltipOptions.valueCurrency,
      })}${this.series.tooltipOptions.valueSuffix || ""}`;
      if (this.point.absolute_value) {
        s += `; ${formatNumber(this.point.absolute_value, {
          precision: this.series.tooltipOptions.absoluteValueDecimals,
          style: this.series.tooltipOptions.absoluteValueStyle,
          currency: this.series.tooltipOptions.absoluteValueCurrency,
        })}${this.series.tooltipOptions.absoluteValueSuffix || ""}`;
      }
      return s;
    };
  } else if (__guard__(data.extra != null ? data.extra.filters : undefined, (x) => x.grouping_y) === "hour") {
    // Show date, and hour range.
    return function() {
      let s = `<b>${this.series.xAxis.categories[this.point.x]} ${this.point.y}:00-${this.point.y + 1}:00</b><br/>${
        this.series.name
      }: ${formatNumber(this.point.value, {
        precision: this.series.tooltipOptions.valueDecimals,
        style: this.series.tooltipOptions.valueStyle,
        currency: this.series.tooltipOptions.valueCurrency,
      })}${this.series.tooltipOptions.valueSuffix || ""}`;
      if (this.point.absolute_value) {
        s += `; ${formatNumber(this.point.absolute_value, {
          precision: this.series.tooltipOptions.absoluteValueDecimals,
          style: this.series.tooltipOptions.absoluteValueStyle,
          currency: this.series.tooltipOptions.absoluteValueCurrency,
        })}${this.series.tooltipOptions.absoluteValueSuffix || ""}`;
      }
      return s;
    };
  } else {
    // Show x and y categories.
    return function() {
      const xName = this.series.xAxis.categories[this.point.x];
      const yName = this.series.yAxis.categories[this.point.y];
      let s = `<b>${xName} ${yName}</b><br/>${this.series.name}: ${formatNumber(this.point.value, {
        precision: this.series.tooltipOptions.valueDecimals,
        style: this.series.tooltipOptions.valueStyle,
        currency: this.series.tooltipOptions.valueCurrency,
      })}${this.series.tooltipOptions.valueSuffix || ""}`;
      if (this.point.absolute_value) {
        s += `; ${formatNumber(this.point.absolute_value, {
          precision: this.series.tooltipOptions.absoluteValueDecimals,
          style: this.series.tooltipOptions.absoluteValueStyle,
          currency: this.series.tooltipOptions.absoluteValue,
        })}${this.series.tooltipOptions.absoluteValueSuffix || ""}`;
      }
      return s;
    };
  }
}

export function bubbleFormatter(data) {
  return function() {
    const name = this.point.tooltipName || this.point.name;
    return `
      <b>${name}</b>
      <br/>
      ${data.extra.z.name}: ${formatNumber(this.point.z, {
      precision: data.extra.z.precision,
      style: data.extra.z.style,
      currency: data.extra.z.currency,
    })}${data.extra.z.suffix}
      <br/>
      ${data.extra.x.name}: ${formatNumber(this.point.x, {
      precision: data.extra.x.precision,
      style: data.extra.x.style,
      currency: data.extra.x.currency,
    })}${data.extra.x.suffix}
      <br/>
      ${data.extra.y.name}: ${formatNumber(this.point.y, {
      precision: data.extra.y.precision,
      style: data.extra.y.style,
      currency: data.extra.y.currency,
    })}${data.extra.y.suffix}
    `;
  };
}

/**
 * Fetches more information about the basket.
 *
 * TODO: Shouldn't be in common formatters.
 */
export function scatterFormatter(data) {
  let count = 0;
  const delayedFetch = _.debounce((tooltip, id, date, lines) => {
    const currentCount = ++count;
    fetch(
      "/api/v1/charts/basket_scatter/tooltip?" +
        qs.stringify({
          chart_token: window.chart_token,
          "filter:basket_id": id,
        }),
      {
        credentials: "same-origin",
      }
    )
      .then((res) => {
        return res.text();
      })
      .then((tooltipHtml) => {
        if (currentCount == count) {
          lines.push(`<span class='date'>${date}</span><div id='product-list-container'>${tooltipHtml}</div>`);
          tooltip.label.attr({ text: lines.join("<br/>") });
        }
      });
  }, 200);

  return function(tooltip) {
    const name = this.point.tooltipName || this.point.name;

    const lines = [
      `<b>${name}</b>`,
      `<span class='basket_sales'>${Math.round(this.point.y * 100) / 100} ${this.point.currency}</span>  (${
        this.point.sales_pcs
      } pcs)`,
    ];

    delayedFetch(tooltip, this.point.basket_id, this.point.date, lines);

    return lines.join("<br/>");
  };
}

export function stackedFormatter(data) {
  const stacking = _.get(data, "plotOptions.column.stacking");
  return function() {
    return `<b>${this.point.category}</b><br/>${this.point.series.name}: ${
      stacking == "percent" ? formatNumber(this.point.percentage, { precision: 2 }) + "%; " : ""
    }${formatNumber(this.point.y, {
      precision: this.point.series.tooltipOptions.valueDecimals,
      style: this.point.series.tooltipOptions.valueStyle,
      currency: this.point.series.tooltipOptions.valueCurrency,
    })}${this.point.series.tooltipOptions.valueSuffix || ""}`;
  };
}

export function pieFormatter() {
  return function() {
    return `
    <div class="tooltip-container">
      ${tooltipHeader(this.point)}
      ${tooltipValue({ ...this.point, value: this.point.y })}
    </div>
  `;
  };
}

export function multiSeriesTooltipFormatter() {
  return function() {
    return `
      <div class="tooltip-container">
        ${tooltipHeader(this.point)}
        ${tooltipValues(this.point)}
        ${tooltipChanges(this.point)}
      </div>
    `;
  };
}

function tooltipHeader({ tooltip, series }) {
  tooltip = tooltip || {};
  const header = [tooltip.metric, series.name].filter(Boolean).join(" - ");
  return `<div class="tooltip-header">${header}</div>`;
}

function tooltipValues({ tooltip, series }) {
  tooltip = tooltip || {};
  const { values, colors } = tooltip;
  if (!values || values.length == 0) {
    return "";
  }

  const valuesHtml = values
    .map((value, index) => {
      const color = colors[index];
      return tooltipValue({ value, color, series });
    })
    .join("");
  return `<div class="tooltip-values-horizontal">${valuesHtml}</div>`;
}

function tooltipValue({ value, color, series }) {
  const formattedValue = formatNumber(value, {
    precision: series.tooltipOptions.valueDecimals,
    style: series.tooltipOptions.valueStyle,
    currency: series.tooltipOptions.valueCurrency,
  });
  const suffix = series.tooltipOptions.valueSuffix || "";
  const separator = suffix && suffix != "%" ? " " : "";
  return `
  <div class="tooltip-value">
    <div class="tooltip-value-band" style="background-color: ${color}"></div>
    ${formattedValue}${separator}${suffix}
  </div>
`;
}

function tooltipChanges({ tooltip, series }) {
  tooltip = tooltip || {};
  const absoluteChanges = tooltip.absolute_changes || [];
  const percentualChanges = tooltip.percentual_changes || [];

  if (absoluteChanges.length == 0) {
    return "";
  }

  const changesHtml = absoluteChanges
    .map((value, index) => {
      const sign = value > 0 ? "+" : "";
      const formattedValue = formatNumber(value, { precision: series.tooltipOptions.valueDecimals });
      const changeColorClass = value > 0 ? "fg-semantic-success" : "fg-semantic-error";
      const changeIconClass = value > 0 ? "fa-arrow-circle-up" : "fa-arrow-circle-down";
      return `
      <div class="tooltip-change">
        ${sign}${formattedValue}
        <div class="percentual-change ${changeColorClass}">
          <i class="fa ${changeIconClass}"></i>
          ${formatNumber(percentualChanges[index], { precision: 2 })}%
        </div>
      </div>
    `;
    })
    .join("");

  return `<div class="tooltip-changes-horizontal">${changesHtml}</div>`;
}
/**
 * Default formatter when we are showing same tooltip for all serieses.
 */
export function sharedTooltipFormatter(data, config) {
  return function() {
    let s = `<div class='tooltip-header'><b>${this.points[0].point.name}</b></div>`;
    s += "<table class='tooltip-points'>";
    _.each(this.points, function(point) {
      s += formatPoint(point);
    });
    s += "</table>";
    if (data.weather) {
      s += weatherTooltipDesc(this, data);
    }

    return s;
  };
}

function formatPoint(point) {
  point = point.point || point;
  const tooltipValue =
    point.tooltipValue ||
    formatNumber(point.y, {
      precision: point.series.tooltipOptions.valueDecimals,
      style: point.series.tooltipOptions.valueStyle,
      currency: point.series.tooltipOptions.valueCurrency,
    });
  const valueSuffix = point.series.tooltipOptions.valueSuffix || "";
  let s = "<tr>";
  s += `<td><div class='tooltip-legend' style='background-color: ${point.color};'></div></td>`;
  s += `<td>${tooltipValue}${valueSuffix}`;
  if (point.absolute_value) {
    const absoluteValue = formatNumber(point.absolute_value, {
      precision: point.series.tooltipOptions.absoluteValueDecimals,
      style: point.series.tooltipOptions.absoluteValueStyle,
      currency: point.series.tooltipOptions.absoluteValueCurrency,
    });
    const absoluteValueSuffix = point.series.tooltipOptions.absoluteValueSuffix || "";
    s += `; ${absoluteValue}${absoluteValueSuffix}`;
  }
  s += "</td>";
  // If there is a change % (from comparison to selection), show it.
  if (point.diff) {
    const change = point.diff > 0 ? "positive" : "negative";
    const diff = formatNumber(point.diff, { precision: 1 });
    s += `<td><div class='tooltip-change-${change}'><i/></div></td>`;
    s += `<td>${diff}%</td>`;
  }
  s += "</tr>";
  return s;
}
function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
