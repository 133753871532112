<template>
  <div class="flex-row align-items-center gap-lg">
    <div class="title" v-if="title">{{ $t("filter.config.limit_to_business_hours") }}</div>
    <btn-group class="btn-multiple" :class="{ shaded: isDefault }">
      <btn class="btn-sm" type="primary" input-type="radio" :input-value="true" v-model="model" @click="update(true)">{{
        $t("options.yes")
      }}</btn>
      <btn
        class="btn-sm"
        type="primary"
        input-type="radio"
        :input-value="false"
        v-model="model"
        @click="update(false)"
        >{{ $t("options.no") }}</btn
      >
    </btn-group>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { Btn, BtnGroup } from "uiv";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Btn,
    BtnGroup,
  },
  data() {
    const model: boolean = false;

    return {
      model,
    };
  },
  computed: {
    isDefault(): boolean {
      return !_.isBoolean(this.value) && _.isBoolean(this.default);
    },
  },
  created() {
    if (_.isBoolean(this.value)) {
      this.model = this.value;
    } else if (_.isBoolean(this.default)) {
      this.model = this.default;
    }
  },
  methods: {
    update(value) {
      this.$emit("update", value);
    },
  },
  props: {
    value: { default: null, type: Boolean },
    default: { default: null, type: Boolean },
    title: { default: true, type: Boolean },
  },
});
</script>
<style lang="scss" scoped>
.shaded {
  opacity: 0.5;
}
</style>
