<template>
  <div v-if="isVisible" class="active-filters-panel hidden-xs hidden-sm flex-column align-items-center gap-md">
    <div
      class="flex-row gap-sm align-items-center fg-text-emphasis font-semibold cursor-pointer print-hidden"
      @click="open = !open"
    >
      {{ t("filters.active_filters") }}
      <Icon :icon="open ? 'fluent:chevron-down-20-filled' : 'fluent:chevron-up-20-filled'" class="text-base" />
    </div>
    <div v-if="open" class="w-full bg-surface-neutral-no-contrast p-md rounded text-align-center">
      <ActiveFilters
        :filterConfiguration="filterConfiguration"
        :chart-options="chartOptions"
        align-items="center"
        @update="updateFilterConfiguration"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ActiveFilters from "@/components/active-filters/active-filters.vue";
import { useI18n } from "@/composables/i18n";
import { configToActive, removeSpecialAndExtraFiltersFromFilterConfiguration } from "@/lib/filter-util";
import ChartOptions from "@/model/chart-options";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import { Icon } from "@iconify/vue2";
import _ from "lodash";
import { computed, ref } from "vue";

const props = defineProps<{
  filterConfiguration: FlatFilterConfiguration;
  chartOptions: ChartOptions;
}>();

const emit = defineEmits(["update"]);

const open = ref(true);

const { t } = useI18n();

const isVisible = computed(() => {
  const filters = _.flatten(
    Object.values(
      configToActive({
        ...removeSpecialAndExtraFiltersFromFilterConfiguration(props.filterConfiguration),
        ...(props.filterConfiguration.metric_range ? { metric_range: props.filterConfiguration.metric_range } : {}),
      })
    )
  );
  return filters.length > 0;
});

const updateFilterConfiguration = (filterConfiguration) => {
  emit("update", filterConfiguration);
};
</script>

<style scoped>
.active-filters-panel {
  @media print {
    padding-bottom: 15px;
    border-bottom: 1px solid black;
  }
}
</style>
