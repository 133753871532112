<template>
  <div
    class="more-with-pro-menu"
    @mouseenter="limitedFeaturesHidden = false"
    @mouseleave="limitedFeaturesHidden = isLimited"
  >
    <div class="limited-features-toggle flex-row align-items-center gap-md" v-if="isLimited">
      <more-with-pro-text></more-with-pro-text
      ><i class="fa fa-chevron-down" :class="{ invisible: !limitedFeaturesHidden }"></i>
    </div>
    <div class="limited-features" :class="{ disabled: isLimited, invisible: limitedFeaturesHidden }">
      <slot></slot>
    </div>
    <div class="get-pro" v-if="isLimited && !limitedFeaturesHidden">
      <button class="btn btn-success btn-block btn-rect flex-row justify-content-center align-items-center">
        {{ $t("limited_version.get_pro") }}
        <div class="ml-sm"><i class="fa fa-arrow-right font-size-xs"></i></div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import MoreWithProText from "./more-with-pro-text.vue";

export default defineComponent({
  components: {
    MoreWithProText,
  },
  data() {
    return {
      isLimited: window.zoinedContext.limited,
      limitedFeaturesHidden: this.isLimited,
    };
  },
});
</script>

<style lang="scss">
.limited-features-toggle {
  padding: 5px 15px;
  white-space: nowrap;
  .pro-text {
    font-weight: bold;
    color: var(--color-yellow);
  }
  i.fa {
    font-size: 10px;
  }
}

.limited-features {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.invisible {
    height: 0;
    overflow: hidden;
  }
}

.get-pro {
  padding: 10px 15px;
}
</style>
