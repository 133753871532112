<template>
  <div class="dashboard-block">
    <chart
      class="graph"
      :type="effectiveChart"
      :pagination="false"
      :filters="effectiveFilters"
      :component="component"
      @pageChanged="pageChanged"
      ref="chart"
    ></chart>
    <div class="graph-pagination" v-if="pagination">
      <div class="row">
        <div class="col-sm-12">
          <div class="extra-controls">
            <div class="btn-group btn-multiple">
              <label class="btn btn-primary btn-sm" :class="{ active: relevant == true }" @click="relevant = true">{{
                $t("dashboard.show_more.show_relevant")
              }}</label>
              <label class="btn btn-primary btn-sm" :class="{ active: relevant == false }" @click="relevant = false">{{
                $t("dashboard.show_more.show_all")
              }}</label>
            </div>
            <div class="btn-group btn-multiple" v-if="showTop">
              <label class="btn btn-primary btn-sm" :class="{ active: top == true }" @click="top = true">{{
                $t("dashboard.show_more.top")
              }}</label>
              <label class="btn btn-primary btn-sm" :class="{ active: top == false }" @click="top = false">{{
                $t("dashboard.show_more.bottom")
              }}</label>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <pagination :page="page" :totalPages="totalPages" @pageChange="changePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "../../zoined-chart/zoined-chart.vue";
import pagination from "../../components/pagination.vue";
import cloneDeep from "clone";

export default {
  components: {
    chart,
    pagination,
  },
  props: {
    chart: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    showTop: {
      type: Boolean,
      default: true,
    },
    component: {
      type: Object,
      required: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      page: 1,
      totalPages: 1,
      relevant: true,
      top: !(this.filters.trend === "bottom" || this.chart.endsWith("_bottom")),
    };
  },
  computed: {
    plainChartName: function() {
      if (this.chart.endsWith("_bottom")) return this.chart.replace(/_bottom$/, "");
      if (this.chart.endsWith("_top")) return this.chart.replace(/_top/, "");
      return this.chart;
    },
    effectiveFilters: function() {
      const f = cloneDeep(this.filters);
      delete f.trend;
      if (this.relevant) f.trend_relevant = true;
      return f;
    },
    effectiveChart: function() {
      return this.plainChartName + (this.top ? "_top" : "_bottom");
    },
  },
  methods: {
    changePage: function(page) {
      this.$refs.chart.switchPage(page);
    },
    pageChanged: function(pagination) {
      this.$emit("pageChanged", pagination); // Pass event up in the chain
      this.page = pagination.page;
      this.totalPages = pagination.totalPages;
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-controls {
  margin-bottom: 10px;
  .btn-group:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
