<template>
  <div class="dashboard" v-if="loaded">
    <dashboard-view :key="$route.path"></dashboard-view>
  </div>
  <spinner v-else></spinner>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import spinner from "../components/spinner.vue";
import Actions from "../store/actions";
import Mutations from "../store/mutations";
import DashboardView from "./dashboard-view.vue";
import toaster from "toastr";
import i18n from "../i18n";
import { sendMixpanelEvent } from "@/mixpanel";

export default defineComponent({
  components: {
    DashboardView,
    spinner,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(Mutations.clearReportContext);
    next();
  },
  computed: {
    reportContextState() {
      return this.$store.state.reportContext.state;
    },
    loaded() {
      return this.reportContextState === "loaded";
    },
  },
  created() {
    // Do we have reportContext passed from page request
    if (this.reportContextState === "initial" && window.reportContext) {
      this.$store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
    }
    // If not then fetch context
    else {
      return this.$store.dispatch(Actions.fetchSystemDashboard).catch((error) => {
        if (error?.response?.status === 404) {
          this.$router.push("/");
        } else {
          toaster.error(i18n.t("errors.loading_failed"));
          throw error;
        }
      });
    }

    sendMixpanelEvent("Opened Dashboard");
  },
  props: {
    name: {
      type: String,
    },
    myReportId: {
      type: String,
    },
  },
});
</script>
