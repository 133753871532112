<template>
  <uiv-modal v-if="role" v-model="open" :title="$t('company_admin.invite_users.invite_user')" :backdrop="false">
    <spinner v-if="saving"></spinner>
    <div class="alert alert-danger" v-if="error">{{ error }}</div>
    <div class="row">
      <div class="col-md-6">
        <label class="control-label" for="invite_email">{{ $t("activerecord.attributes.user.email") }}</label>
        <input
          class="form-control"
          id="invite_email"
          autofocus
          type="email"
          v-model="role.email"
          required
          :placeholder="$t('activerecord.attributes.user.email')"
        />
      </div>
      <div class="col-md-6">
        <label class="control-label" for="invite_role">{{ $t("activerecord.attributes.user.role") }}</label>
        <select class="form-control" id="invite_role" v-model="role.role">
          <option :value="role.id" v-for="role in roleOptions">{{ role.name }}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label class="control-label" for="invite_first_name">{{ $t("activerecord.attributes.user.first_name") }}</label>
        <input
          class="form-control"
          id="invite_first_name"
          type="text"
          v-model="role.first_name"
          required
          :placeholder="$t('activerecord.attributes.user.first_name')"
        />
      </div>
      <div class="col-md-6">
        <label class="control-label" for="invite_last_name">{{ $t("activerecord.attributes.user.last_name") }}</label>
        <input
          class="form-control"
          id="invite_last_name"
          type="text"
          v-model="role.last_name"
          required
          :placeholder="$t('activerecord.attributes.user.last_name')"
        />
      </div>
      <div class="col-md-6">
        <label class="control-label" for="invite_language">{{ $t("activerecord.attributes.user.language") }}</label>
        <select class="form-control" id="invite_language" v-model="role.locale">
          <option :value="locale.id" v-for="locale in localeOptions">{{ locale.name }}</option>
        </select>
      </div>
      <div class="col-md-6" v-if="currencyOptions.length > 1">
        <label class="control-label" for="invite_newsletter_currency">{{
          $t("activerecord.attributes.user.newsletter_currency")
        }}</label>
        <select class="form-control" id="invite_newsletter_currency" v-model="role.newsletter_currency">
          <option :value="currency.currency" v-for="currency in currencyOptions">{{ currency.label }}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label class="control-label" for="invite_user_title">{{ $t("activerecord.attributes.user.title") }}</label>
        <user-title-selector v-model="role.title"></user-title-selector>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="control-label">{{ $t("filter.config.allowFileExport") }}<span class="info-bubble"></span></label>
        <div class="allow-options">
          <div class="btn-group btn-multiple" data-toggle="buttons">
            <label class="btn btn-primary" :class="{ active: !role.deny_export }" @click="updateExport(false)">{{
              $t("permissions.allow")
            }}</label>
            <label class="btn btn-primary" :class="{ active: role.deny_export }" @click="updateExport(true)">{{
              $t("permissions.deny")
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="teams.length > 0">
      <div class="col-md-12">
        <label class="control-label" v-uiv-tooltip.top="teamsTooltip"
          >{{ $t("company_admin.teams.title") }}<span class="info-bubble"></span
        ></label>
        <teamMembershipTable
          :team-memberships="role.team_memberships"
          :teams="teams"
          @update="updateTeamMemberships"
        ></teamMembershipTable>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="control-label" v-uiv-tooltip.top="permissionsTooltip"
          >{{ $t("profile.user_permissions") }}<span class="info-bubble"></span
        ></label>
        <permissions-editor
          :permissions="role.permissions"
          :effective-permissions="role.effective_permissions"
          @update="updatePermissions"
        ></permissions-editor>
      </div>
    </div>
    <div class="row" v-if="restrictedDashboards.length > 0">
      <div class="col-md-12">
        <label class="control-label" v-uiv-tooltip.top="allowedDashboardsTooltip"
          >{{ $t("profile.allowed_restricted_dashboards") }}<span class="info-bubble"></span
        ></label>
        <allowed-reports
          :title="$t('dashboard_custom.dashboards')"
          :restricted-reports="restrictedDashboards"
          :allowed-reports="role.permitted_dashboards"
          @change="updatePermittedDashboards"
        ></allowed-reports>
      </div>
    </div>
    <div class="row" v-if="restrictedReports.length > 0">
      <div class="col-md-12">
        <label class="control-label" v-uiv-tooltip.top="allowedReportsTooltip"
          >{{ $t("profile.allowed_restricted_reports") }}<span class="info-bubble"></span
        ></label>
        <allowed-reports
          :title="$t('analytics.reports')"
          :restricted-reports="restrictedReports"
          :allowed-reports="role.permitted_reports"
          @change="updatePermittedReports"
        ></allowed-reports>
      </div>
    </div>
    <div class="row" v-if="companyDashboards.length > 0">
      <div class="col-md-12">
        <label class="control-label" v-uiv-tooltip.top="defaultDashboardTooltip"
          >{{ $t("dashboard_custom.default_dashboard") }}<span class="info-bubble"></span
        ></label>
        <select class="form-control" id="invite_default_dashboard" v-model="role.default_dashboard_id">
          <option :value="null">{{ $t("dashboard.system_dashboard") }}</option>
          <option :value="dashboard.report.id" v-for="dashboard in companyDashboards">{{ dashboard.name }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="control-label" v-uiv-tooltip.top="defaultParametersTooltip"
          >{{ $t("profile.default_params") }}<span class="info-bubble"></span
        ></label>
        <defaultParameterSelector
          v-model="role.default_parameters"
          :permissions="role.effective_permissions"
        ></defaultParameterSelector>
      </div>
    </div>
    <div class="row" v-if="definitions.length > 0">
      <div class="col-md-12">
        <label class="control-label" v-uiv-tooltip.top="newsletterSubscriptionsTooltip"
          >{{ $t("newsletter.subscription.list_title") }}<span class="info-bubble"></span
        ></label>
        <subscriptions-selector v-model="role.definition_ids" :definitions="definitions"></subscriptions-selector>
      </div>
    </div>
    <div slot="footer">
      <button class="btn" @click="cancel()">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" type="submit" @click="invite()">
        {{ $t("company_admin.invite_users.invite") }}
      </button>
    </div>
  </uiv-modal>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import TeamMembershipTable from "./team-membership-table.vue";
import FilterSelector from "@/components/filter-selector.vue";
import ConfirmButton from "@/components/confirm-button.vue";
import AllowedReports from "@/permissions/allowed_reports.vue";
import PermissionsEditor from "@/permissions/permissions-editor.vue";
import PillList from "@/components/pill-list.vue";
import Team from "@/model/team";
import NewsletterDefinition from "@/model/newsletter-definition";
import i18n from "@/i18n";
import Actions from "@/store/actions";
import MenuItem from "@/interfaces/menu-item";
import adminCompaniesApi from "@/api/admin-companies-api";
import adminUsersApi from "@/api/admin-users-api";
import UserCompanyRole from "@/model/user-company-role";
import DashboardsApiService from "@/api/dashboards-api-service";
import { calculateEffectivePermissions } from "@/permissions/utils";
import _ from "lodash";
import PillItem from "@/interfaces/pill-item";
import DefaultParameterSelector from "./default-parameter-selector.vue";
import SubscriptionsSelector from "./subscriptions-selector.vue";
import Spinner from "@/components/spinner.vue";
import userTitleSelector from "@/components/user-title-selector.vue";

export default defineComponent({
  components: {
    TeamMembershipTable,
    FilterSelector,
    ConfirmButton,
    AllowedReports,
    PermissionsEditor,
    PillList,
    DefaultParameterSelector,
    SubscriptionsSelector,
    Spinner,
    userTitleSelector,
  },
  data() {
    const subscriptions: PillItem[] = null;
    const companyDashboards: any[] = [];
    const restrictedReports: any[] = [];
    const restrictedDashboards: any[] = [];
    const role: UserCompanyRole = null;
    const error: string = null;

    return {
      open: false,
      saving: false,
      error,
      role,
      restrictedDashboards,
      restrictedReports,
      companyDashboards,
      subscriptions,
    };
  },
  computed: {
    permissionsTooltip() {
      return i18n.t("setup.users.tooltips.permissions");
    },
    defaultDashboardTooltip() {
      return i18n.t("setup.users.tooltips.default_dashboard");
    },
    allowedDashboardsTooltip() {
      return i18n.t("setup.users.tooltips.allowed_dashboards");
    },
    allowedReportsTooltip() {
      return i18n.t("setup.users.tooltips.allowed_reports");
    },
    defaultParametersTooltip() {
      return i18n.t("setup.users.tooltips.default_parameters");
    },
    newsletterSubscriptionsTooltip() {
      return i18n.t("setup.users.tooltips.newsletter_subscriptions");
    },
    teamsTooltip() {
      return i18n.t("setup.users.tooltips.teams");
    },
    companyId() {
      return window.zoinedContext.companyId;
    },
    company() {
      return window.zoinedContext.company;
    },
    currencyOptions() {
      return [
        { currency: "", label: i18n.t("activerecord.attributes.company.company_currency") },
        ...window.zoinedContext.currencies.map(({ currency, symbol }) => ({
          currency,
          label: `${currency} (${symbol})`,
        })),
      ];
    },
    roleOptions() {
      return ["email", "user", "admin"].map((id) => {
        return { id, name: i18n.t(`roles.${id}`) };
      });
    },
    localeOptions() {
      return this.$store.getters.getParameters("locales");
    },
    availableSubcriptions(): MenuItem[] {
      return (
        this.definitions?.map((definition) => {
          return {
            id: definition.id.toString(),
            name: definition.title,
          };
        }) || []
      );
    },
    userTeams() {
      return this.role.team_memberships.map(({ team_id }) => _.find(this.teams, (team) => team.id === team_id));
    },
  },
  created() {
    this.$store.dispatch(Actions.fetchParameters("locales"));

    adminCompaniesApi.getRestrictedDashboards({ id: this.companyId }).then((r) => {
      this.restrictedDashboards = r;
    });

    adminCompaniesApi.getRestrictedReports({ id: this.companyId }).then((r) => {
      this.restrictedReports = r;
    });

    new DashboardsApiService().getCompanyDashboards().then((dashboards) => (this.companyDashboards = dashboards));
  },
  methods: {
    defaultRoleOptions(): Partial<UserCompanyRole> {
      return {
        locale: "",
        role: "user",
        title: null,
        permissions: {
          dimensions: { store: { allow: "all" } },
          metrics: { allow: "all" },
          groupings: { allow: "all" },
        },
        deny_export: false,
        newsletter_currency: "",
        default_dashboard_id: null,
        permitted_reports: [],
        permitted_dashboards: [],
        team_memberships: [],
        default_parameters: {},
        definition_ids: [],
      };
    },
    show() {
      this.role = this.defaultRoleOptions() as UserCompanyRole;
      this.updateEffectivePermissions();

      this.open = true;
    },
    updateTeamMemberships(teamMemberships) {
      this.role.team_memberships = teamMemberships;
      this.updateEffectivePermissions();
    },
    updateExport(denyExport) {
      this.role.deny_export = denyExport;
    },
    updatePermissions(permissions) {
      this.role.permissions = permissions;
      this.updateEffectivePermissions();
    },
    updatePermittedReports(reports) {
      this.role.permitted_reports = reports;
    },
    updatePermittedDashboards(dashboards) {
      this.role.permitted_dashboards = dashboards;
    },
    updateEffectivePermissions() {
      this.role.effective_permissions = calculateEffectivePermissions(this.role, this.userTeams);
    },
    invite() {
      this.saving = true;
      this.role.permitted_dashboard_ids = this.role.permitted_dashboards.map(({ id }) => id);
      this.role.permitted_report_ids = this.role.permitted_reports.map(({ id }) => id);
      adminUsersApi
        .invite({ company_id: this.companyId, user: this.role })
        .then((role) => {
          this.error = null;
          this.open = false;
          this.$emit("add", role);
        })
        .catch((err) => {
          this.error = err.response.data.error;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      this.open = false;
    },
  },
  props: {
    teams: {
      type: Array as PropType<Team[]>,
    },
    definitions: {
      type: Array as PropType<NewsletterDefinition[]>,
    },
  },
});
</script>

<style lang="scss" scoped>
label.control-label {
  margin-top: 15px;
}
</style>
