<template>
  <span class="config-pill-list">
    <pill-list
      :title="title"
      :searchable="searchable"
      :radio="radio"
      :draggable="draggable"
      :items="items"
      :defaults="defaultItems"
      :availableItems="availableItems"
      @update="onUpdate($event.items)"
    ></pill-list
  ></span>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import pillList from "./pill-list";

export default defineComponent({
  components: {
    pillList,
  },
  props: {
    config: Object,
    defaultConfig: Object,
    availableItems: Array,
    title: String,
    radio: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
    computed: {
        searchable() {
            return this.availableItems && this.availableItems.length > 10;
        },
        items() {
            return (
              this.config &&
              _.map(this.config, ({ name, value, ...rest }, key) => ({
                ...rest,
                value: key,
                name: name || value,
              }))
            );
        },
        defaultItems() {
            return (
              this.defaultConfig &&
              _.map(this.defaultConfig, ({ name, value, ...rest }, key) => ({
                ...rest,
                value: key,
                name: name || value,
              }))
            );
        }
    },
    methods: {
        onUpdate(items) {
            this.$emit(
              "update",
              items.reduce(
                (result, item) => ({
                  ...result,
                  [item.value]: item,
                }),
                {}
              )
            );
        }
    }
})

</script>

<style lang="scss" scoped>
.config-pill-list {
  margin-right: 20px;
}
</style>
