<template>
  <transition name="ai-assistant-button">
    <button v-if="!isOpen" id="ai-assintant-button" class="btn" @click="toggle">
      <Icon icon="fluent:sparkle-16-filled" class="icon-sparkle" />
    </button>
  </transition>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";
import { computed } from "vue";
import { Icon } from "@iconify/vue2";

const store = useStore();

const isOpen = computed(() => store.state.aiAssistant.open);

const toggle = () => {
  store.commit(Mutations.toggleAiAssistant);
};
</script>

<style lang="scss" scoped>
button.btn#ai-assintant-button {
  display: none;

  @media (min-width: 992px) {
    display: flex;
  }

  align-items: center;
  justify-content: center;
  outline: none !important;
  height: 46px;
  width: 46px;
  padding: 0;
  border-radius: 50%;
  border: 0.5px solid var(--color-border-emphasis);
  background-color: var(--color-surface-neutral-1);
  color: var(--color-text-emphasis);
  transition: all 0.3s ease;

  .icon-sparkle {
    font-size: 28px;
  }

  &:hover {
    background-color: var(--color-interactive-fill-primary);
    color: var(--color-text-button-primary);
    border-color: var(--color-interactive-fill-primary);
  }

  &:active {
    background-color: var(--color-interactive-fill-primary-variant);
    color: var(--color-text-button-primary);
    border-color: var(--color-interactive-fill-primary-variant);
  }
}
</style>

<style lang="scss">
.ai-assistant-button-enter-active {
  animation: fade-and-widen 1s;
}
.ai-assistant-button-leave-active {
  animation: fade-and-widen 0.5s reverse;
}
@keyframes fade-and-widen {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    opacity: 0;
    width: 46px;
  }
  100% {
    width: 46px;
    opacity: 1;
  }
}
</style>
