<template>
  <div class="sidenav-search-results">
    <sidenav-report :report="report" v-for="report in reports" :key="report.path"></sidenav-report>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import SidenavReport from "./sidenav-report.vue";

export default Vue.extend({
  components: {
    SidenavReport,
  },
  props: {
    reports: {
      type: Array,
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-search-results {
  margin-top: 20px;
}
</style>
