import { getAiMessages } from "@/ai-assistant/ai-assistant-api";
import AiMessage from "@/ai-assistant/ai-message";
import Component from "@/model/component";
import RootState from "@/store/root-state";
import { Module } from "vuex";

export type AiAssistantState = {
  open: boolean;
  session_id: string;
  creatingMessage: boolean;
  loadingMessages: boolean;
  messages: AiMessage[];
  components: Record<number, Component>;
};

const aiAssistant: Module<AiAssistantState, RootState> = {
  namespaced: true,
  state: () => ({
    open: false,
    session_id: null,
    creatingMessage: false,
    loadingMessages: false,
    messages: [],
    components: {},
  }),
  mutations: {
    toggle(state) {
      state.open = !state.open;
    },
    close(state) {
      state.open = false;
    },
    setSessionId(state, session_id: string) {
      state.session_id = session_id;
    },
    setComponent(state, { id, component }) {
      state.components = {
        ...state.components,
        [id]: component,
      };
    },
    setCreatingMessage(state, creatingMessage: boolean) {
      state.creatingMessage = creatingMessage;
    },
    setLoadingMessages(state, loadingMessages: boolean) {
      state.loadingMessages = loadingMessages;
    },
    setMessages(state, messages: AiMessage[]) {
      state.messages = messages;
    },
    addMessage(state, message: AiMessage) {
      state.messages.push(message);
    },
    replaceLastMessage(state, message: AiMessage) {
      state.messages.splice(-1, 1, message);
    },
    updateMessageScore(state, { id, score }) {
      const message = state.messages.find((m) => m.id === id);
      if (message) {
        message.score = score;
      }
    },
    updateMessageProgress(state, { id, progress }) {
      const message = state.messages.find((m) => m.id === id);
      state.messages.splice(state.messages.indexOf(message), 1, {
        ...message,
        progress,
      });
    },
  },
  actions: {
    fetchMessages({ commit, state }) {
      if (state.loadingMessages) {
        return;
      }
      commit("setLoadingMessages", true);
      getAiMessages(state.session_id)
        .then((messages) => {
          commit("setMessages", messages);
        })
        .finally(() => commit("setLoadingMessages", false));
    },
  },
};

export default aiAssistant;
