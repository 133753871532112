<template>
  <div v-if="isEnabled" id="menubar-mobile" class="hidden visible-xs visible-sm">
    <div class="flex-column gap-md">
      <div class="flex-row justify-content-center align-items-center">
        <ReportActions />
        <div class="flex-1 flex-row justify-content-flex-end align-items-center gap-md">
          <FlyoverFiltersHandle v-if="showFlyoverFilters" />
          <SaveReportButton v-if="showSaveButton" />
        </div>
      </div>
      <ActiveFilters
        v-if="filterConfiguration"
        class="hidden visible-xs visible-sm"
        :filter-configuration="filterConfiguration"
        :chart-options="chartOptions"
        @update="updateFilterConfiguration"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import { computed } from "vue";
import ReportActions from "@/components/report-actions.vue";
import FlyoverFiltersHandle from "@/flyover-filters/flyover-filters-handle.vue";
import ActiveFilters from "@/components/active-filters/active-filters.vue";
import { filterToFlyover, flatFilterConfiguration, normalizeConfig } from "@/lib/filter-util";
import SaveReportButton from "@/save-report/save-report-button.vue";
import Mutations from "@/store/mutations";

const store = useStore();

const isEnabled = computed(() => !!showFlyoverFilters.value || !!showSaveButton.value);

const showFlyoverFilters = computed(() => {
  const reportContext = store.getters.getReportContext;
  return reportContext?.active_filters?.length > 0;
});

const reportContext = computed(() => store.getters.getReportContext);

const filterConfiguration = computed(() => {
  const filterConfiguration = reportContext.value?.filter_configuration;

  return filterConfiguration && flatFilterConfiguration(filterConfiguration);
});

const chartOptions = reportContext.value?.chart_options;

const showSaveButton = computed(() => {
  return reportContext.value?.my_report?.editable;
});

const updateFilterConfiguration = (filterConfiguration) => {
  const flyoverFilterConfiguration = filterToFlyover(filterConfiguration);
  store.commit(Mutations.setFilterConfiguration, {
    ...flyoverFilterConfiguration,
    filters: normalizeConfig(flyoverFilterConfiguration.filters),
  });
};
</script>

<style scoped lang="scss">
#menubar-mobile {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid var(--color-border-variant-2);
  position: sticky;
  top: var(--header-height);
  z-index: var(--z-index-menubar);
}
</style>
