<template>
  <div class="newsletter-template-editor">
    <newsletter-definition-editor-view
      :title="title"
      :save="save"
      :definition="template"
      :template="true"
      :allowed-section-types="allowedSectionTypes"
    ></newsletter-definition-editor-view>
  </div>
</template>

<script lang="ts">
import NewsletterDefinitionEditorView from "../../newsletter-definition-editor/newsletter-definition-editor-view.vue";
import Vue, { defineComponent } from "vue";
import Configuration from "../../newsletter-definition-editor/configuration";
import { makeApiInstance } from "../../api/instance";
import i18n from "../../i18n";
import newsletterDefinitionsApi from "../../api/admin-newsletter-definitions-api";

export default defineComponent({
  components: {
    NewsletterDefinitionEditorView,
  },
  data() {
    return {
      template: null,
    };
  },
  computed: {
    api() {
      return makeApiInstance({ baseURL: "/api/zoined_admin/newsletter_templates/" });
    },
    allowedSectionTypes() {
      return ["metric_comparison", "top", "metric_extremes", "time_series"];
    },
    title() {
      if (this.id) {
        return i18n.t("newsletter_templates.edit_newsletter_template");
      } else {
        return i18n.t("newsletter_templates.new_newsletter_template");
      }
    },
    companyId() {
      return window.zoinedContext.companyId;
    },
  },
  async created() {
    if (this.id) {
      const template = await this.api.get(this.id).then((response) => response.data);
      template.configuration = Configuration.fromHash(template.configuration);
      this.template = template;
    } else if (this.templateId) {
      const template = await this.api.get(this.templateId).then((response) => response.data);
      template.configuration = Configuration.fromHash(template.configuration);
      delete template.id;
      template.title = `Copy of ${template.title}`;
      this.template = template;
    } else if (this.definitionId) {
      const definition = await newsletterDefinitionsApi.get({ id: this.definitionId, company_id: this.companyId });
      this.template = {
        title: definition.title,
        frequency: definition.frequency,
        translations: definition.translations,
        configuration: await Configuration.fromJson(definition.configuration),
      };
      this.template.configuration.sections = this.template.configuration.sections.filter((section) =>
        this.allowedSectionTypes.includes(section.type.id)
      );
    } else {
      this.template = {
        translations: {},
        configuration: new Configuration({ use_default_parameters: false }),
      };
    }
  },
  methods: {
    async save({ id, configuration, partners = [], ...newsletter_template }) {
      const url = id ? String(id) : "";
      const method = id ? "put" : "post";

      newsletter_template.configuration = configuration.toHash();
      newsletter_template.partner_ids = partners.map(({ id }) => id);

      await this.api.request({
        url,
        method,
        data: {
          newsletter_template,
        },
      });
      this.$router.back();
    },
  },
  props: {
    id: {
      type: String,
    },
    templateId: {
      type: String,
    },
    definitionId: {
      type: String,
    },
  },
});
</script>
