<template>
  <uiv-modal
    class="newsletter-definition-from-template-modal"
    v-model="open"
    :append-to-body="true"
    :title="$t('newsletter.definition.select_newsletter_template')"
    :footer="false"
  >
    <div class="loading-indicator" v-if="!newsletterTemplates">
      <spinner></spinner>
    </div>
    <div class="content" v-else>
      <div class="item" v-for="template in newsletterTemplates" @click="createFromTemplate(template)">
        {{ template.title }}
      </div>
    </div>
  </uiv-modal>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import spinner from "../components/spinner.vue";
export default defineComponent({
  components: {
    spinner,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
  },
  methods: {
    show() {
      this.open = true;
    },
    createFromTemplate(template) {
      this.open = false;
      this.$emit("new-from-template", template.id);
    },
  },
  props: {
    newsletterTemplates: {
      type: Array as PropType<any[]>,
    },
  },
});
</script>

<style lang="scss" scoped>
.newsletter-definition-from-template-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
