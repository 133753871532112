<template>
  <button v-if="productFruitsEnabled" type="button" ref="launcherButton" class="product-fruits-launcher-button">
    <Icon icon="fluent:question-20-filled" color="white" />
  </button>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue2";
import { onMounted, ref } from "vue";

const launcherButton = ref<HTMLButtonElement | null>(null);

const productFruitsEnabled = !!window.productFruits;

onMounted(() => {
  const intervalId = setInterval(() => {
    if (window.productFruits?.api?.inAppCenter?.attachToCustomElement && launcherButton.value) {
      clearInterval(intervalId);
      window.productFruits.api.inAppCenter.attachToCustomElement(launcherButton.value);
    }
  }, 100);
});
</script>

<style lang="scss">
.product-fruits-launcher-button {
  height: 40px;
  width: 40px;
  padding: 0;
  background: linear-gradient(134deg, #53b8d5 2.03%, #359cbb 100%);
  box-shadow: 0px 2px 25px 0px rgba(148, 148, 148, 0.2);
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 4px 2px rgba(75, 205, 240, 0.25);
  }
}

@media (min-width: 992px) {
  .product-fruits-launcher-button {
    height: 48px;
    width: 48px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: var(--z-index-product-fruits);
    transition: transform 0.3s;
  }
  .ai-assistant-open .product-fruits-launcher-button {
    transform: translateX(calc(var(--ai-assistant-width) * -1 + 20px));
  }
}
</style>
