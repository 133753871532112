const Events = {
  get saveReport() {
    return "saveReport";
  },

  get editReport() {
    return "editReport";
  },

  get deleteReport() {
    return "deleteReport";
  },

  get duplicateReport() {
    return "duplicateReport";
  },

  get setAsDefault() {
    return "setAsDefault";
  },
};

export default Events;
