<template>
  <div class="sidenav-analytics">
    <sidenav-search-box v-model="search"></sidenav-search-box>
    <sidenav-search-results v-if="search" :reports="searchResults"></sidenav-search-results>
    <sidenav-category
      v-else
      :category="category"
      v-for="category in navigationItems"
      :key="category.name"
    ></sidenav-category>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import Vue, { defineComponent } from "vue";
import SidenavCategory from "./sidenav-category.vue";
import SidenavSearchBox from "./sidenav-search-box.vue";
import SidenavSearchResults from "./sidenav-search-results.vue";

export default defineComponent({
  components: { SidenavCategory, SidenavSearchBox, SidenavSearchResults },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    navigationItems(): any[] {
      return this.$store.state.navigation.data?.analytics || [];
    },
    allReports() {
      return _.flatten(
        this.navigationItems.map((category) => _.flatten(category.folders.map(({ reports }) => reports)))
      );
    },
    searchResults() {
      console.log(this.search);
      const search = this.search.trim().toLowerCase();
      if (search.length === 0) {
        return this.allReports;
      }
      return this.allReports.filter(({ name }) => name?.toLowerCase().includes(search));
    },
  },
});
</script>
